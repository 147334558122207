import { NgModule } from '@angular/core';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { CurrencyToZeroConverter } from '../../../../msl-translate/src/lib/mysam-translate/currency-pipes/pipes/currency-to-zero.converter';
import { AlfredUserPipe } from './alfred-user.pipe';
import { CompanyPipe } from './company.pipe';
import { DistancePipe } from './distance.pipe';
import { EnumPipe } from './enum.pipe';
import { KeysPipe } from './keys.pipe';
import { UserPipe } from './user.pipe';
import { VehicleTypePipe } from './vehicle-type.pipe';
import { TimestampToHoursFlooredPipe } from './timestamp-to-hours-floored.pipe';
import { SecondsToHoursFlooredPipe } from './seconds-to-hours-floored.pipe';
import { SecondsToMinutesFlooredPipe } from './seconds-to-minutes.pipe';
import { UserDetailsPipe } from './user-details.pipe';
import { UserPenaltyPipe } from './user-penalty.pipe';
import { PhonePipe } from './phone.pipe';
import { CurrencyPipesModule } from 'msl-translate';
import { CompanyWithLibertyMobilePipe } from "./company-with-liberty-mobile.pipe";

const pipes: any[] = [
    AlfredUserPipe,
    CompanyPipe,
    CompanyWithLibertyMobilePipe,
    DistancePipe,
    EnumPipe,
    KeysPipe,
    PhonePipe,
    SecondsToMinutesFlooredPipe,
    SecondsToHoursFlooredPipe,
    TimestampToHoursFlooredPipe,
    UserPipe,
    UserDetailsPipe,
    UserPenaltyPipe,
    VehicleTypePipe ];

const imports = [ CurrencyPipesModule ];

@NgModule({
    declarations: [ ...pipes ],
    imports: [ ...imports ],
    exports: [ ...pipes, ...imports ],
    providers: [ ...pipes, CurrencyToZeroConverter, TitleCasePipe, DatePipe ]
})
export class PipesModule
{
}
