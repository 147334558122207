<!-- Needed to display the component on the entire screen -->
<div class="container-full bg-gray-darker">
    <div [ngClass]="outerContainerClass">

        <!-- Centering the Container -->
        <div class="mx-auto" [ngClass]="innerContainerClass">

            <!--
                    Logo for Alfred / Liberty Driver
                    Even if the outer container is bigger, we want the logo to be wrapped in a "container-xs", in
                    order not to appear too big
            -->
            <div class="container-xs mx-auto">
                <!-- Logo for Alfred -->
                <img *ngIf="source == UserSource.MYSAM" src="assets/img/logo.png" class="mv-lg block-center img-responsive thumb64"/>

                <!-- Logo for Liberty Driver -->
                <div *ngIf="source == UserSource.LIBERTY_DRIVER">
                    <img src="assets/img/liberty-driver/liberty-driver-white.png" class="block-center img-responsive"/>
                    <h4 class="text-center" *ngIf="!!textBelowLogo">{{ textBelowLogo }}</h4>
                </div>

                <!-- Liberty Mobile logo, for Register-LM-->
                <div *ngIf="source == UserSource.LIBERTY_MOBILE">
                    <!--
                            "m-auto" in order to center the Image in the block, without the usage of flex (which
                            would prevent the text from staying below the Image)
                            "d-block" required for "m-auto" to work
                    -->
                    <img src="assets/img/liberty-mobile-white.png" class="d-block m-auto" style="max-height: 120px"/>
                    <h4 class="text-center" *ngIf="!!textBelowLogo">{{ textBelowLogo }}</h4>
                </div>
            </div>

            <!-- The actual content goes here -->
            <ng-content></ng-content>
        </div>
    </div>
</div>
