import { Pipe, PipeTransform } from '@angular/core';

/**
 * Displays a User object as "firstName lastName", or an empty string if an empty user
 * object was passed to the "transform" method
 */
@Pipe({
    name: 'userDetails'
})
export class UserDetailsPipe implements PipeTransform
{
    transform(user?: { firstName, lastName, email, mobilePhoneNumber }): string
    {
        if (!!user)
        {
            return user.lastName + ' ' + user.firstName +
                (!!user.email ? ' - ' + user.email : '') +
                (!!user.mobilePhoneNumber ? ' - ' + user.mobilePhoneNumber : '');
        }

        return '';
    }
}
