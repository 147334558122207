import { ClientsEffect } from './clients/clients.effect';
import { ClientEffect } from './clients/client.effect';
import { ClientReferrerUpdateEffect } from './clients/client.referrer.update.effect';
import { UsersEffect } from './users.effect';

export const effects: any[] = [
    ClientsEffect,
    ClientEffect,
    ClientReferrerUpdateEffect,
    UsersEffect ];

export * from './clients/clients.effect';
export * from './clients/client.effect';
export * from './clients/client.referrer.update.effect';
export * from './users.effect';
