import { Injectable } from '@angular/core';
import { ApiError, ApiService, Client } from 'mys-base';
import { Observable } from 'rxjs';

import { Page } from '../../../../../msl-driver-registration/src/lib/http/responses/pagination/page';
import { environment } from '../../../environments/environment';
import { ClientFilter } from '../../http/responses/filters/clients/client-filter';
import { ClientUpdateRequest } from '../../http/requests/clients/client-update.request';
import { UserUpdateApiError } from '../../errors/models/business/user-update-error';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientFilters } from '../../http/responses/filters/clients/client-filters';
import { map } from 'rxjs/operators';

@Injectable()
export class ClientService extends ApiService
{
    private readonly BASE_CLIENT_URL = environment.BASE_URL + '/alfred/clients';
    private readonly EDIT_CLIENT_URL = environment.BASE_URL + '/alfred/client-edition';

    /**
     * Get all Clients filtered by clientFilter
     *
     * @param {ClientFilter} clientFilter
     * @returns {Observable<Page<Client>>}
     */
    public getClients(clientFilter?: ClientFilter): Observable<Page<Client>>
    {
        let url = this.BASE_CLIENT_URL;

        /**
         * If a result filter is specified, we add it here
         */
        if (!!clientFilter)
        {
            url += '?page=' + clientFilter.pageable.page + '&size=' + clientFilter.pageable.size;
        }

        return this.httpClient.post<Page<Client>>(url, clientFilter.clientFilters).pipe(
            // Enforcing the return type here
            map((page: Page<Client>) =>
            {
                page.content = page.content.map(client => Client.assign(client));
                return page;
            })
        );
    }

    /**
     * Get a csv export with a Clients list filtered by clientFilters
     *
     * @param {ClientFilters} clientFilters
     * @returns {Observable<Blob>}
     */
    public getClientsCsv(clientFilters: ClientFilters): Observable<Blob>
    {
        const url = this.BASE_CLIENT_URL + '/csv';

        return this.httpClient.put(url, clientFilters, { responseType: 'blob' });
    }

    /**
     * Gets the client bound to the given clientId
     * @param {string} clientId
     * @return {Observable<Client>}
     */
    public getClientById(clientId: string): Observable<Client>
    {
        return this.httpClient.get<Client>(this.BASE_CLIENT_URL + '/' + clientId).pipe(
            // Enforcing the return type here
            map((client: any) => Client.assign(client))
        );
    }

    public updateClient(userId: string, clientUpdateRequest: ClientUpdateRequest): Observable<Client>
    {
        return this.httpClient.put<Client>(this.EDIT_CLIENT_URL + '/' + userId, clientUpdateRequest).pipe(
            // Enforcing the return type here
            map((client: any) => Client.assign(client))
        );
    }

    // region ApiError handle

    public createApiError(err: HttpErrorResponse): ApiError
    {
        return new UserUpdateApiError(super.createApiError(err));
    }

    // endregion
}
