import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns the keys of a given [key, value] array
 * - If the array is a simple array, return its keys
 * - If the array is a map of {key: ..., value: ...} objects, returns all the "key" attributes of those objects
 */
@Pipe({
    name: 'keys'
})
export class KeysPipe implements PipeTransform
{
    transform(array: any[]): any
    {
        const keys = [];
        // console.log(array.keys());

        // We parse the array...
        for (const i in array)
        {
            // ... And if we have a "key" attribute, that's the one we want to gather
            if (array[i].key !== undefined)
            {
                keys.push(array[i].key);
            }
        }

        // If there is no "key" attributes, we return "array.keys()" instead
        return keys.length === 0 ? array.keys() : keys;
    }
}
