import { ApiError } from 'mys-base';
import { MysamTranslateService } from 'msl-translate';

/**
 * Created by Aurélien Rieu on 22/05/2019
 */
export class AdministrativeAreasError extends ApiError
{
    constructor(apiError: ApiError)
    {
        super(apiError.type, apiError.description, apiError.httpStatus, apiError.extraParams);
    }

    public getErrorMessage(translate: MysamTranslateService): string
    {
        switch (this.type)
        {
            // The Driver was not found
            // The userId extra param is not used in the returned errors message for user readability
            case AdministrativeAreasError.Type.DriverNotFound:
                return translate.instant('errors.administrative-areas.ADMINISTRATIVE-AREAS-BASE') + ' ' +
                    translate.instant('errors.my-sam-errors.DRIVER-NOT-FOUND', { userId: this.extraParams.userId });

            /**
             * For now, we don't handle other errors
             */
            default:
                return translate.instant('errors.my-sam-errors.DEFAULT-ERROR');
        }
    }
}

export namespace AdministrativeAreasError
{
    export enum Type
    {
        DriverNotFound = 'DRIVER_NOT_FOUND', // userId
    }
}
