import { Action, State, StateContext } from '@ngxs/store';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { GenericState, GenericStateModel } from 'mys-base';
import { ForgotPasswordService } from '../services/forgot-password.service';
import { RequestNewPasswordAction } from '../actions/forgot-password.action';
import { TranslateService } from '@ngx-translate/core';

import { Injectable } from '@angular/core';

@State({
    name: 'forgotPasswordState',
    defaults: GenericState.init()})
@Injectable()
export class ForgotPasswordState
{
    // region Constructor

    constructor(private forgotPasswordService: ForgotPasswordService, private translate: TranslateService)
    {
    }

    // endregion

    @Action(RequestNewPasswordAction)
    requestNewPassword(ctx: StateContext<GenericStateModel>, action: RequestNewPasswordAction)
    {
        ctx.patchState({ ...GenericState.load() });

        return this.forgotPasswordService.requestNewPasswordToken(action.email).pipe(
            map(_empty => ctx.patchState({ ...GenericState.success() })),
            catchError(error => of(ctx.patchState({ ...GenericState.error(
                this.forgotPasswordService.createApiError(error).getErrorMessage(this.translate))
            })))
        );
    }
}
