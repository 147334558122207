import { Action } from '@ngrx/store';
import { TripChecker } from '../../models/checkers/trip-checker';

// Load TripChecker by Trip Id
export const LOAD_TRIP_CHECKER_BY_TRIP_ID = '[Alfred] Load Trip Checker By Trip Id';
export const LOAD_TRIP_CHECKER_BY_TRIP_ID_FAIL = '[Alfred] Load Trip Checker By Trip Id Fail';
export const LOAD_TRIP_CHECKER_BY_TRIP_ID_SUCCESS = '[Alfred] Load Trip Checker By Trip Id Success';

export class LoadTripCheckerByTripId implements Action
{
    readonly type = LOAD_TRIP_CHECKER_BY_TRIP_ID;

    constructor(public payload: number)
    {
    }
}

export class LoadTripCheckerByTripIdFail implements Action
{
    readonly type = LOAD_TRIP_CHECKER_BY_TRIP_ID_FAIL;

    constructor(public payload: any)
    {
    }
}

export class LoadTripCheckerByTripIdSuccess implements Action
{
    readonly type = LOAD_TRIP_CHECKER_BY_TRIP_ID_SUCCESS;

    constructor(public payload: TripChecker)
    {
    }
}

// Action types
export type TripCheckerAction = LoadTripCheckerByTripId | LoadTripCheckerByTripIdFail | LoadTripCheckerByTripIdSuccess;
