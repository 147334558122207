import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlfredUser, ApiService } from 'mys-base';
import { environment } from '../../../environments/environment';
import { AlfredUserRequest } from '../../alfred-users/http/requests/alfred-user-request';
import { HttpErrorResponse } from '@angular/common/http';
import { AlfredUserError } from '../../alfred-users/http/errors/alfred-user.error';
import { map } from 'rxjs/operators';

@Injectable()
export class AlfredUserService extends ApiService
{
    private readonly ALFRED_BASE_URL = environment.BASE_URL + '/alfred';

    public getCurrentAlfredUser(): Observable<AlfredUser>
    {
        return this.httpClient.get<AlfredUser>(this.ALFRED_BASE_URL + '/connectable/me').pipe(
            // Enforcing the return type here
            map((user: AlfredUser) => AlfredUser.assign(user))
        );
    }

    /**
     * Creates a new AlfredUser from the given alfredUserRequest
     * @param alfredUserRequest
     */
    public createAlfredUser(alfredUserRequest: AlfredUserRequest): Observable<AlfredUser>
    {
        return this.httpClient.post<AlfredUser>(this.ALFRED_BASE_URL + '/alfred-user/new', alfredUserRequest).pipe(
            // Enforcing the return type here
            map((user: any) =>  AlfredUser.assign(user))
        );
    }

    public buildAlfredUserError(err: HttpErrorResponse): AlfredUserError
    {
        const error = super.createApiError(err);
        return new AlfredUserError(error.type, error.description, error.httpStatus, error.extraParams);
    }
}
