import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LdLayoutComponent } from './components/ld-layout/ld-layout.component';

const components = [ LdLayoutComponent ];

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [ ...components ],
    exports: [ ...components ]
})
export class LdLayoutModule
{
}
