import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { LogoutAction } from 'msl-login';

/**
 * Created by Adrien Dos Reis on 14/08/2019
 * The only purpose of this component is to trigger a "LogoutAction" when invoked (e.g. when the user enters /logout in his browser)
 */
@Component({ selector: 'mys-logout', template: '' })
export class LogoutComponent implements OnInit
{
    constructor(private store: Store)
    {
    }

    ngOnInit(): void
    {
        this.store.dispatch(new LogoutAction());
    }
}
