<!--
        See https://primeng.org/overlaypanel for usages of <p-overlayPanel> and "pTemplate"
-->
<p-overlayPanel #alertPanel (onShow)="onShow.emit($event)" (onHide)="onHide.emit($event)">
    <ng-template pTemplate>
        <ng-content></ng-content>
    </ng-template>
</p-overlayPanel>

<icon-notification [notificationIconToDisplay]="notificationIconToDisplay"
                   [nbAlerts]="badgeNumberValue"
                   (click)="alertPanel.toggle($event)"></icon-notification>
