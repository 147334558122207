import { GenericState, GenericStateModel } from 'mys-base';
import { Driver } from 'mys-base';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { DriverService } from '../services/driver.service';
import { MysamTranslateService } from 'msl-translate';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EditDriver, LoadDriverById, LoadDriverByIdSuccess } from '../actions/driver.action';
import { DriverDetailsUpdatedAction } from '../../../../../../msl-driver-registration/src/lib/driver-details/actions/driver-details-update.action';
import { DriverError } from '../errors/driver.error';
import { AdministrativeArea } from '../../../../../../msl-driver-registration/src/lib/models/administrative-area';
import { LoadAdministrativeAreasForDriver } from '../../../../../../msl-driver-registration/src/lib/administrative-areas/actions/administrative-areas.action';
import { AdministrativeAreasService } from '../../../../../../msl-driver-registration/src/lib/administrative-areas/services/administrative-areas.service';
import { WorkingAreasError } from '../../../../../../msl-driver-registration/src/lib/working-areas/errors/working-areas.error';
import { UpdateDriverWorkingAreas } from '../../../../../../msl-driver-registration/src/lib/working-areas/actions/working-areas.action';
import { WorkingAreasService } from '../../../../../../msl-driver-registration/src/lib/working-areas/services/working-areas.service';
import { DriverDetails } from 'mys-base';

const _clone = (d) => JSON.parse(JSON.stringify(d));

/**
 * Created by Aurélien Rieu on 04/06/2019
 */
export interface DriverStateModel extends GenericStateModel
{
    editing: boolean;
    edited: boolean;
    currentDriver: Driver;
    administrativeAreas: AdministrativeArea[];
    translatedError: any;
}

export const driverInitialState = {
    editing: false,
    edited: false,
    currentDriver: null,
    administrativeAreas: [],
    translatedError: null,
    ...GenericState.init()
};

import { Injectable } from '@angular/core';

@State({
    name: 'driver',
    defaults: driverInitialState
})
@Injectable()
export class DriverState
{
    // region Constructor

    constructor(private driverService: DriverService,
                private administrativeAreasService: AdministrativeAreasService,
                private workingAreasService: WorkingAreasService,
                private translate: MysamTranslateService,
                private store: Store)
    {
    }

    // endregion

    // region Selector

    @Selector()
    static currentDriver(state: DriverStateModel): Driver
    {
        return state.currentDriver;
    }

    @Selector()
    static currentDriverDetails(state: DriverStateModel): DriverDetails
    {
        return state.currentDriver.driverDetails;
    }

    @Selector()
    static administrativeAreas(state: DriverStateModel): AdministrativeArea[]
    {
        return state.administrativeAreas;
    }

    @Selector()
    static editing(state: DriverStateModel): boolean
    {
        return state.editing;
    }

    @Selector()
    static edited(state: DriverStateModel): boolean
    {
        return state.edited;
    }

    @Selector()
    static error(state: DriverStateModel): DriverError
    {
        return state.error;
    }

    @Selector()
    static translatedError(state: DriverStateModel): string
    {
        return state.translatedError;
    }

    // endregion

    // region LoadDriverById

    @Action(LoadDriverById)
    loadDriverById(ctx: StateContext<DriverStateModel>, action: LoadDriverById)
    {
        ctx.patchState({
            currentDriver: null,
            editing: false,
            edited: false,
            translatedError: null,
            ...GenericState.load()
        });

        return this.driverService.getDriverById(action.userId).pipe(
            map((driver: Driver) => this.loadDriverByIdSuccess(ctx, driver)),
            catchError((error: any) =>
                of(this.loadDriverByIdFail(ctx, this.driverService.buildDriverError(error))))
        );
    }

    @Action(LoadDriverByIdSuccess)
    loadDriverByIdSuccessAction(ctx: StateContext<DriverStateModel>, action: LoadDriverByIdSuccess)
    {
        return this.loadDriverByIdSuccess(ctx, action.driver);
    }

    @Action(DriverDetailsUpdatedAction)
    driverDetailsUpdated(ctx: StateContext<DriverStateModel>, action: DriverDetailsUpdatedAction): DriverStateModel
    {
        /**
         * We update the DriverDetails of the current Driver
         * We need to clone "ctx.getState().currentDriver" into a new variable, otherwise the object is immutable
         * and we can't replace its "driverDetails".
         * Previously, we used Driver.assign() to do that, but it messes up with enum definitions (in the copied object, the enum values
         * are "undefined" after calling "Driver.assign()"). So we replaced this call by a simple "clone()"
         */
        const currentDriver = _clone(ctx.getState().currentDriver);
        currentDriver.driverDetails = action.driverDetails;

        /**
         * And we put it back into our store
         */
        return ctx.patchState({ currentDriver });
    }

    // noinspection JSMethodCanBeStatic
    loadDriverByIdSuccess(ctx: StateContext<DriverStateModel>, driver: Driver)
    {
        return ctx.patchState({
            currentDriver: driver,
            ...GenericState.success()
        });
    }

    // noinspection JSMethodCanBeStatic
    loadDriverByIdFail(ctx: StateContext<DriverStateModel>, error: DriverError)
    {
        const translatedError = error.getErrorMessage(this.translate);
        return ctx.patchState({
            currentDriver: null,
            translatedError,
            ...GenericState.error(error)
        });
    }

    // endregion

    // region EditDriver

    @Action(EditDriver)
    EditDriver(ctx: StateContext<DriverStateModel>, action: EditDriver)
    {
        ctx.patchState({
            editing: true,
            edited: false,
            translatedError: null,
            ...GenericState.load()
        });

        return this.driverService.updateDriver(action.userId, action.changes).pipe(
            map((driver: Driver) => this.editDriverSuccess(ctx, driver)),
            catchError((error: any) =>
                of(this.editDriverFail(ctx, this.driverService.buildDriverError(error))))
        );
    }

    // noinspection JSMethodCanBeStatic
    editDriverSuccess(ctx: StateContext<DriverStateModel>, driver: Driver)
    {
        return ctx.patchState({
            currentDriver: driver,
            editing: false,
            edited: true,
            ...GenericState.success()
        });
    }

    // noinspection JSMethodCanBeStatic
    editDriverFail(ctx: StateContext<DriverStateModel>, error: DriverError)
    {
        const translatedError = error.getErrorMessage(this.translate);
        return ctx.patchState({
            editing: false,
            edited: false,
            translatedError,
            ...GenericState.error(error)
        });
    }

    // endregion

    // region LoadAdministrativeAreasForDriver

    @Action(LoadAdministrativeAreasForDriver)
    LoadAdministrativeAreasForDriver(ctx: StateContext<DriverStateModel>, action: LoadAdministrativeAreasForDriver)
    {
        ctx.patchState({
            ...GenericState.load()
        });

        return this.administrativeAreasService.getAdministrativeAreasForDriver(action.driverId).pipe(
            map((administrativeAreas: AdministrativeArea[]) => this.loadAdministrativeAreasForDriverSuccess(ctx, administrativeAreas)),
            catchError((error: any) =>
                of(this.loadAdministrativeAreasForDriverFail(ctx, this.administrativeAreasService.buildAdministrativeAreasError(error))))
        );
    }

    // noinspection JSMethodCanBeStatic
    loadAdministrativeAreasForDriverSuccess(ctx: StateContext<DriverStateModel>, administrativeAreas: AdministrativeArea[])
    {
        return ctx.patchState({
            administrativeAreas: administrativeAreas,
            ...GenericState.success()
        });
    }

    // noinspection JSMethodCanBeStatic
    loadAdministrativeAreasForDriverFail(ctx: StateContext<DriverStateModel>, error: DriverError)
    {
        const translatedError = error.getErrorMessage(this.translate);
        return ctx.patchState({
            administrativeAreas: [],
            translatedError,
            ...GenericState.error(error)
        });
    }

    // endregion

    // region UpdateDriverWorkingAreas

    @Action(UpdateDriverWorkingAreas)
    UpdateDriverWorkingAreas(ctx: StateContext<DriverStateModel>, action: UpdateDriverWorkingAreas)
    {
        ctx.patchState({
            editing: true,
            edited: false,
            translatedError: null,
            ...GenericState.load()
        });

        return this.workingAreasService.updateDriverWorkingAreas(action.driverId, action.administrativeAreaIds).pipe(
            map((driver: Driver) => this.updateDriverWorkingAreasSuccess(ctx, driver)),
            catchError((error: any) =>
                of(this.updateDriverWorkingAreasFail(ctx, this.workingAreasService.buildWorkingAreasError(error))))
        );
    }

    // noinspection JSMethodCanBeStatic
    updateDriverWorkingAreasSuccess(ctx: StateContext<DriverStateModel>, driver: Driver)
    {
        this.store.dispatch(new LoadAdministrativeAreasForDriver(driver.userId));
        return ctx.patchState({
            editing: false,
            edited: true,
            ...GenericState.success()
        });
    }

    // noinspection JSMethodCanBeStatic
    updateDriverWorkingAreasFail(ctx: StateContext<DriverStateModel>, error: WorkingAreasError)
    {
        const translatedError = error.getErrorMessage(this.translate);
        return ctx.patchState({
            editing: false,
            edited: false,
            translatedError,
            ...GenericState.error(error)
        });
    }

    // endregion
}
