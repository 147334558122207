import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AlfredState } from '../store/reducers';
import { CurrentUserIsPayingGuard } from '../../../../msl-driver-registration/src/lib/authentication/guards/current-paying-user-guard.service';
import { AccessRightsService } from '../../../../msl-driver-registration/src/lib/authentication/access-rights/access-rights.service';
import { Observable } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';
import { Go } from '../../app/store/actions';

/**
 * Created by Adrien Dos Reis on 20/10/2020
 *
 * Common behavior between "BoundTo..." guards
 */
export abstract class AbstractBoundToGuard
{
    // region Constructor

    protected constructor(private store: Store<AlfredState>,
                          private currentUserLoadedAndPayingGuard: CurrentUserIsPayingGuard,
                          private accessRights: AccessRightsService)
    {
    }

    // endregion

    shouldActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, shouldBeBoundToMySam: boolean): Observable<boolean>
    {
        // First, we ensure that the parent "canActivate" is valid.
        return this.currentUserLoadedAndPayingGuard.canActivate(route, state).pipe(
            filter((parentResult: boolean) => !!parentResult),

            /**
             * Now, if the parent "canActivate" is valid, we can check the second step :
             * We allow access only if "isUserBoundToMySam$" matches "shouldBeBoundToMySam"
             */
            flatMap(() => this.isBoundToMySam(shouldBeBoundToMySam))
        );
    }

    /**
     * Returns an Observable containing a boolean indicating whether the current User is bound to MySam (if
     * "shouldBeBoundToMySam" is true), or bound to a Company (if "shouldBeBoundToMySam" is false)
     */
    private isBoundToMySam(shouldBeBoundToMySam: boolean): Observable<boolean>
    {
        return this.accessRights.isUserBoundToMySam$.pipe(
            map((isMySam: boolean) =>
            {
                // If "isMySam" matches our "shouldBeBoundToMySam", we're good !
                if (isMySam === shouldBeBoundToMySam)
                {
                    return true;
                }
                else // If the user is bound to a company, we redirect to a 404
                {
                    this.store.dispatch(new Go({ path: [ '/404' ] }));
                    return false;
                }
            }));
    }
}
