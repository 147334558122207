import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { dependencies, libraryDependencies, ngxsStates, services } from './index';
import { NgxsModule } from '@ngxs/store';

@NgModule({
    imports: [
        CommonModule,
        NgxsModule.forFeature([ ...ngxsStates ]),
        ...libraryDependencies
    ],
    declarations: [ ...dependencies ],
    providers: [ ...services ],
    exports: [ ...dependencies, ...libraryDependencies ]
})
export class UserAutocompleteModule
{
}
