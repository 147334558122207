/**
 * Created by Adrien Dos Reis on 21/02/2019
 */
import { SelectCompanyComponent } from './components/select-company/select-company.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MysamTranslateModule } from 'msl-translate';
import { CompaniesState } from './state/companies.state';
import { CompaniesService } from './services/companies.service';
import { CommonModule } from '@angular/common';

export const materialDependencies = [ MatFormFieldModule, MatSelectModule, MatDividerModule ];

export const libraryDependencies = [ CommonModule, MysamTranslateModule ];

export const declarations = [ SelectCompanyComponent ];

export const services = [ CompaniesService ];

export const ngxsStates = [ CompaniesState ];
