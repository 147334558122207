import { Action } from '@ngrx/store';
import { Trip } from '../../../../../mys-base/src/lib/models/trip';
import {TripAppealType} from '../../../../../mys-base/src/lib/models/constants/trip/trip-appeal-type';

// Change the appeal property from a Trip
export const TRIP_APPEAL_UPDATE = '[Alfred] Trip appeal update';
export const TRIP_APPEAL_UPDATE_FAIL = '[Alfred] Trip appeal update Fail';
export const TRIP_APPEAL_UPDATE_SUCCESS = '[Alfred] Trip appeal update Success';

export class TripAppealUpdate implements Action
{
    readonly type = TRIP_APPEAL_UPDATE;

    constructor(public id: number, public appeal: TripAppealType)
    {
    }
}

export class TripAppealUpdateFail implements Action
{
    readonly type = TRIP_APPEAL_UPDATE_FAIL;

    constructor(public payload: any)
    {
    }
}

export class TripAppealUpdateSuccess implements Action
{
    readonly type = TRIP_APPEAL_UPDATE_SUCCESS;

    constructor(public payload: Trip)
    {
    }
}

// Action types
export type TripAppealUpdateAction = TripAppealUpdate | TripAppealUpdateFail | TripAppealUpdateSuccess;
