import { Action } from '@ngrx/store';
import { Trip } from '../../../../../mys-base/src/lib/models/trip';
import { TripFilter } from '../../http/responses/filters/trips/trip-filter';
import { TripFilters } from '../../http/responses/filters/trips/trip-filters';
import { Page } from '../../../../../msl-driver-registration/src/lib/http/responses/pagination/page';

// Load Trips
export const LOAD_TRIPS = '[Alfred] Load Trips';
export const LOAD_TRIPS_FAIL = '[Alfred] Load Trips Fail';
export const LOAD_TRIPS_SUCCESS = '[Alfred] Load Trips Success';

// Load newest trip IDs
export const LOAD_NEWEST_TRIP_IDS = '[Alfred] Load Newest Trip IDs';
export const LOAD_NEWEST_TRIP_IDS_FAIL = '[Alfred] Load Newest Trip IDs Fail';
export const LOAD_NEWEST_TRIP_IDS_SUCCESS = '[Alfred] Load Newest Trip IDs Success';

// Load Trips as csv
export const LOAD_TRIPS_CSV = '[Alfred] Load Trips as csv';
export const LOAD_TRIPS_CSV_FAIL = '[Alfred] Load Trips as csv Fail';
export const LOAD_TRIPS_CSV_SUCCESS = '[Alfred] Load Trips as csv Success';
export const RESET_NEWEST_TRIP_IDS = '[Alfred] Reset newest trip ids';

export class LoadTrips implements Action
{
    readonly type = LOAD_TRIPS;

    constructor(public payload: TripFilter)
    {
    }
}

export class LoadTripsFail implements Action
{
    readonly type = LOAD_TRIPS_FAIL;

    constructor(public payload: any)
    {
    }
}

export class LoadTripsSuccess implements Action
{
    readonly type = LOAD_TRIPS_SUCCESS;

    constructor(public payload: Page<Trip>)
    {
    }
}

export class LoadNewestTripIDs implements Action
{
    readonly type = LOAD_NEWEST_TRIP_IDS;

    constructor()
    {
    }
}

export class LoadNewestTripIDsFail implements Action
{
    readonly type = LOAD_NEWEST_TRIP_IDS_FAIL;

    constructor(public payload: any)
    {
    }
}

export class LoadNewestTripIDsSuccess implements Action
{
    readonly type = LOAD_NEWEST_TRIP_IDS_SUCCESS;

    constructor(public payload: number[])
    {
    }
}

export class LoadTripsCsv implements Action
{
    readonly type = LOAD_TRIPS_CSV;

    constructor(public payload: TripFilters)
    {
    }
}

export class LoadTripsCsvFail implements Action
{
    readonly type = LOAD_TRIPS_CSV_FAIL;

    constructor(public payload: any)
    {
    }
}

export class LoadTripsCsvSuccess implements Action
{
    readonly type = LOAD_TRIPS_CSV_SUCCESS;

    constructor(public payload: any)
    {
    }
}

export class ResetNewestTripIds
{
    readonly type = RESET_NEWEST_TRIP_IDS;
}

// Action types
export type TripsAction = LoadTrips | LoadTripsFail | LoadTripsSuccess
    | LoadNewestTripIDs | LoadNewestTripIDsFail | LoadNewestTripIDsSuccess
    | LoadTripsCsv | LoadTripsCsvFail | LoadTripsCsvSuccess | ResetNewestTripIds;
