import { ApiError } from 'mys-base';
import { MysamTranslateService } from 'msl-translate';

/**
 * Created by Adrien Dos Reis on 12/09/2019
 */
export class ForgotPasswordError extends ApiError
{
    constructor(apiError: ApiError)
    {
        super(apiError.type, apiError.description, apiError.httpStatus, apiError.extraParams);
    }

    public getErrorMessage(translate: MysamTranslateService): string
    {
        switch (this.type)
        {
            case ForgotPasswordError.Type.AlfredUserNotFound:
                return translate.instant(
                    'login-form.forgot-password.errors.EMAIL-NOT-FOUND', { email: this.extraParams.userId }
                );

            case ForgotPasswordError.Type.ClientNotFound:
                return translate.instant(
                    'login-form.forgot-password.errors.EMAIL-NOT-FOUND', { email: this.extraParams.userId }
                );

            default:
                return translate.instant('login-form.forgot-password.errors.DEFAULT');
        }
    }
}

export namespace ForgotPasswordError
{
    export enum Type
    {
        AlfredUserNotFound = 'ALFRED_USER_NOT_FOUND',
        ClientNotFound = 'CLIENT_NOT_FOUND'
    }
}
