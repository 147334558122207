import { DriverDetails } from 'mys-base';

export const DRIVER_DETAILS_UPDATED = '[Driver] Driver Details updated';

/**
 * The DriverDetails were updated by another store, this actions is used to update the current user in the Store
 */
export class DriverDetailsUpdatedAction
{
    static readonly type = DRIVER_DETAILS_UPDATED;

    constructor(public driverDetails: DriverDetails)
    {
    }
}
