import { Pipe, PipeTransform } from '@angular/core';
import { VehicleType } from 'mys-base';
import { $enum } from "ts-enum-util";

/**
 * Displays a DriverDetails object, or an empty string if an empty driver details
 * object was passed to the "transform" method
 */
@Pipe({ name: 'vehicleType' })
export class VehicleTypePipe implements PipeTransform
{
    static getIonClass(vehicleType: VehicleType): string
    {
        switch (vehicleType)
        {
            case VehicleType.LUXE:
            case VehicleType.PRIME:
                return 'car-sport';
            case VehicleType.VAN:
                return 'bus';
            case VehicleType.CAR:
                return 'car';
            case VehicleType.GREEN:
            case VehicleType.GREEN_PRIME:
                return 'leaf';
        }
    }

    static getVehicleTypeText(vehicleType: VehicleType) {
        return $enum(VehicleType).getValueOrDefault(vehicleType);
    }

    transform(vehicleType: string): string
    {
        if (!!vehicleType)
        {
            return VehicleType[ vehicleType ];
        }

        return '';
    }
}
