import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { SettingsService } from '../shared/settings/settings.service';
import { isLibertyDriver } from '../../environments/liberty-driver/environment.alfred';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: [ './layout.component.scss', './layout-variants.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit
{
    isLibertyDriver = isLibertyDriver;

    /**
     * The "mailto" link is generated from the Component, in order to hide it from the HTML (and from scrapping robots
     * over the Internet !)
     */
    mailAddress = 'contact@mysam.fr';
    mailtoLink = `mailto:${this.mailAddress}?subject=Question à propos de la plateforme de commande Business`;

    constructor(private settings: SettingsService)
    {
    }

    ngOnInit()
    {
    }

    layout()
    {
        return [

            this.settings.app.sidebar.visible ? 'sidebar-visible' : '',
            this.settings.app.sidebar.offcanvas ? 'sidebar-offcanvas' : '',
            this.settings.app.sidebar.offcanvasVisible ? 'offcanvas-visible' : ''

        ].join(' ');
    }

    closeSidebar()
    {
        this.settings.app.sidebar.visible = false;
    }
}
