export class Order
{
    direction: string;
    property: string;

    constructor(property: string, direction: string) {
        this.direction = direction;
        this.property = property;
    }
}
