import { MysamTranslateService } from 'msl-translate';
import { ApiError } from 'mys-base';

/**
 * Created by Adrien Dos Reis on 10/11/2020
 */
export class BankInformationError extends ApiError
{
    constructor(apiError: ApiError)
    {
        super(apiError.type, apiError.description, apiError.httpStatus, apiError.extraParams);
    }

    public getErrorMessage(translate: MysamTranslateService): string
    {
        switch (this.type)
        {
            case BankInformationError.Type.BicInvalid:
                return translate.instant('bank-information.errors.INVALID-BIC-W-PARAMS', { bic: this.extraParams.bic });

            case BankInformationError.Type.IbanInvalid:
                return translate.instant('bank-information.errors.INVALID-IBAN-W-PARAMS', { iban: this.extraParams.iban });

            case BankInformationError.Type.DriverNotFound:
                return translate.instant('errors.my-sam-errors.DRIVER-NOT-FOUND', { userId: this.extraParams.userId });

            /**
             * For now, we don't handle other errors
             */
            default:
                return translate.instant('errors.my-sam-errors.DEFAULT-ERROR');
        }
    }
}

export namespace BankInformationError
{
    export enum Type
    {
        // Business errors
        BicInvalid = 'BIC_INVALID', // bic
        IbanInvalid = 'IBAN_INVALID', // iban

        // Errors that should not happen in production (missing parameters, etc...)
        DriverNotFound = 'DRIVER_NOT_FOUND', // userId
    }
}
