import { AccessRightsService } from '../../../../../msl-driver-registration/src/lib/authentication/access-rights/access-rights.service';
import { Injectable } from '@angular/core';
import { WordReplacer } from 'msl-translate';

/**
 * Created by Adrien Dos Reis on 27/05/2020
 *
 * Service allowing to replace some words from any translated string, based on our Alfred business rules
 */
@Injectable({ providedIn: 'root' })
export class MysamWordReplacerService extends WordReplacer
{
    // region Constructor

    constructor(private accessRights: AccessRightsService)
    {
        super();
    }

    // endregion

    /**
     * Transforms the given "translatedString" by calling "replaceCaseSensitive" on it, based on the given language, but ONLY if the current
     * user is not bound to MySam
     * @param translatedString The string to transform
     * @param language The current language
     * @return The transformed string
     */
    replaceTerms(translatedString: string, language: string): string
    {
        /**
         * If the User is not bound to MySam, we replace a few terms from the given translated string
         *
         * Since this method can be called from everywhere, it is possible that we have no user yet.
         * In this case, "isUserBoundToMySamSub.value" will be undefined, and therefore falsy.
         * To avoid going into the "if" branch in this case, we need to ensure our "value" is explicitely false (and not "falsy")
         */
        if (this.accessRights.isUserBoundToMySamSub.value === false)
        {
            switch (language)
            {
                case WordReplacer.ENGLISH_LANGUAGE:
                    translatedString = this.replaceCaseSensitive(translatedString, 'alfred', 'business platform');
                    // translatedString = this.replaceCaseSensitive(translatedString, 'client', 'associate');
                    // "Employee" stays the same, so no need to translate a second time (as in french)
                    break;
                case WordReplacer.FRENCH_LANGUAGE:
                    /**
                     * If the user is bound to a Company, we want to change the strings "Vers MySam" & "Depuis MySam" to
                     * "Vers XXX" & "Depuis XXX", where XXX is the name of his Company
                     * We don't need to do this in English, since those strings are "Inbound" & "Outbound" in English (and therefore,
                     * no mention of "MySam")
                     * If the user is not bound to a Company, that means that he is assignable from a Driver, so we want to
                     * change the strings "Vers MySam" & "Depuis MySam" to "Vers ma société VTC" & "Depuis ma société VTC"
                     */
                    translatedString = this.replaceCaseSensitive(
                        translatedString, 'Vers MySam', !!this.accessRights.companySub.getValue()
                            ? `Vers ${this.accessRights.companySub.getValue().name}` : `Vers ma société VTC`);
                    translatedString = this.replaceCaseSensitive(
                        translatedString, 'Depuis MySam', !!this.accessRights.companySub.getValue()
                            ? `Depuis ${this.accessRights.companySub.getValue().name}` : `Depuis ma société VTC`);

                    translatedString = this.replaceCaseSensitive(translatedString, 'alfred', 'plateforme business');
                    // translatedString = this.replaceCaseSensitive(translatedString, 'collaborateur', 'référent');
                    // translatedString = this.replaceCaseSensitive(translatedString, 'client', 'collaborateur');
                    break;
            }
        }

        return translatedString;
    }
}
