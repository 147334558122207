import { GenericState, TranslatedState, TranslatedStateModel } from 'mys-base';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { BankInformationService } from '../services/bank-information.service';
import { PostNewBankInformation, ResetBankInformation } from '../actions/bank-information.action';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MysamTranslateService } from 'msl-translate';
import { BankInformationError } from '../errors/bank-information.error';

/**
 * Created by Adrien Dos Reis on 09/11/2020
 */
export interface BankInformationStateModel extends TranslatedStateModel
{
    errorType: string | null;
}

const initialState = {
    errorType: null,
    ...TranslatedState.init()
};

@State({
    name: 'bankInformation',
    defaults: initialState
})
@Injectable()
export class BankInformationState
{
    // region Selectors

    @Selector()
    static errorType(state: BankInformationStateModel): string | null
    {
        return state.errorType;
    }

    // endregion

    // region Constructor

    constructor(private bankInformationService: BankInformationService, private translate: MysamTranslateService)
    {
    }

    // endregion

    // region Post New BankInformation

    @Action(PostNewBankInformation)
    postNewBankInformation(ctx: StateContext<BankInformationStateModel>, action: PostNewBankInformation)
    {
        ctx.patchState({ ...TranslatedState.load() });

        return this.bankInformationService.postNewBankInfo(action.driver, action.iban, action.bic).pipe(
            map(() => ctx.patchState({ ...GenericState.success() })),
            catchError((error: HttpErrorResponse) => of(this.handlePostNewBankInfoErrors(ctx, error)))
        );
    }

    // endregion

    private handlePostNewBankInfoErrors(ctx: StateContext<BankInformationStateModel>, error: HttpErrorResponse)
    {
        const apiError = this.bankInformationService.createApiError(error);
        const errorMessage = apiError.getErrorMessage(this.translate);
        const errorType = apiError.type;

        ctx.patchState({ ...TranslatedState.error(errorMessage), errorType });
    }

    // region Reset

    @Action(ResetBankInformation)
    resetBankInformation(ctx: StateContext<TranslatedStateModel>)
    {
        return ctx.setState(initialState);
    }

    // endregion
}
