import {SpringAuthority} from 'mys-base';
import {MY_INVOICES_PATH} from '../../alfred/guards/liberty-driver-guard';

const Cards = {
    name: 'Cards',
    link: '/cards',
    order: 2,
    availableForRoles: [ SpringAuthority.ROLE_ADMIN ],
    imgpath: 'assets/img/icons/radio-waves.svg'
};

const Home = {
    name: 'menu.HOME',
    link: '/home',
    order: 1,
    availableForRoles: [ SpringAuthority.ROLE_PAYING_DRIVER ],
    iconclass: 'home-outline'
};

const Dashboard = {
    name: 'menu.DASHBOARD',
    link: '/dashboard',
    order: 1.1,
    availableForRoles: [ SpringAuthority.ROLE_ADMIN ],
    iconclass: 'desktop-outline'
};

const Drivers = {
    name: 'menu.DRIVERS',
    link: '/drivers/list',
    order: 1.5,
    availableForRoles: [ SpringAuthority.ROLE_ADMIN, SpringAuthority.ROLE_SUPERVISOR_DRIVER ],
    imgpath: 'assets/img/icons/person-stalker.svg'
};

const DriverActivity = {
    name: 'menu.DRIVER-ACTIVITY',
    link: '/driver-activity',
    order: 6,
    availableForRoles: [ SpringAuthority.ROLE_ADMIN ],
    iconclass: 'map-outline'
};

const Payments = {
    name: 'menu.PAYMENTS',
    link: '/payments',
    order: 7,
    availableForRoles: [ SpringAuthority.ROLE_ADMIN ],
    iconclass: 'logo-euro'
};

const AlfredUsers = {
    name: 'menu.ALFRED-USERS',
    link: '/alfred-users/list',
    order: 2,
    availableForRoles: [ SpringAuthority.ROLE_ADMIN, SpringAuthority.ROLE_COMPANY ],
    imgpath: 'assets/img/icons/person-stalker.svg'
};

const Clients = {
    name: 'menu.CLIENTS',
    link: '/clients/list',
    order: 3,
    availableForRoles: [ SpringAuthority.ROLE_ADMIN, SpringAuthority.ROLE_COMPANY, SpringAuthority.ROLE_PAYING_DRIVER ],
    imgpath: 'assets/img/icons/person-stalker.svg'
};

const Trips = {
    name: 'menu.TRIPS',
    link: '/trips/list',
    order: 4,
    availableForRoles: [ SpringAuthority.ROLE_ADMIN, SpringAuthority.ROLE_COMPANY, SpringAuthority.ROLE_PAYING_DRIVER ],
    imgpath: 'assets/img/icons/navicon.svg'
};

const Companies = {
    name: 'menu.COMPANIES',
    link: '/companies',
    order: 5,
    availableForRoles: [ SpringAuthority.ROLE_ADMIN ],
    imgpath: 'assets/img/icons/company.svg'
};

const MySubscription = {
    name: 'menu.MY-SUBSCRIPTION',
    link: '/my-subscription',
    order: 6,
    availableForRoles: [ SpringAuthority.ROLE_PAYING_DRIVER ],
    iconclass: 'clipboard-outline'
};

const Invoices = {
    name: 'menu.INVOICES',
    link: '/company-invoices',
    order: 7,
    availableForRoles: [ SpringAuthority.ROLE_COMPANY ],
    iconclass: 'document-outline'
};

const LibertyDriverInvoices = {
    name: 'menu.INVOICES',
    link: MY_INVOICES_PATH,
    order: 8,
    availableForRoles: [ SpringAuthority.ROLE_FREE_DRIVER, SpringAuthority.ROLE_PAYING_DRIVER ],
    iconclass: 'document-outline'
};

const AlfredParameters = {
    name: 'menu.PARAMETERS',
    link: '/parameters/trip-appeal',
    order: 99,
    availableForRoles: [ SpringAuthority.ROLE_ADMIN ],
    iconclass: 'settings-outline',
    subitems: [
        {
            name: 'menu.parameters.TRIP-APPEAL',
            link: '/parameters/trip-appeal'
        },
        {
            name: 'menu.parameters.PRICE-AND-INCREASE',
            link: '/parameters/price-and-increase-settings'
        },
        {
            name: 'menu.parameters.LIBERTY-SETTINGS',
            link: '/parameters/liberty-settings'
        },
    ]
};

/**
 * UPDATE 04/01/2021
 * The "AppSettings" parameters are now also available for Supervisors (since they can update their own pricings)
 *
 * UPDATE 17/04/2023
 * Rename "AppSettings" to "Price and increase"
 */
const DriverParameters = {
    name: 'menu.PARAMETERS',
    link: '/parameters/trip-appeal',
    order: 99,
    availableForRoles: [ SpringAuthority.ROLE_SUPERVISOR_DRIVER ],
    iconclass: 'settings-outline',
    subitems: [
        {
            name: 'menu.parameters.PRICE-AND-INCREASE',
            link: '/parameters/price-and-increase-settings'
        }
        /** 24/05/2023 - https://mysamcab.atlassian.net/browse/MYS-6231
         * Disable for now for Liberty Driver
         */
        // {
        //     name: 'menu.parameters.LIBERTY-SETTINGS',
        //     link: '/parameters/liberty-settings'
        // }
    ]
};

export const menu = [
    Home,
    Dashboard,
    Drivers,
    AlfredUsers,
    Trips,
    Clients,
    Companies,
    DriverActivity,
    Invoices,
    LibertyDriverInvoices,
    MySubscription,
    AlfredParameters,
    DriverParameters,
    Payments
];
