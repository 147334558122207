import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Company } from 'mys-base';
import { Select, Store } from '@ngxs/store';
import { CompaniesState } from '../../state/companies.state';
import { Observable } from 'rxjs';
import { GetCompaniesAction } from '../../actions/companies.action';

/**
 * Created by Adrien Dos Reis on 21/02/2019
 *
 * This Component displays all the Companies involved in business with MySam, and allow to select one of them
 */
@Component({
    selector: 'mys-select-company',
    templateUrl: './select-company.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectCompanyComponent implements OnInit
{
    // region Attributes

    // Companies in our database
    @Select(CompaniesState.companies) companies$: Observable<Company[]>;

    /**
     * Classes to add automatically to a <mys-select-company> element
     *
     * .fw => defined in utils.scss
     *
     * We could write : @HostBinding('class') classes = 'fw';
     * But in this case, any "class" added to our Component will be overwritten.
     *
     * With the notation below, the classes added to our Component are kept, and the "fw" class is added to them.
     */
    @HostBinding('class.fw') fullWidth = true;

    // endregion

    // region Inputs / Outputs

    @Input() shouldDisplayEmptyRow = true; // Set to false if an "empty" row should be displayed (to represent "No company")

    /**
     * Event emitted when a Company is selected (obviously)
     * Can be null, if the user selects the "No company" row
     */
    @Output() companyChange = new EventEmitter<Company | null>();
    @Input() company = Company;

    /**
     * Added on 24/04/2020
     * Since this Component can now be used to select Companies from a subset of all our Companies, we
     * add a new Input allowing to specify the Action to trigger to load those Companies.
     * By default, it is "GetCompaniesAction", in order to load all the Companies from our database
     */
    @Input() action = new GetCompaniesAction();

    // endregion

    // region Constructor & Lifecycle

    constructor(private store: Store)
    {
    }

    ngOnInit()
    {
        this.store.dispatch(this.action);
    }

    // endregion

    /**
     * Method used to compare two Companies, used by <mat-select> to pre-select the @Input company
     * @see https://material.angular.io/components/select/overview, and search for "compareWith"
     * @param c1 The first Company
     * @param c2 The second Company
     * @return true if both Companies are equal (= their IDs are the same)
     */
    compareTwoCompanies(c1: Company, c2: Company): boolean
    {
        return !!c1 && !!c2 && c1.id === c2.id;
    }
}
