import { ApiService, Company } from 'mys-base';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pageable } from '../../http/responses/pagination/pageable';
import { Page } from '../../http/responses/pagination/page';

/**
 * Created by Adrien Dos Reis on 21/02/2019
 */
@Injectable({
    providedIn: 'root'
})
export class CompaniesService extends ApiService
{
    private readonly COMPANIES_URL = this.BASE_URL + '/alfred/companies';
    private readonly GROUPS_OF_DRIVERS_URL = this.BASE_URL + '/alfred/group-of-drivers';

    /**
     * Gets all the Companies of our database
     */
    public getCompanies(name: string | null = null): Observable<Company[]>
    {
        /**
         * If a "name" is given, we add it to our Query Params
         */
        let query = '';
        if (!!name)
        {
            query = `?name=${name}`;
        }

        return this.httpClient.get<Company[]>(this.COMPANIES_URL + query).pipe(
            map(companies => companies.map(company => Object.assign(new Company(), company)))
        );
    }

    /**
     * Gets all the Companies of our database, with some pagination to structure the data
     */
    public getCompaniesPaginated(pageable: Pageable): Observable<Page<Company>>
    {
        return this.httpClient.get<Page<Company>>(`${this.COMPANIES_URL}/page?page=${pageable.page}&size=${pageable.size}`).pipe(
            map((page: Page<any>) =>
            {
                page = Object.assign(new Page<Company>(), page);
                page.content = page.content.map(company => Object.assign(new Company(), company));
                return page;
            }));
    }

    public getGroupsOfDrivers(): Observable<Company[]>
    {
        return this.httpClient.get<Company[]>(this.GROUPS_OF_DRIVERS_URL).pipe(
            map(companies => companies.map(company => Object.assign(new Company(), company)))
        );
    }
}
