import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PaymentMethod } from 'mys-base';
import { IconComponent } from './icon.component';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';

@Component({
    selector: 'mys-payment-method',
    template: IconComponent.template,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentMethodComponent extends IconComponent implements OnChanges
{
    @Input() method: PaymentMethod;
    @Input() cash: boolean | null = null;

    ngOnChanges(changes: SimpleChanges): void
    {
        /**
         * The Changes are independent : The method can be changed (if the filter is set from "IN_APP" to "ON_BOARD", for example),
         * but the "cash" can also changed without changing the method (if the filter is set from "Cash" to "CB", the method is still
         * "ON_BOARD")
         *
         * Therefore, we handle them separately
         */
        const newMethod = !!changes.method ? changes.method.currentValue : this.method;
        const newCash = !!changes.cash ? changes.cash.currentValue : this.cash;

        this.setPaymentMethodIcon(newMethod, newCash);
    }

    /**
     * Draws a PaymentMethod icon adapted to the given PaymentMethod and the "willBePaidInCash" if needed
     * @param paymentMethod The new PaymentMethod
     * @param willBePaidInCash Used ONLY if the PaymentMethod is "ON_BOARD". Otherwise, it is ignored
     */
    private setPaymentMethodIcon(paymentMethod: PaymentMethod, willBePaidInCash: boolean)
    {
        this.setTitleAsync(paymentMethod, willBePaidInCash);
        switch (paymentMethod)
        {
            case PaymentMethod.ON_BOARD:
                this.imageName = willBePaidInCash ? 'cash.png' : 'credit-card.png';
                break;
            case PaymentMethod.DEFERRED:
                this.imageName = 'debt.png';
                break;
            default: // IN_APP
                this.imageName = 'mobile.png';
                break;
        }
    }

    /**
     * Sets the "title" field asynchronously, once the given parameter has been translated
     * @param {string} titleToTranslate
     * @param {boolean | null} willBePaidInCash If set, the title$ underlying string will be completed with an extra info
     * (cash or credit card)
     */
    protected setTitleAsync(titleToTranslate: string, willBePaidInCash: boolean | null): void
    {
        this.title$ = super.setTitleTranslationAsync(titleToTranslate).pipe(
            switchMap(title => this.addExtraCashInfo(title, willBePaidInCash))
        );
    }

    /**
     * Adds extra info to the given translated title, if there is any info about a "cash" or "credit card" payment
     * @param {string} translatedTitle The title to append some extra info to
     * @param {boolean | null} willBePaidInCash {@code true} if paid in cash, {@code false} if paid on board with a credit card
     * @return {Observable<string>}
     */
    private addExtraCashInfo(translatedTitle: string, willBePaidInCash: boolean | null): Observable<string>
    {
        let obs: Observable<string> = of(translatedTitle);
        if (willBePaidInCash != null)
        {
            obs = this.translate.get(willBePaidInCash ? 'enums.payment-method.cash' : 'enums.payment-method.cb')
                .pipe(map(method => translatedTitle + ' - ' + method));
        }

        return obs;
    }
}
