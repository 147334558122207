import { Pipe, PipeTransform } from '@angular/core';
import { MysamTranslateService } from 'msl-translate';
import { DatePipe } from '@angular/common';
import { Constants } from '../models/constants/constants';

/**
 * Displays a UserPenalty object as "transactionId - tripId - amount - date", or an empty string if an empty UserPenalty
 * object was passed to the "transform" method
 */
@Pipe({
    name: 'userPenalty'
})
export class UserPenaltyPipe implements PipeTransform
{
    private TRIP_TRANSLATED;
    private AMOUNT_TRANSLATED;
    private CURRENCY_TRANSLATED;
    private DATE_TRANSLATED;

    constructor(translate: MysamTranslateService, public datePipe: DatePipe)
    {
        translate.get([
            'penalties.TRIP',
            'penalties.AMOUNT',
            'penalties.CURRENCY',
            'penalties.DATE'
        ]).subscribe(result =>
        {
            this.TRIP_TRANSLATED = result[0];
            this.AMOUNT_TRANSLATED = result[1];
            this.CURRENCY_TRANSLATED = result[2];
            this.DATE_TRANSLATED = result[3];
        });
    }

    transform(userPenalty?: { id, tripId, amount, date }): string
    {
        if (!!userPenalty)
        {
            return this.TRIP_TRANSLATED +
                userPenalty.tripId +
                this.AMOUNT_TRANSLATED +
                userPenalty.amount +
                this.CURRENCY_TRANSLATED +
                this.DATE_TRANSLATED +
                this.datePipe.transform(userPenalty.date, Constants.DD_MM_YYY___HH_MM___FR);
        }

        return '';
    }
}
