export const RELEASE_TRIP_EXCLUSIVITY = '[Alfred] Release Trip exclusivity';

export class ReleaseTripExclusivityAction
{
    static readonly type = RELEASE_TRIP_EXCLUSIVITY;

    constructor(public payload: number)
    {
    }
}
