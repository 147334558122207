import { ApiError } from 'mys-base';
import { MySamError } from '../my-sam-error';
import { $enum } from 'ts-enum-util';
import { MysamTranslateService } from 'msl-translate';

export class UserUpdateApiError extends ApiError
{
    constructor(apiError: ApiError)
    {
        super(apiError.type, apiError.description, apiError.httpStatus, apiError.extraParams);
    }

    public getErrorMessage(translate: MysamTranslateService): string
    {
        switch (this.type) {
            case $enum(MySamError).getKeyOrDefault(MySamError.EMAIL_ALREADY_EXISTS): {
                return translate.instant(MySamError.USER_UPDATE_BASE) + ' ' +
                    translate.instant(MySamError.EMAIL_ALREADY_EXISTS);
            }
            case $enum(MySamError).getKeyOrDefault(MySamError.INVALID_EMAIL_SYNTAX): {
                return translate.instant(MySamError.USER_UPDATE_BASE) + ' ' +
                    translate.instant(MySamError.INVALID_EMAIL_SYNTAX);
            }
            default: {
                return translate.instant(MySamError.USER_UPDATE_BASE) + ' ' +
                    translate.instant(MySamError.DEFAULT_ERROR);
            }
        }
    }
}
