import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Driver, GenericState, TranslatedState } from 'mys-base';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { PostNewBankInformation, ResetBankInformation } from '../../actions/bank-information.action';
import { BankInformationState } from '../../states/bank-information.state';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { BankInformationError } from '../../errors/bank-information.error';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'mys-new-bank-information[driver]',
    templateUrl: './new-bank-information.component.html',
    styleUrls: [ './new-bank-information.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewBankInformationComponent implements OnInit, OnDestroy
{
    // region Inputs / Outputs

    @Input() driver: Driver;
    @Output() newBankInfoAdded = new EventEmitter<void>(); // Emitted when the upload of a new Bank Info succeeded
    @Output() errorWhileAdding = new EventEmitter<void>(); // Emitted when the upload of a new Bank Info failed

    // endregion

    // region Selectors

    @Select(GenericState.loadedSelector(BankInformationState)) newBankInfoAdded$: Observable<boolean>;
    @Select(TranslatedState.translatedErrorSelector(BankInformationState)) errorMessage$: Observable<string>;
    @Select(BankInformationState.errorType) errorType$: Observable<string>;

    // endregion

    // region Attributes

    ibanCtrl = new UntypedFormControl('', Validators.required);
    bicCtrl = new UntypedFormControl('', Validators.required);

    private subscription = new Subscription();

    // endregion

    // region Constructor

    constructor(private readonly store: Store, private readonly toastr: ToastrService,
                private readonly cdRef: ChangeDetectorRef)
    {
    }

    // endregion

    /**
     * Submits the given form and sends the "Post New Bank Info" payload to the server if the form is valid
     * Returns a boolean true if a PostNewBankInformation has been sent to the server (that does NOT mean the request
     * is successful, but that means the client side validation is OK)
     */
    submit(): boolean
    {
        if (this.ibanCtrl.valid && this.bicCtrl.valid)
        {
            this.store.dispatch(new PostNewBankInformation(this.driver, this.ibanCtrl.value, this.bicCtrl.value));
            return true;
        }
        else
        {
            /**
             * We mark both elements as "touched", to let Angular know that the fields should have been interacted
             * with, and their errors can be displayed (if any)
             */
            this.ibanCtrl.markAsTouched();
            this.bicCtrl.markAsTouched();

            return false;
        }
    }

    // region Lifecycle

    ngOnInit()
    {
        /**
         * When a new Bank Info is added successfully, we emit the event to let the caller know about it
         */
        this.subscription.add(
            this.newBankInfoAdded$.pipe(
                filter(newBankInfoAdded => !!newBankInfoAdded),
                tap(() => this.newBankInfoAdded.emit())
            ).subscribe()
        );

        /**
         * Error handling
         */
        this.subscription.add(
            /**
             * We have a specific process here :
             * The errors message might be displayed as a <mat-errors> under a specific form field.
             * In every scenario, we should also have an "errors type". We analyze this "type", and if it is
             * recognized as an errors that should be displayed under one specific field, we bind it to the field.
             * Otherwise, we handle it like we usually do : by displaying an errors toast
             */
            combineLatest(this.errorType$, this.errorMessage$).pipe(
                filter(([ type, message ]) => !!type && !!message),
                tap(([ type, message ]) => {

                    /**
                     * Based on the value of "type", we will display the errors on the most appropriate FormControl
                     */
                    let formControl;
                    switch (type)
                    {
                        case BankInformationError.Type.IbanInvalid: formControl = this.ibanCtrl; break;
                        case BankInformationError.Type.BicInvalid: formControl = this.bicCtrl; break;
                    }

                    /**
                     * If we have a "formControl", we display the message on it
                     */
                    if (!!formControl)
                    {
                        formControl.setErrors({ 'invalidatedFromServer': message });
                        this.cdRef.detectChanges(); // Needed to refresh the UI
                    }
                    /**
                     * No "formControl" -> We display the message in a toast
                     */
                    else
                    {
                        this.toastr.error(message);
                    }

                    /**
                     * We let the calling component know about the fact that an errors occurred
                     */
                    this.errorWhileAdding.emit();
                })
            ).subscribe()
        );
    }

    ngOnDestroy()
    {
        this.store.dispatch(new ResetBankInformation());
        this.subscription.unsubscribe();
    }

    // endregion
}

