import { NgModule } from '@angular/core';
import { CurrentUserLoadedGuard } from './guards/current-user-loaded-guard';
import { LibertyDriverPayingDriverGuard } from './guards/liberty-driver-paying-driver-guard';
import { MslLoginModule, MySamProtectedGuard } from 'msl-login';
import {CurrentUserIsPayingGuard} from "./guards/current-paying-user-guard.service";

/**
 * Extends MslLoginModule by adding the Guards that are Alfred-specific
 */
@NgModule({
    imports: [ MslLoginModule.forRoot([
        /**
         * Guards use throughout the whole app (Alfred / Subscription)
         * Defined here because CurrentUserLoadedAndPayingGuard and PayingDriverGuard depend on CurrentUserLoadedGuard, and we want
         * CurrentUserLoadedGuard to replace the default ProtectedGuard (see the line below)
         */
        CurrentUserLoadedGuard, CurrentUserIsPayingGuard, LibertyDriverPayingDriverGuard,
        { provide: MySamProtectedGuard, useClass: CurrentUserLoadedGuard }
    ]) ]
})
export class AuthenticationModule
{

}
