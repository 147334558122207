import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { PendingApprovalBannerComponent } from './components/pending-approval-banner/pending-approval-banner.component';
import { DownloadMobileAppModule } from '../../../../../msl-driver-registration/src/lib/download-mobile-app/download-mobile-app.module';
import { MysamTranslateModule } from 'msl-translate';

const components = [ PendingApprovalBannerComponent ];

@NgModule({
    imports: [
        CommonModule,
        DownloadMobileAppModule,
        SharedModule,
        MysamTranslateModule
    ],
    declarations: [ ...components ],
    exports: [ ...components ]
})
export class MysamLayoutModule
{
}
