<div class="row m-0" [ngClass]="getBackgroundColorPriority(alert.priority)">

    <!-- Text column -->
    <!-- We add a ngClass here because when none button as displayed the visual was broken -->
    <div class="col-10">
        <!-- Trip id text -->
        <span class="fw-bold pointer" *ngIf="!!alert?.tripId"
              (click)="openOnNewTab('/trips/' + alert.tripId)">
            {{ 'trip-detail.TITLE' | mysamTranslate }} {{ alert?.tripId }}
        </span>
        <br>

        <!-- Client and driver text -->
        <ng-template *ngIf="!!alert?.driver" [ngTemplateOutlet]="clientOrDriverClickableText"
                     [ngTemplateOutletContext]="{ urlToGo: '/drivers/' + alert?.driver?.userId, data: alert?.driver }"></ng-template>
        <ng-template *ngIf="!!alert?.client" [ngTemplateOutlet]="clientOrDriverClickableText"
                     [ngTemplateOutletContext]="{ urlToGo: '/clients/' + alert?.client?.userId, data: alert?.client }"></ng-template>

        <span class="text-gray-light ms-1 w-auto"> {{ getDeltaBetweenCreationToNow() }}</span>
        <br>
        <span>{{ alert.description }}</span>
    </div>

    <!-- Button column -->
    <div class="col-2 d-flex justify-content-end align-items-center">
        <!--
                Button to bind alert to operator, only displayed on unassigned alert tab
                In the "unassigned" tab, the Alerts can either be :
                - With a null operator (= unassigned)
                OR
                - With the "isAlertSuccessfullyBound$" boolean to true : That means the alert WAS unassigned but is
                just assigned to an operator now. In this case we still want to display the template (which will display
                a green check button to provide feedback about this assignation)

                We also want to hide this button if the current alert was resolved successfully (otherwise, it would
                be displayed as a green check, and we would have two green checks next to each other. We only want one)
        -->
        <ng-template *ngIf="!alert.operator || (isAlertSuccessfullyBound$ | async) && !(isAlertSuccessfullyResolved$ | async)"
                     [ngTemplateOutlet]="actionBtn"
                     [ngTemplateOutletContext]="{ clickFn: bindAlertToOperator.bind(this), icon: 'add_alert',
             loading: isCurrentAlertBeingBound$, success: isAlertSuccessfullyBound$,
             error: isAlertBindError$, tooltip: 'BIND-ALERT-TOOLTIP'}"></ng-template>

        <!--
           Button to unbind alert to operator, only displayed on assigned alert tab
           NOT DISPLAYED for the moment, it may be uncommented during the V2 alert-row
        -->
        <!--    <ng-template *ngIf="!!alert.operator" [ngTemplateOutlet]="btnActionAlert"-->
        <!--                 [ngTemplateOutletContext]="{ clickFn: bindAlertToOperator.bind(this), icon: 'notifications_off',-->
        <!--             isLoading: isAlertBeingUnbound, tooltip: 'UNASSIGNED-ALERT-TOOLTIP'}"></ng-template>-->

        <!-- Button to mark the alert as resolved -->
        <ng-template [ngTemplateOutlet]="actionBtn"
                     [ngTemplateOutletContext]="{ clickFn: resolveAlert.bind(this), icon: 'thumb_up',
             loading: isAlertResolving$, success: isAlertSuccessfullyResolved$, error: isAlertResolveError$,
             tooltip: 'RESOLVE-ALERT-TOOLTIP'}"></ng-template>
    </div>
</div> <!-- End AlertRow -->

<!-- //////////////////////////////////////////////////// -->
<!-- Reusable code blocks defined here -->
<!-- //////////////////////////////////////////////////// -->
<ng-template #actionBtn let-clickFn=clickFn
             let-icon=icon
             let-isLoading=loading let-isSuccess=success let-error=error
             let-tooltip=tooltip>
    <!-- "Default" button that should NOT be changed until an action is triggered on it -->
    <msl-accent-button *ngIf="!(isSuccess | async) && !(error | async)"
                       [displayLoader]="isLoading | async"
                       [class]="'thumb36 mx-1'"
                       (click)="clickFn()"
                       [matTooltip]="'notification.'+ tooltip | mysamTranslate">
        <mat-icon>{{ icon }}</mat-icon>
    </msl-accent-button>
    <!-- Replaces the button when the action is successful with a button before the alert disappears -->
    <msl-primary-button *ngIf="isSuccess | async" [class]="'thumb36 mx-1'">
        <mat-icon>check_circle</mat-icon>
    </msl-primary-button>
    <!-- Replaces the button when the action failed with an error button to avoid possible spam -->
    <!--    <msl-accent-button *ngIf="error | async" [class]="'thumb36 mx-1 mat-warn'"><mat-icon>cancel</mat-icon></msl-accent-button>-->
</ng-template>

<ng-template #clientOrDriverClickableText
             let-urlTogo=urlToGo let-data=data>
    <span class="fw-bold pointer" (click)="openOnNewTab(urlTogo)"> {{ data | user }} </span>
    <br>
</ng-template>
