import { DriverDetails } from '../driver-details';
import { DriverStatus } from '../constants/driver-status';
import { MSLocation } from '../../utils/ms-location';
import { $enum } from 'ts-enum-util';
import { DriverAccountLimit } from '../constants/driver-account-limit';
import { MobileUser } from './mobile-user';
import { ConnectableUser } from './connectable-user';
import { Country } from '../country';

export class Driver extends MobileUser
{
    // region Attributes

    companyName: string;
    status: DriverStatus;
    driverDetails: DriverDetails;
    endOfSuspension: Date;
    reservationAlert: boolean;
    premium: boolean;
    isLibertyMobile: boolean;
    credits: number;
    location: MSLocation | null;
    accountLimit: DriverAccountLimit;
    hasActiveBank: boolean;
    country: Country | null;
    isHighQuality: boolean;
    inOpenedForSignupArea: boolean;

    // endregion

    /**
     * Needed for OAuthToken to call dynamically the appropriate "assign" method based on the correct
     * ConnectableUser subtype
     */
    assign(unassignedDriver: any): Driver
    {
        return Driver.assign(unassignedDriver)
    }

    /**
     * Returns true if the current driver can accept immediate trips
     */
    isAvailableForImmediates(): Boolean
    {
        return [ DriverStatus.AVAILABLE, DriverStatus.AVAILABLE_IMMEDIATES_ONLY ].indexOf(this.status) > -1;
    }

    // region Static Methods

    /**
     * Returns a boolean indicating whether the given "maybeDriver" is a Driver or not
     * @param maybeDriver
     */
    static isDriver(maybeDriver: any): boolean
    {
        /**
         * Checking if "maybeDriver" is a Driver consists in two things :
         * - "maybeDriver" is a ConnectableUser (at least)
         * - "maybeDriver" has a "driverDetails" attributes
         *
         * Since Typescript works with a duck-typing, we can't do much more (obviously, it works only if Driver is
         * the only MobileUser subclass with a "driverDetails" attribute)
         */
        return maybeDriver instanceof ConnectableUser && !!(maybeDriver as Driver).driverDetails;
    }

    static assign(unassignedDriver: any): Driver
    {
        /**
         * We call the "super" method to get a ConnectableUser
         */
        const connectableUser = super.assign(unassignedDriver);
        const driver = Object.assign(new Driver(), connectableUser);

        /**
         * Now, we handle the attributes specific to a Driver
         */
        driver.status = $enum(DriverStatus).getValueOrDefault(driver.status);
        driver.accountLimit = $enum(DriverAccountLimit).getValueOrDefault(driver.accountLimit);
        driver.driverDetails = DriverDetails.assign(driver.driverDetails);

        const loc = driver.location;
        const country = driver.country;
        driver.location = !!loc ? new MSLocation(loc.latitude, loc.longitude, loc.locationDate) : null;
        driver.country = !!country ? Country.assign(country) : null;

        return driver;
    }

    // endregion
}
