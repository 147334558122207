import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DocumentState } from '../../../models/constants/document-state';
import { Select, Store } from '@ngxs/store';
import { UpdateDriverDocumentState } from '../../actions/driver-documents.action';
import { DriverDocumentsUtils } from '../../driver-documents.utils';
import { Constants } from '../../../models/constants/constants';
import { DriverDocumentState } from '../../states/driver-document.state';
import { Driver, GenericState } from 'mys-base';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MysamTranslateService } from 'msl-translate';
import { DriverDocument } from '../../../models/driver-document';
import { NewBankInformationComponent } from '../../../bank-information/components/new-bank-information/new-bank-information.component';

@Component({
    selector: 'mys-driver-document-image[driver][document]',
    templateUrl: 'driver-document-image.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverDocumentImageComponent extends DriverDocumentsUtils implements AfterViewInit, OnDestroy
{
    // region Selectors

    /**
     * The following are used to track if the document is currently being updated, and which button was clicked
     */
    @Select(GenericState.loadingSelector(DriverDocumentState)) documentUpdating$: Observable<boolean>;

    @Select(DriverDocumentState.driverDocumentUpdated) documentUpdated$: Observable<boolean>;
    @Select(GenericState.errorSelector(DriverDocumentState)) errorWhileUpdatingDocument$: Observable<boolean>;

    // endregion

    // region Inputs

    @Input() driver: Driver;
    @Input() document: DriverDocument;

    // endregion

    // region ViewChild

    /**
     * Initialized only if "isBankInfo" is true
     */
    @ViewChild('newBankInfoComponent') newBankInfoComponent: NewBankInformationComponent | null;

    // endregion

    // region Attributes

    Constants = Constants;
    private validatedLoading = false;
    private refusedLoading = false;

    private subscription = new Subscription();

    // endregion

    // region Constructor

    constructor(store: Store, public dialogRef: MatDialogRef<DriverDocumentImageComponent>,
                private toastr: ToastrService, private translate: MysamTranslateService)
    {
        super(store);
    }

    // endregion

    initSubscriptions()
    {
        /**
         * Listening for the "success" event when updating the document states
         */
        this.subscription.add(
            this.documentUpdated$.pipe(
                filter(updated => updated),
                tap(_updated => this.dialogRef.close())
            ).subscribe()
        );

        /**
         * Listening for the "errors" event when updating the document states
         */
        this.subscription.add(
            this.errorWhileUpdatingDocument$.pipe(
                filter(error => !!error),
                tap(() => {
                    this.dialogRef.close();
                    this.toastr.error(this.translate.instant('errors.driver-documents.DOCUMENT-STATE-UPDATE-FAILED'));
                })
            ).subscribe()
        );

        /**
         * When the "documentUpdating$" switches to false, we reset our loading flags
         */
        this.subscription.add(
            this.documentUpdating$.pipe(
                filter(updating => !updating), // Not updating anymore
                tap(() => this.resetLoadingFlags())
            ).subscribe()
        )
    }

    /**
     * Reset the "validatedLoading" and "refusedLoading" to their initial states
     */
    resetLoadingFlags()
    {
        this.validatedLoading = false;
        this.refusedLoading = false;
    }

    // region Update Document

    /**
     * For the most documents, validates the DriverDocument.
     * For bank documents, submits the "newBankInfoComponent" instead (which will trigger a validation if the
     * submission succeeds)
     */
    validateDriverDocument(): void
    {
        this.validatedLoading = true;
        this.refusedLoading = false;

        /**
         * UPDATE 09/11/2020
         * https://mysamcab.atlassian.net/browse/MYS-4534
         * If the current document is a bank information, we don't want to trigger the validation right now
         * We want to send the bank information to the server first, and if they are valid, only then we will
         * validate the document
         */
        if (!!this.newBankInfoComponent)
        {
            this.validatedLoading = this.newBankInfoComponent.submit();
        }
        else
        {
            this.doValidateDriverDocument();
        }
    }

    /**
     * Calls the server to validate the current DriverDocument
     */
    doValidateDriverDocument(): void
    {
        this.store.dispatch(new UpdateDriverDocumentState(this.document.id, DocumentState.VALIDATED));
    }

    /**
     * Refuse the DriverDocument
     */
    refuseDriverDocument(): void
    {
        this.store.dispatch(new UpdateDriverDocumentState(this.document.id, DocumentState.REFUSED));
        this.validatedLoading = false;
        this.refusedLoading = true;
    }

    isValidating(): boolean
    {
        return this.validatedLoading;
    }

    isRefusing(): boolean
    {
        return this.refusedLoading;
    }

    // endregion

    // region Lifecycle

    ngAfterViewInit(): void
    {
        this.initSubscriptions();
    }

    ngOnDestroy(): void
    {
        this.subscription.unsubscribe();
    }

    // endregion
}
