export const REQUEST_NEW_PASSWORD = '[ForgotPassword] Request New Password';

export class RequestNewPasswordAction
{
    static readonly type = REQUEST_NEW_PASSWORD;

    constructor(public email: string)
    {
    }
}
