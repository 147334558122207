/**
 * Created by Adrien Dos Reis on 17/12/2018
 */
import { Action } from '@ngrx/store';

export const RESET_CSV = '[Alfred] Reset CSV';

export class ResetCsv implements Action
{
    readonly type = RESET_CSV;

    constructor()
    {
    }
}
