import { Store } from '@ngxs/store';
import { filter, map, race } from 'rxjs/operators';
import { Go } from '../../app/store';
import { Observable } from 'rxjs';


/**
 * Created by Adrien Dos Reis on 05/02/2019
 * This class contains static methods useful in all guards
 */
export class UtilGuardNgxs
{

    /**
     * Loads an object, then analyses if the loading was successful (successSelector was activated) or failed (failureSelector was
     * activated). Then, in order to know which Selector was triggered, we use the "successType" to know if the result is
     * "instanceof successType", and in this case, we consider that the redirection is authorized. Otherwise, we redirect to a 404
     * @param store The states containing both selectors
     * @param loadAction The Load Action to trigger
     * @param successSelector The success selector
     * @param failureSelector The failure selector
     * @param successType The type of the success selector
     */
    static loadThenRedirect<T>(store: Store, loadAction: () => any, successSelector: Observable<any>,
                               failureSelector: Observable<any>, successType: any)
    {
        store.dispatch(loadAction());

        /**
         * Now, we wait to have either a content of "successType" (success) or an Error message (failure).
         */
        return successSelector.pipe(
            filter(success => !!success),
            race(failureSelector.pipe(filter(error => !!error))),
            map((result: any) =>
            {
                /**
                 * If we have a content, we can let the user access the Component protected by this guard
                 */
                if (result instanceof successType)
                {
                    return true;
                }
                else // No content found, we redirect to a 404
                {
                    store.dispatch(new Go({ path: [ '/404' ] }));
                    return false;
                }
            })
        );
    }
}
