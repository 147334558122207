import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { effects, reducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { alfredGuards } from './guards';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorsModule } from './errors/errors.module';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from '../environments/environment';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { MySamBaseModule } from 'mys-base';
import { MslClientRegisterModule } from 'msl-register';
import { MslAddressesModule } from 'msl-addresses';
import { AlfredUserSourceGenerator } from '../../../msl-driver-registration/src/lib/authentication/generator/alfred-user-source-generator';

@NgModule({
    imports: [
        MySamBaseModule.forRoot(environment.BASE_URL),
        MslClientRegisterModule.forRoot(environment.BASE_URL + '/alfred/client-edition/create', AlfredUserSourceGenerator),
        MslAddressesModule.forRoot(environment.BASE_URL + '/ws/addresses'),

        /**
         * Lazily loaded in app/routes/routes.ts
         */
        ErrorsModule,
        BrowserAnimationsModule, // Animations for Material Angular
        StoreModule.forFeature('alfred', reducers),

        // TODO Once NGRX has been removed, implement this https://ngxs.gitbook.io/ngxs/advanced/meta-reducer
        NgxsModule.forRoot([], { developmentMode: !environment.production }),
        NgxsReduxDevtoolsPluginModule.forRoot({ maxAge: 25, disabled: environment.production }),
        NgxsRouterPluginModule.forRoot(),
        EffectsModule.forFeature(effects)
    ],
    providers: [ ...alfredGuards ]
})
export class AlfredModule
{
}
