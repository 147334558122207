import { ApiService, ApiError, Client } from 'mys-base';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ClientReferrerUpdateError } from '../../errors/client-referrer-update.error';
import { HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

// TODO : Move this file when we will refactor this (cf. trips)
@Injectable()
export class ClientReferrerUpdateService extends ApiService
{
    private readonly BASE_CLIENT_URL = environment.BASE_URL + '/alfred/clients';
    private readonly UPDATE_REFERRER_URL = '/update-referrer/';

    public clientReferrerUpdate(clientId: string, referrerId: string): Observable<Client>
    {
        return this.httpClient.put<Client>(this.BASE_CLIENT_URL + '/' + clientId + this.UPDATE_REFERRER_URL + referrerId, null).pipe(
            // Enforcing the return type here
            map((client: any) => Client.assign(client))
        );
    }

    // region ApiError handle

    public createApiError(err: HttpErrorResponse): ApiError
    {
        return new ClientReferrerUpdateError(super.createApiError(err));
    }

    // endregion
}
