import { Injectable } from '@angular/core';
import { CurrentUserUtils } from '../../../../../msl-login/src/lib/utils/current-user-utils';
import * as ngxs from '@ngxs/store';
import { SpecificRoleGuard } from './specific-role.guard';
import { SpringAuthority } from 'mys-base';

/**
 * Updated on 14/08/2019
 * This Guard ensures that the current user is loaded, and that he is a Paying Driver (as opposed to CurrentUserLoadedAndPayingGuard, which
 * allows the user to be either a Paying Driver OR an AlfredUser). This Guard does not allow AlfredUsers through. Instead, it redirects
 * them to the /trips/list page
 */
@Injectable({ providedIn: 'root' })
export class LibertyDriverPayingDriverGuard extends SpecificRoleGuard
{
    constructor(ngxsStore: ngxs.Store)
    {
        super(ngxsStore);
    }

    getPredicateWithRoles(roles: SpringAuthority[]): boolean
    {
        return CurrentUserUtils.isRolePayingDriver(roles);
    }

    getUrlToRedirect(): string
    {
        return '/trips/list';
    }
}
