import { Component, Input } from '@angular/core';
import { UserDetailsPipe } from '../../../pipes/user-details.pipe';
import { MobileUser } from 'mys-base';
import { UserPipe } from '../../../pipes/user.pipe';
import { AbstractAutocompleteComponent } from '../../../utils/components/abstract-autocomplete/abstract-autocomplete.component';

@Component({
    selector: 'mys-user-autocomplete',
    templateUrl: '../../../utils/components/abstract-autocomplete/abstract-autocomplete.component.html'
})
export class UserAutocompleteComponent extends AbstractAutocompleteComponent<MobileUser>
{
    // region Inputs

    @Input() label = '';
    @Input() values: any[] = [];
    @Input() minCharsBeforeSearching = 4;

    // endregion

    // region Constructor

    constructor(private userDetailsPipe: UserDetailsPipe, public userPipe: UserPipe)
    {
        super();
    }

    // endregion

    /**
     * This method is passed to the AutocompleteComponent to tell it how to display an user in the Option field
     *
     * @param {User | string} user
     * @returns {string}
     */
    displayInOption(user: MobileUser | string): string
    {
        return this.userDetailsPipe.transform(user as MobileUser);
    }

    /**
     * This method is passed to the AutocompleteComponent to tell it how to display an user in the Input field
     *
     * @param {User | string} user
     * @returns {string}
     */
    displayInInput(user: MobileUser | string): string
    {
        return this.userPipe.transform(user as MobileUser);
    }
}
