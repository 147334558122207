import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MysamTranslateService } from 'msl-translate';
import {
    AccessRightsService
} from '../../../../../msl-driver-registration/src/lib/authentication/access-rights/access-rights.service';
import { Subscription } from 'rxjs';
import { IconComponent } from '../icon.component';

/**
 * This component behaves the same way as DetailItemComponent for static content,
 * but it uses image files instead of Bootstrap ionic logos for the rendering
 */
@Component({
    selector: 'mys-detail-item-with-img',
    templateUrl: './detail-item-with-img.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailItemWithImgComponent extends IconComponent implements OnInit, OnDestroy
{
    // region Component Inputs / Outputs

    @Input() contentKey: string;
    @Input() content: string;
    @Input() titleKey: string;

    // endregion

    // region Attributes

    private subscription: Subscription = new Subscription();

    // endregion

    // region Constructor

    constructor(_translate: MysamTranslateService, public accessRights: AccessRightsService)
    {
        super(_translate);
    }

    // endregion

    // region Angular lifecycle

    ngOnInit(): void
    {
        if (!!this.titleKey)
        {
            this.title$ = super.setTitleTranslationAsync(this.titleKey);
        }

        if (!!this.contentKey)
        {
            this.subscription.add(this.translate.get(this.contentKey).subscribe(content =>
            {
                this.content = content;
            }));
        }
    }

    ngOnDestroy(): void
    {
        this.subscription.unsubscribe();
    }

    // endregion
}
