/**
 * Created by Aurélien Rieu on 06/06/2019
 */
export const UPDATE_DRIVER_WORKING_AREAS = '[WorkingAreas] Update Driver WorkingAreas';

/**
 * Load UpdateDriverWorkingAreas
 */
export class UpdateDriverWorkingAreas
{
    static readonly type = UPDATE_DRIVER_WORKING_AREAS;

    constructor(public driverId: string, public administrativeAreaIds: number[])
    {
    }
}
