import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "../components/components.module";
import { MysamTranslateModule } from "msl-translate";
import { PipesModule } from "mys-base";
import { TripOperatorComponent } from "./components/trip-operator.component";
import { FloatingActionButtonModule } from "../utils/modules/floating-action-button/floating-action-button.module";
import { MslCenteredSpinnerModule } from "msl-components";
import { NgxsModule } from '@ngxs/store';
import { TripOperatorBindingState } from '../trip-operator-binding/states/trip-operator-binding.state';
import { TripOperatorState } from "./state/trip-operator.state";

const exportedComponents = [TripOperatorComponent];

const libraries = [ FloatingActionButtonModule, MysamTranslateModule, MslCenteredSpinnerModule];
const ngxsStates = [ TripOperatorState, TripOperatorBindingState ];

@NgModule({
    declarations: [...exportedComponents],
    imports: [
        CommonModule,
        ComponentsModule,
        ...libraries,
        NgxsModule.forFeature([...ngxsStates]),
        PipesModule
    ],
    exports: [...exportedComponents],

    /**
     * See https://stackoverflow.com/a/63881579 - Option 2
     * Needed to avoid Angular complaining that 'ion-icon' is not a known element.
     */
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TripOperatorModule
{
}
