import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { LoginAction } from 'msl-login';
import { ConnectableUser, GenericState, UserSource } from 'mys-base';
import { ActivatedRoute } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';
import { MysamTranslateService } from 'msl-translate';
import { AlfredLoginState } from '../../state/alfred-login.state';
import { Title } from "@angular/platform-browser";
import { USER_SOURCE } from "../../index";

@Component({
    selector: 'mys-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnDestroy
{
    // region Selectors

    @Select(AlfredLoginState.user) currentUser$: Observable<ConnectableUser>;
    @Select(GenericState.loadingSelector(AlfredLoginState)) loginLoading$: Observable<boolean>;
    @Select(GenericState.errorSelector(AlfredLoginState)) error$: Observable<string>;

    // endregion

    // region Controls

    emailCtrl: FormControl<string>; // Will be initialized as soon as EmailFieldComponent is initialized
    passwordCtrl = new FormControl<string>(null, Validators.required);

    // endregion

    // region Attributes

    /**
     * Will be switched to "true" when the user clicks the "Driver Signup" button
     */
    driverSignupClicked = false;

    /**
     * Indicates whether this login form is destined to the drivers (on Liberty Driver or on Register-LM), or to
     * Alfred users
     */
    isDriver: boolean;

    private subscription = new Subscription();

    // endregion

    constructor(private store: Store, private route: ActivatedRoute, private title: Title,
                @Inject(USER_SOURCE) public source: UserSource, translate: MysamTranslateService)
    {
        this.subscription.add(translate.get('login-form.ALFRED-LOGIN-TITLE')
            .subscribe(title => this.title.setTitle(title)));

        this.isDriver = source == UserSource.LIBERTY_DRIVER || source == UserSource.LIBERTY_MOBILE;
    }

    isFormValid(): boolean
    {
        return this.emailCtrl.valid && this.passwordCtrl.valid;
    }

    // region UI interaction

    navigateToDriverSignup()
    {
        this.store.dispatch(new Navigate(['/driver/signup']));
        this.driverSignupClicked = true;
    }

    startLoginProcess()
    {
        /**
         * And we send the actual request here
         */
        this.store.dispatch(new LoginAction({ email: this.emailCtrl.value, password: this.passwordCtrl.value }));
    }

    // endregion

    /**
     * Sets the given "control" as our "emailCtrl", and pre-fills it with the given "email" route parameter, if given
     * @param formControl
     */
    initializeEmailCtrl(formControl: FormControl<string>)
    {
        this.emailCtrl = formControl;

        /**
         * From the official doc :
         * The Router manages the observables it provides and localizes the subscriptions...
         * so we don't need to unsubscribe from the route params Observable.
         */
        this.route.queryParams.pipe(
            filter((queryParams: { email: string | null }) => !!queryParams.email),
            tap((queryParams: { email: string | null }) => this.emailCtrl.setValue(queryParams.email))
        ).subscribe();
    }

    // region Lifecycle

    ngOnDestroy()
    {
        this.subscription.unsubscribe();
    }

    // endregion
}
