<ld-layout [outerContainerClass]="'page-container'" [innerContainerClass]="'container-xs'" [source]="source"
           [textBelowLogo]="'login-form.LOGIN-WITH-MYSAM-CREDENTIALS'| mysamTranslate">
    <form novalidate class="card b0 form-validate">

        <!--
            Pierre is used to use this green check to log in, we keep it for Alfred
            For the Driver Web app, we want to hide it
        -->
        <div class="card-offset pb0" *ngIf="!isDriver">
            <div class="card-offset-item d-flex justify-content-end">
                <button (click)="startLoginProcess()" [disabled]="!isFormValid()"
                        class="btn btn-success btn-circle">
                    <ion-icon name="checkmark" class="ion3x"></ion-icon>
                </button>
            </div>
        </div>

        <mat-card class="no-bottom-padding">
            <mat-card-title class="text-center">
                <span *ngIf="!isDriver">{{ 'login-form.ALFRED-LOGIN-TITLE' | mysamTranslate }}</span>
                <span *ngIf="isDriver">{{ 'login-form.DRIVER-LOGIN-TITLE' | mysamTranslate }}</span>
            </mat-card-title>
            <mat-card-content>
                <div id="fields">
                    <!-- Email field -->
                    <mys-email-field (emailCtrl)="initializeEmailCtrl($event)"></mys-email-field>

                    <!-- Password field -->
                    <mat-form-field>
                        <span matPrefix>
                            <ion-icon name="lock-closed-outline"></ion-icon> &nbsp;
                        </span>

                        <!--
                                https://mysamcab.atlassian.net/browse/MYS-6222
                                data-lpignore="false" added in order to allow LastPass to fill this input
                        -->
                        <input matInput type="password" [formControl]="passwordCtrl" data-lpignore="false"
                               [placeholder]="'login-form.PASSWORD' | mysamTranslate"/>

                        <!-- Password errors messages -->
                        <mat-error *ngIf="passwordCtrl.hasError('required')
                            && (passwordCtrl.dirty || passwordCtrl.touched)">
                            {{ 'login-form.errors.PASSWORD-REQUIRED' | mysamTranslate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Global errors message -->
                <div class="text-danger text-center" *ngIf="error$ | async">{{ error$ | async }}</div>

                <!--
                        Forgotten password ?
                        Only on Liberty Driver for now
                -->
                <div *ngIf="isDriver" class="text-center my-4">
                    <!--
                            In order to display this text as a "link", we add the bootstrap class "btn-link"
                    -->
                    <i class="btn-link" (click)="forgotPassword.display()">
                        {{ 'login-form.forgot-password.TITLE' | mysamTranslate }}
                    </i>
                    <mys-forgot-password #forgotPassword [defaultEmail]="emailCtrl.value"></mys-forgot-password>
                </div>

                <!--
                    We want to display the loader in the following cases :
                    - The "oauth/token" call is pending
                    - The current user is loaded, but the next page is loading

                    In all other cases (no pending network call and no current user loaded), we want to hide
                    the loader.
                -->
                <msl-primary-button [disabled]="!isFormValid()" [class]="'w-100 py-4'" [hostClass]="'w-100'"
                                    [displayLoader]="(loginLoading$ | async) || !!(currentUser$ | async)"
                                    (click)="startLoginProcess()">
                    {{ 'login-form.SUBMIT' | mysamTranslate | uppercase }}
                </msl-primary-button>
            </mat-card-content>
        </mat-card>
    </form>

    <!-- If we are on Liberty Driver, we want to display a button to let the Driver register on MySam -->
    <mat-card *ngIf="isDriver" class="p-0 card">
        <mat-card-title class="text-center py-3"
                        [innerHTML]="'login-form.no-account.NOT-REGISTERED-YET-HTML' | mysamTranslate">
        </mat-card-title>
        <mat-card-content>
            <msl-primary-button (click)="navigateToDriverSignup()" [class]="'w-100 py-4'" [hostClass]="'w-100'"
                                [displayLoader]="driverSignupClicked">
                {{ 'login-form.no-account.REGISTER-NOW' | mysamTranslate | uppercase }}
            </msl-primary-button>
        </mat-card-content>
    </mat-card>
</ld-layout>
