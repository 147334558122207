import { Trip } from '../../../../../mys-base/src/lib/models/trip';
import { Action } from '@ngrx/store';

// Start Trip
export const START_TRIP = '[Alfred] Start Trip';
export const START_TRIP_FAIL = '[Alfred] Start Trip Fail';
export const START_TRIP_SUCCESS = '[Alfred] Start Trip Success';

export class StartTrip implements Action
{
    readonly type = START_TRIP;

    constructor(public payload: number)
    {
    }
}

export class StartTripFail implements Action
{
    readonly type = START_TRIP_FAIL;

    constructor(public payload: any)
    {
    }
}

export class StartTripSuccess implements Action
{
    readonly type = START_TRIP_SUCCESS;

    constructor(public payload: Trip)
    {
    }
}

// Action types
export type StartTripAction = StartTrip | StartTripFail | StartTripSuccess;
