<mat-form-field>
    <span matPrefix>
        <ion-icon name="mail-outline"></ion-icon> &nbsp;
    </span>
    <!--
            https://mysamcab.atlassian.net/browse/MYS-6222
            data-lpignore="false" added in order to allow LastPass to fill this input
    -->
    <input matInput type="text" [formControl]="emailInput" data-lpignore="false"
           [placeholder]="placeholderKey | mysamTranslate"/>

    <!-- Email errors fields -->
    <mat-error *ngIf="emailInput.hasError('required') && (emailInput.dirty || emailInput.touched)">
        {{ 'login-form.errors.EMAIL-REQUIRED' | mysamTranslate }}
    </mat-error>

    <mat-error *ngIf="emailInput.hasError('email') && (emailInput.dirty || emailInput.touched)">
        {{ 'login-form.errors.INVALID-EMAIL' | mysamTranslate }}
    </mat-error>
</mat-form-field>
