import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MobileUser } from 'mys-base';
import * as fromActions from '../actions/users.action';

export interface UsersState extends EntityState<MobileUser>
{
    loaded: boolean;
    loading: boolean;
    error: any;
}

const usersAdapter = createEntityAdapter<MobileUser>({ selectId: (user: MobileUser) => user.userId });
const initialState: UsersState = usersAdapter.getInitialState({
    loaded: false,
    loading: false,
    error: null
});

export function reducer(state: UsersState = initialState, action: fromActions.UsersAction): UsersState
{
    switch (action.type)
    {
        /**
         * Loading actions
         */
        case fromActions.SEARCH_USERS:
        {
            return {
                ...state,
                loading: true
            };
        }

        case fromActions.SEARCH_USERS_SUCCESS:
        {
            const newUsersList = action.payload;

            /**
             * We remove the Users already stored in-memory, in order to fully refresh the list
             */
            const tempState = usersAdapter.removeAll(state);
            return usersAdapter.addMany(newUsersList, { ...tempState, loaded: true, loading: false, error: null });
        }

        case fromActions.SEARCH_USERS_FAIL:
        {
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            };
        }
    }

    return state;
}

export const {
    // select the array of Client ids
    selectIds: selectUsersIds,

    // select the dictionary of Client entities
    selectEntities: selectUsersEntities,

    // select the array of Clients
    selectAll: selectAllUsers,

    // select the total Clients count
    selectTotal: selectUsersTotal
} = usersAdapter.getSelectors();

export const getLoadedFromUsersReducer = (state: UsersState) => state.loaded;
export const getLoadingFromUsersReducer = (state: UsersState) => state.loading;
export const getErrorFromUsersReducer = (state: UsersState) => state.error;
