import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../app/shared/shared.module';
import {PipesModule} from '../pipes/pipes.module';
import {ModalImageThumbnailComponent} from './modal-image/thumbnail/modal-image-thumbnail.component';
import {IconComponent} from './icon.component';
import {PaymentMethodComponent} from './payment-method.component';
import {DisabilityLevelComponent} from './disability-level.component';
import {DetailItemAddressAutocompleteComponent} from "./detail-item-address-autocomplete/detail-item-address-autocomplete.component";
import {DetailItemComponent} from './detail-item/detail-item.component';
import {DetailItemCompanyComponent} from './detail-item-company/detail-item-company.component';
import {DetailItemWithImgComponent} from './detail-item-with-img/detail-item-with-img.component';
import {ColumnFilterComponent} from './datatable/column-filter/column-filter.component';
import {ColumnSorterComponent} from './datatable/column-sorter/column-sorter.component';
import {DetailMenuComponent} from './detail-menu/detail-menu.component';
import {MslAutocompleteWrapperModule, MslButtonModule} from 'msl-components';
import {UserAutocompleteModule} from './user-autocomplete/user-autocomplete.module';
import {SelectCompanyModule} from '../../../../msl-driver-registration/src/lib/select-company/select-company.module';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MslAddressesModule} from "msl-addresses";
import {ZoneSurchargeComponent} from './zone-surcharge/zone-surcharge.component';


const components: any[] = [ IconComponent, PaymentMethodComponent, DisabilityLevelComponent, DetailItemAddressAutocompleteComponent,
    DetailItemComponent, DetailItemCompanyComponent, DetailItemWithImgComponent,
    ColumnFilterComponent, ColumnSorterComponent, DetailMenuComponent, ModalImageThumbnailComponent, ZoneSurchargeComponent
];

const libraryDependencies = [MslAddressesModule, MslAutocompleteWrapperModule, MslButtonModule, SelectCompanyModule, UserAutocompleteModule ];

// For the components using Material Dependencies, we need to add the corresponding Material Modules here
const materialDependencies = [ MatAutocompleteModule, MatCheckboxModule, MatDividerModule, MatListModule,
    MatFormFieldModule, MatIconModule, MatInputModule, MatMenuModule, MatOptionModule, MatSelectModule, MatDialogModule, MatPaginatorModule,
    MatTooltipModule ];

@NgModule({
    declarations: [...components],
    imports: [CommonModule, SharedModule, PipesModule, ...materialDependencies, ...libraryDependencies],
    exports: [...components, ...materialDependencies, ...libraryDependencies],
    /**
     * See https://stackoverflow.com/a/63881579 - Option 2
     * Needed to avoid Angular complaining that 'ion-icon' is not a known element.
     */
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule
{
}
