import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MySamPair } from 'mys-base';

@Component({
    selector: 'mys-datatable-column-filter',
    templateUrl: './column-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnFilterComponent implements OnInit, OnDestroy
{
    // region Attributes

    @Input() type: string;
    @Input() filterKey: string;

    @Output() search: EventEmitter<MySamPair<string, string>> = new EventEmitter<MySamPair<string, string>>();

    private subscription: Subscription = new Subscription();
    private searchCriteria = new Subject<string>();

    // Build a two-way data binding, in order to clear the value of our input when we will call resetValue()
    // If we don't do this way, the resetValue() do not work
    inputValue = '';
    @Output() inputChange = new EventEmitter<string>();

    @Input()
    get input()
    {
        return this.inputValue;
    }

    set input(val)
    {
        this.inputValue = val;
        this.inputChange.emit(this.inputValue);
    }

    // endregion

    // region Component lifecycle

    public ngOnInit(): void
    {
        this.subscription.add(
            this.searchCriteria.pipe(
                // wait 300ms after each keystroke before considering the term
                debounceTime(300),

                // ignore new term if same as previous term
                distinctUntilChanged(),

                /**
                 * UPDATE 27/02/2019
                 * https://mysamcab.atlassian.net/browse/MYS-3245
                 * We now allow empty terms, since "ColumnFilterComponent#resetValue()" can now control if the resetting should
                 * trigger a new search or not. This is not the role of this pipe anymore
                 */

                // /**
                //  * If the term is empty, we want to bypass the "distinctUntilChanged", but we don't want to trigger a search
                //  * (since it will be done in a higher-level, in AbstractDatatableWithCsv#resetUI for example)
                //  *
                //  * If we do this before the "distinctUntilChanged", it will behave poorly when the client enters a term,
                //  * clears the filter, then re-enters the same term (it won't be considered as "distinctUntilChanged", see
                //  * https://mysamcab.atlassian.net/browse/MYS-3162)
                //  */
                // filter(term => !!term),

                // emit the term value each time it changes
                tap((term: string) =>
                {
                    this.search.emit(new MySamPair<string, string>(term, this.filterKey));
                })
            ).subscribe());
    }

    ngOnDestroy(): void
    {
        this.subscription.unsubscribe();
    }

    // endregion

    // region Utility methods

    /**
     * Resets the value of the input field
     *
     * @param shouldTriggerNewSearch True if resetting the input should trigger a refresh of the data list, false otherwise.
     * We set it to a "false" default value, since we usually use this method to clear all filters at once (and we don't want to
     * trigger X searches while resetting X fields)
     */
    public resetValue(shouldTriggerNewSearch: boolean = false)
    {
        this.input = '';

        /**
         * And now, if we want to trigger a new search, we signal to our actual ColumnFilterComponent that our filters changed
         */
        if (shouldTriggerNewSearch)
        {
            this.doSearch('');
        }
    }

    /**
     * Called from the searchCriteria box whenever the user types some data in the input field
     * @param event
     */
    onTextEntered(event)
    {
        this.doSearch(event.target.value);
    }

    /**
     * Triggers a search if the given term is not empty and is distinct from the last search
     * @param term
     */
    doSearch(term: string)
    {
        this.searchCriteria.next(term.toLowerCase());
    }

    // endregion
}
