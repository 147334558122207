import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError, ApiService } from 'mys-base';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Driver } from '../../../../../../../mys-base/src/lib/models/users/driver';
import { SearchDriversAutocompleteError } from '../errors/search-driver.error';
import { map } from 'rxjs/operators';
import { DriverEligibilityFilter } from '../requests/driver-eligibility-filter';

@Injectable({ providedIn: 'root' })
export class SearchDriverService extends ApiService
{
    private readonly BASE_SEARCH_DRIVER_URL = environment.BASE_URL + '/alfred/search-drivers/';
    private readonly SEARCH_ELIGIBLE_DRIVERS_URL = this.BASE_SEARCH_DRIVER_URL + 'eligible/';

    /**
     * Gets the drivers matching the term, and filters them through the given "filter", if any
     * @param term
     * @param filter
     */
    public searchDriversByTermWithEligibilityIfGiven(term: string, filter?: DriverEligibilityFilter | null): Observable<Driver[]>
    {
        if (!!filter)
        {
            return this.searchEligibleDriversByTerm(term, filter);
        }
        else
        {
            return this.searchDriversByTerm(term);
        }
    }

    /**
     * Gets the eligible drivers matching the term
     * @param {string} term
     * @param filter Holds the eligibility filters that might have been given
     * @return {Observable<Driver[]>}
     */
    private searchEligibleDriversByTerm(term: string, filter: DriverEligibilityFilter): Observable<Driver[]>
    {
        /**
         * Executing the request here
         */
        return this.httpClient.put<Driver[]>(`${this.SEARCH_ELIGIBLE_DRIVERS_URL}${term}`, filter).pipe(
            // Enforcing the return type here
            map((drivers: any[]) => this.enforceListType(drivers))
        );
    }

    /**
     * Gets drivers matching the term
     * @param {string} term
     * @return {Observable<Driver[]>}
     */
    private searchDriversByTerm(term: string): Observable<Driver[]>
    {
        /**
         * Executing the request here
         */
        return this.httpClient.get<Driver[]>(`${this.BASE_SEARCH_DRIVER_URL}${term}`).pipe(
            // Enforcing the return type here
            map((drivers: any[]) => this.enforceListType(drivers))
        );
    }

    /**
     * Casts each element of the given array into a Driver
     * @param array
     */
    private enforceListType(array: any[]): Driver[]
    {
        return array.map(driver => Driver.assign(driver));
    }

    public createApiError(err: HttpErrorResponse): ApiError
    {
        return new SearchDriversAutocompleteError(super.createApiError(err));
    }
}
