import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { MysamTranslateService } from 'msl-translate';

@Component({
    selector: 'mys-detail-item',
    templateUrl: './detail-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailItemComponent implements OnInit, OnDestroy
{
    // region Component Inputs

    /**
     * The proper usage is by giving either an "ionIcon" (which will be picked from https://ionicons.com)
     * OR a "materialIcon" (from https://fonts.google.com/icons)
     *
     * Using both would work, but that's not intended
     */
    @Input() ionIcon: string;
    @Input() materialIcon: string;
    @Input() materialIconClass: string = 'material-icons material-symbols-outlined';
    @Input() iconColorClass = 'pink';
    @Input() titleKey: string;
    @Input() title: string;
    @Input() isEditable = false;
    @Input() withVerticalMargin = true; // false to save some space by removing the top margin on the image

    // endregion

    // region Input text FormGroup

    currentInput: UntypedFormControl;

    // endregion

    /**
     * If the validatorFn is defined, then we try to run it. If it is not valid, we mark the field as erroneous
     */
    @Input() validatorFn: () => boolean;

    // region Editing handling

    /**
     * Added on 02/10/2019
     * If defined, will be executed on "editing".
     * Be careful ! This attribute cannot be used with others like "textWhileEditing" and "contentWhileEditingChange"
     * (because defining a "executeWhenEditing" would take precedence over the display of "textWhileEditing")
     */
    @Input() executeWhenEditing: () => void = null;

    /**
     * If set, this string will be displayed in edition mode, in a <matInput>
     * In this case, "displayInReadMode" should be null
     */
    @Input() textWhileEditing: string = null;
    @Output() contentWhileEditingChange: EventEmitter<string> = new EventEmitter<string>();

    isEditing = false;

    // endregion

    private subscription: Subscription = new Subscription();

    // region UI Actions

    public switchEditMode(): void
    {
        /**
         * UPDATE 02/10/2019
         * If "executeWhenEditing" is defined, we execute it.
         * Otherwise, we just switch the "isEditing" states
         */
        if (!!this.executeWhenEditing)
        {
            this.executeWhenEditing();
        }
        else
        {
            this.isEditing = !this.isEditing;
        }
    }

    validateValue(): void
    {
        /**
         * If "validatorFn" is undefined, or if it did not return any result (no errors message), we can emit the given
         * value and go back to non-edition mode
         */
        if (!this.validatorFn || !this.validatorFn())
        {
            this.contentWhileEditingChange.emit(this.currentInput.value);
            this.switchEditMode();
        }
    }

    // endregion

    // region Lifecycle

    constructor(private translate: MysamTranslateService, private changeDetectorRef: ChangeDetectorRef)
    {
    }

    public ngOnInit(): void
    {
        if (!!this.titleKey)
        {
            this.subscription.add(this.translate.get(this.titleKey).subscribe(title =>
            {
                this.title = title;
                this.changeDetectorRef.detectChanges();
            }));
        }

        this.currentInput = new UntypedFormControl(this.textWhileEditing);
    }

    ngOnDestroy(): void
    {
        this.subscription.unsubscribe();
    }

    // endregion
}
