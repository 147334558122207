import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: 'mys-redirect-button',
    templateUrl: './redirect-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedirectButtonComponent
{
    // region Attributes

    @Input({ required: true }) textBtn: string;
    @Input({ required: true }) pathToRedirect: string;

    // Should we display the spinning loader inside the button ?
    shouldDisplayLoader = false;

    // endregion
    constructor(private store: Store)
    {
    }

    /**
     * Sets the value of "shouldDisplayLoader$" to true to display spinner on the button,
     * then redirect to the page
     */
    redirection()
    {
        this.shouldDisplayLoader = true;

        /**
         * We need to add a small timeout before triggering the redirection (10ms seems enough).
         * Otherwise, the navigation starts too fast and the spinner loader cannot be displayed (even though
         * "shouldDisplayLoader" is true)
         */
        setTimeout(() =>
        {
            this.store.dispatch(new Navigate([this.pathToRedirect]));
        }, 10);
    }
}
