import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { TripOperatorError } from '../../trips/errors/trip.operator.error';
import { Alert, ApiService, MySamPair, TripOperator } from 'mys-base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Created by Adrien Dos Reis on 10/03/2023
 *
 * Aggregates all common behavior for TripOperatorBindingService and AlertService
 */
export abstract class AbstractTripOperatorBindingAlertAssignationService extends ApiService
{
    /**
     * Util method to call an endpoint using the POST method, and getting a result as a <MySamPair<TripOperator | null, Alert[]>>
     */
    protected post(url: string): Observable<MySamPair<TripOperator | null, Alert[]>>
    {
        return this.httpClient.post<MySamPair<TripOperator | null, Alert[]>>(url, null)
            .pipe(
                map((pair: MySamPair<TripOperator | null, Alert[]>) => new MySamPair<TripOperator | null, Alert[]>(
                    Object.assign(new TripOperator(), pair.first),
                    pair.second.map(alert => Object.assign(new Alert(), alert))
                ))
            );
    }

    /**
     * Error builder for the services
     *
     * @param {HttpErrorResponse} err The errors to cast in TripOperatorError
     * @returns {TripOperatorError} An errors typed as TripOperatorError
     */
    public buildTripOperatorError(err: HttpErrorResponse): TripOperatorError
    {
        return new TripOperatorError(super.createApiError(err));
    }
}
