import { routerReducer, RouterReducerState, RouterStateSerializer } from '@ngrx/router-store';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { Injectable } from "@angular/core";

// Our representation of a route
export interface RouterStateUrl
{
    url: string;
    queryParams: Params;
    params: Params;
}

export interface State
{
    routerReducer: RouterReducerState<RouterStateUrl>;
}

export const routerReducers: ActionReducerMap<State> = {
    routerReducer: routerReducer
};

export const getRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('routerReducer');

@Injectable()
export class AlfredRouterSerializer implements RouterStateSerializer<RouterStateUrl>
{
    serialize(routerState: RouterStateSnapshot): RouterStateUrl
    {
        const { url } = routerState;
        const { queryParams } = routerState.root;

        let state: ActivatedRouteSnapshot = routerState.root;
        while (state.firstChild)
        {
            state = state.firstChild;
        }

        const { params } = state;

        return { url, queryParams, params };
    }

}
