import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts a timestamp to hours, or return 0 the timestamp is null
 */
@Pipe({
    name: 'timestampToHoursFloored'
})
export class TimestampToHoursFlooredPipe implements PipeTransform
{
    transform(timestamp: number): number
    {
        if (!!timestamp)
        {
            return Math.floor(timestamp / 1000 / 60 / 60);
        }

        return 0;
    }
}
