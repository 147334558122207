import { Component, Input } from '@angular/core';

@Component({
    selector: 'icon-notification',
    templateUrl: './icon-notification.component.html',
    styleUrls: ['./icon-notification.component.scss']
})
export class IconNotificationComponent
{

    // region attribute

    @Input() notificationIconToDisplay: string;
    @Input() nbAlerts: number | null; // null = no badge

    // endregion

}
