<mys-alerts [alerts]="(unassignedAlertsPag$ | async)?.content"></mys-alerts>

<!-- Handling the pagination here, by plugin our Page object to a <mat-paginator> -->
<mat-paginator #alertPaginator
               [hidden]="!(unassignedAlertsPag$ | async) || (unassignedAlertsPag$ | async)?.totalElements <=1"
               [length]="(unassignedAlertsPag$ | async)?.totalElements"
               [pageSize]="4"
               [pageIndex]="(unassignedAlertsPag$ | async)?.number"
               hidePageSize showFirstLastButtons
               (page)="getUnassignedAlerts($event.pageIndex, $event.pageSize)"></mat-paginator>
