import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadDriverMobileAppComponent } from './components/download-driver-mobile-app/download-driver-mobile-app.component';

const components = [ DownloadDriverMobileAppComponent ];

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [ ...components ],
    exports: [ ...components ]
})
export class DownloadMobileAppModule
{
}
