import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable ,  of } from 'rxjs';
import { AlfredState } from '../store/reducers';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadTripById } from '../trips/actions';
import { getCurrentTrip, getTripError } from '../store/selectors';
import { Trip } from '../../../../mys-base/src/lib/models/trip';
import { UtilGuard } from './util-guard';

@Injectable()
export class TripDetailGuard 
{
    constructor(private store: Store<AlfredState>)
    {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        // We need a "tripId" parameter
        const tripId: number = route.params[ 'tripId' ];

        return !!tripId && this.reloadTrip(tripId).pipe(
            switchMap((loadedSuccessfully: boolean) => of(loadedSuccessfully)),
            catchError(() => of(false))
        );
    }

    reloadTrip(tripId: number): Observable<boolean>
    {
        /**
         * Loading the given Trip here
         */
        return UtilGuard.loadThenRedirect(this.store, () => new LoadTripById(tripId), getCurrentTrip, getTripError, Trip);
    }
}
