import { Component, OnInit } from '@angular/core';
import { DriverAccessRightsService } from '../../../../../../../msl-driver-registration/src/lib/authentication/access-rights/driver-access-rights.service';

@Component({
  selector: 'mys-pending-approval-banner',
  templateUrl: './pending-approval-banner.component.html',
  styleUrls: ['./pending-approval-banner.component.scss']
})
export class PendingApprovalBannerComponent implements OnInit {

  constructor(public driverAccessRights: DriverAccessRightsService) { }

  ngOnInit() {
  }

}
