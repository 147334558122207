import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    CLIENT_REFERRER_UPDATE,
    ClientReferrerUpdate,
    ClientReferrerUpdateFail,
    ClientReferrerUpdateSuccess
} from '../../actions';
import { Client } from 'mys-base';
import { ClientReferrerUpdateService } from '../../../services';

@Injectable()
export class ClientReferrerUpdateEffect
{
    constructor(private actions$: Actions, private clientReferrerUpdateService: ClientReferrerUpdateService)
    {
    }

    clientReferrerUpdate$ = createEffect(() =>
        this.actions$.pipe(ofType<ClientReferrerUpdate>(CLIENT_REFERRER_UPDATE),
            switchMap((action: ClientReferrerUpdate) =>
                this.clientReferrerUpdateService.clientReferrerUpdate(action.clientId, action.referrerId).pipe(
                    map((client: Client) => new ClientReferrerUpdateSuccess(client)),
                    catchError((error: any) =>
                        of(new ClientReferrerUpdateFail(this.clientReferrerUpdateService.createApiError(error))))
                ))
        )
    );
}
