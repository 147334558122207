import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiError, ApiService } from 'mys-base';
import { ForgotPasswordError } from "../errors/forgot-password.error";
import { ENDPOINT_URL } from "../index";

@Injectable({ providedIn: 'root' })
export class ForgotPasswordService extends ApiService
{
  /**
   * @param httpClient the HttpClient used in this Service
   * @param endpointURL The URL that shall be given by the application using this module
   */
  constructor(httpClient: HttpClient, @Inject(ENDPOINT_URL) protected endpointURL: string)
  {
    super(httpClient, endpointURL);
  }

  /**
   * * Requests a new Password Token from the server (which should be send over email)
   * @param {string} email
   * @returns {Observable<void>} Observable allowing to track the success or failure of the login request
   */
    public requestNewPasswordToken(email: string): Observable<void>
    {
      return this.httpClient.post<void>(this.endpointURL + '/public/passwords/forgot', email);
    }

    public createApiError(err: HttpErrorResponse): ApiError
    {
        return new ForgotPasswordError(super.createApiError(err));
    }
}
