import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AlfredUser, GenericState } from 'mys-base';
import { Select, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { AuthenticationService, LogoutAction } from 'msl-login';
import { of } from 'rxjs/internal/observable/of';
import { AlfredLoginState } from '../../login/state/alfred-login.state';

/**
 * Updated on 14/08/2019
 * This Guard ensures that the current user is loaded, but doesn't ensure that he is either a "free" or "paying" user
 */
@Injectable({ providedIn: 'root' })
export class CurrentUserLoadedGuard 
{
    // region Selectors

    @Select(AlfredLoginState.user) currentUser$: Observable<AlfredUser>;
    @Select(GenericState.loadedSelector(AlfredLoginState)) userLoaded$: Observable<boolean>;
    @Select(GenericState.errorSelector(AlfredLoginState)) errorLoadingUser$: Observable<boolean>;

    // endregion

    // region Constructor

    constructor(protected store: Store, private authService: AuthenticationService)
    {
    }

    // endregion

    /**
     * Ensures that the current user is loaded, or redirects to the login page if that is not the case
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    {
        /**
         * If the user is already loaded, we don't want to reload it (because otherwise, during Routing & Guard
         * lifecycle, it can trigger multiple "refreshes", and we don't want that)
         */
        return this.userLoaded$.pipe(
            /**
             * If not loaded, we refresh the OAuth Token, to refresh the current user
             */
            map(loaded =>
            {
                if (loaded)
                {
                    return of(true);
                }
                else
                {
                    return this.authService.refreshToken();
                }
            }),
            /**
             * Now, we want to wait for either the current user to be loaded, or for an errors to be thrown
             */
            switchMap(refreshRequest$ => combineLatest(refreshRequest$, this.currentUser$, this.errorLoadingUser$).pipe(
                filter(([ _initialRequest, user, error ]: any) => !!user || !!error),

                /**
                 * The API call is over, we have either an User or an Error
                 */
                map(([ _initialRequest, _user, error ]: [ any, AlfredUser, boolean | null ]) =>
                {
                    /**
                     * If errors, we log out
                     */
                    if (!!error)
                    {
                        this.authService.setInterruptedUrl(state.url);
                        this.store.dispatch(new LogoutAction());
                        return false;
                    }
                    /**
                     * No errors, the user is loaded, this method can return true
                     */
                    else
                    {
                        return true;
                    }
                }),
                take(1)
            ))
        );
    }
}
