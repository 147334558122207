import { SpecificRoleGuard } from "../../../../msl-driver-registration/src/lib/authentication/guards/specific-role.guard";
import * as ngxs from "@ngxs/store";
import { SpringAuthority } from "mys-base";
import { CurrentUserUtils } from "msl-login";
import {Injectable} from "@angular/core";

/**
 * created on 13/07/2023
 * This Guard ensures that the current user is loaded and that he is a Driver (paying or free)
 */
@Injectable({ providedIn: 'root' })
export class LibertyDriverGuard extends SpecificRoleGuard
{
    constructor(ngxsStore: ngxs.Store)
    {
        super(ngxsStore);
    }

    getPredicateWithRoles(roles: SpringAuthority[]): boolean
    {
        return CurrentUserUtils.isRolePayingDriver(roles) || CurrentUserUtils.isRoleFreeDriver(roles);
    }

    getUrlToRedirect(): string
    {
        return MY_INVOICES_PATH;
    }
}

export const MY_INVOICES_PATH_WITHOUT_PREFIX_SLASH = 'my/invoices';
export const MY_INVOICES_PATH = '/' + MY_INVOICES_PATH_WITHOUT_PREFIX_SLASH;
