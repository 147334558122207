import { DriverDocument } from '../../models/driver-document';
import { GenericState, GenericStateModel } from 'mys-base';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MysamTranslateService } from 'msl-translate';
import { DriverDocumentsService } from '../services/driver-documents.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    GetDocumentById,
    ResetDriverDocument,
    UpdateDocumentInList,
    UpdateDriverDocumentState
} from '../actions/driver-documents.action';
import { DriverDocumentsError } from '../errors/driver-documents.error';

/**
 * Created by Aurélien Rieu on 08/03/2019
 */
export interface DriverDocumentStateModel extends GenericStateModel
{
    driverDocument: DriverDocument | null;
    driverDocumentUpdated: boolean; // Is the DriverDocument being updated ?
    translatedError: string | null;
}

export const driverDocumentInitialState = {
    driverDocument: null,
    driverDocumentUpdated: null,
    translatedError: null,
    ...GenericState.init()
};

import { Injectable } from '@angular/core';

@State({
    name: 'driverDocument',
    defaults: driverDocumentInitialState
})
@Injectable()
export class DriverDocumentState
{
    // region Constructor

    constructor(private driverDocumentsService: DriverDocumentsService, private translate: MysamTranslateService)
    {
    }

    // endregion

    // region Selector

    @Selector()
    static driverDocument(state: DriverDocumentStateModel)
    {
        return state.driverDocument;
    }

    @Selector()
    static driverDocumentUpdated(state: DriverDocumentStateModel)
    {
        return state.driverDocumentUpdated;
    }

    @Selector()
    static translatedError(state: DriverDocumentStateModel)
    {
        return state.translatedError;
    }

    // endregion

    // region GetDocumentById

    @Action(GetDocumentById)
    GetDriverDocument(ctx: StateContext<DriverDocumentStateModel>, action: GetDocumentById)
    {
        ctx.patchState({
            ...GenericState.load(),
            driverDocumentUpdated: false,
            translatedError: null
        });

        return this.driverDocumentsService.getDocumentById(action.documentId).pipe(
            map((driverDocument: DriverDocument) => this.getDriverDocumentSuccess(ctx, driverDocument)),
            catchError((error: any) =>
                of(this.driverDocumentActionFail(ctx, this.driverDocumentsService.buildDriverDocumentsError(error))))
        );
    }

    // noinspection JSMethodCanBeStatic
    getDriverDocumentSuccess(ctx: StateContext<DriverDocumentStateModel>, driverDocument: DriverDocument)
    {
        ctx.dispatch(new UpdateDocumentInList(driverDocument));

        return ctx.patchState({
            driverDocument: driverDocument,
            ...GenericState.success()
        });
    }

    // endregion

    // region UpdateDriverDocumentState

    @Action(UpdateDriverDocumentState)
    UpdateDriverDocumentState(ctx: StateContext<DriverDocumentStateModel>, action: UpdateDriverDocumentState)
    {
        ctx.patchState({
            ...GenericState.load(),
            driverDocumentUpdated: false,
            translatedError: null
        });

        return this.driverDocumentsService.updateDriverDocumentState(action.driverDocumentId, action.state).pipe(
            map((driverDocument: DriverDocument) => this.updateDriverDocumentStateSuccess(ctx, driverDocument)),
            catchError((error: any) =>
                of(this.driverDocumentActionFail(ctx, this.driverDocumentsService.buildDriverDocumentsError(error))))
        );
    }

    // noinspection JSMethodCanBeStatic
    updateDriverDocumentStateSuccess(ctx: StateContext<DriverDocumentStateModel>, driverDocument: DriverDocument)
    {
        ctx.dispatch(new UpdateDocumentInList(driverDocument));

        return ctx.patchState({
            driverDocument: driverDocument,
            driverDocumentUpdated: true,
            ...GenericState.success()
        });
    }

    // endregion

    // region DriverDocumentsError management for GenericState

    // noinspection JSMethodCanBeStatic
    driverDocumentActionFail(ctx: StateContext<DriverDocumentStateModel>, error: DriverDocumentsError)
    {
        const translatedError = error.getErrorMessage(this.translate);
        return ctx.patchState({
            driverDocument: null,
            driverDocumentUpdated: false,
            translatedError,
            ...GenericState.error(error)
        });
    }

    // endregion

    // region ResetDriverDocument

    @Action(ResetDriverDocument)
    resetDriverDocument(ctx: StateContext<DriverDocumentStateModel>, action: ResetDriverDocument)
    {
        return ctx.setState(driverDocumentInitialState);
    }

    // endregion
}
