<div class="my-2 p0 d-flex">
    <div [ngClass]="[ 'me-3 me-md-4 d-flex justify-content-center align-items-center', withVerticalMargin ? 'my-2' : 'my-0' ]"
         style="width: 40px; max-width: 40px; height: 40px">

        <!-- If "ionIcon" is specified, we display the <ion-icon> tag -->
        <ion-icon *ngIf="!!ionIcon" name="{{ ionIcon }}" class="{{ iconColorClass }} ion3x"></ion-icon>

        <!-- If "materialIcon" is specified, we display it instead -->
        <span *ngIf="!!materialIcon" [ngClass]="[ materialIconClass, iconColorClass ]"
              class="md-36">{{ materialIcon }}
        </span>
    </div>

    <!--
            Content display
            "w-100" allows to "push" the icon on the left, the edit icon on the right, and expand the content
            on all the remaining space
    -->
    <div class="d-flex flex-column justify-content-center w-100">

        <!--
                While not in editing mode, we display one of the following :
                - "textWhileEditing" if "textWhileEditing" is given
                - <ng-content> otherwise
        -->
        <div *ngIf="!isEditing">

            <!-- "textWhileEditing" is given -->
            <div *ngIf="!!textWhileEditing">
                {{ textWhileEditing }}
            </div>

            <!-- "textWhileEditing" is null/undefined -->
            <ng-content *ngIf="!textWhileEditing"></ng-content>
        </div>

        <!--
                In editing mode, we display one of the following :
                - A <matInput> containing "textWhileEditing" if "textWhileEditing" is given
                - The content-projection of "displayInEditMode" otherwise
        -->
        <ng-container *ngIf="isEditing">

            <!-- "textWhileEditing" is given -->
            <mat-form-field *ngIf="!!textWhileEditing">
                <input matInput type="text" [formControl]="currentInput"/>
            </mat-form-field>

            <!-- "textWhileEditing" is null/undefined -->
            <div *ngIf="!textWhileEditing">
                <ng-content select="[displayInEditMode]"></ng-content>
            </div>
        </ng-container>

        <div class="text-muted">{{ title }}</div>
    </div>

    <!-- Buttons used through content editing process -->
    <div *ngIf="isEditable" class="d-flex justify-content-end my-2">

        <button *ngIf="!isEditing"  mat-icon-button (click)="switchEditMode()">
            <mat-icon>edit</mat-icon>
        </button>

        <button *ngIf="isEditing"  mat-icon-button (click)="validateValue()">
            <mat-icon>done</mat-icon>
        </button>

        <button *ngIf="isEditing"  mat-icon-button (click)="switchEditMode()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
