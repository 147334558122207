<!--
        On Show, we force a refresh of the list in order to have the most accurate data.
        We rely on "alertsWithPagination.disableRefreshOnBlur.refresh()", in order to use the exact same refresh
        mechanism as the one triggered periodically by "alertsWithPagination.disableRefreshOnBlur"
-->
<mys-icon-and-alert-panel [badgeNumber]="nbUnassignedAlerts$ | async"
                          (onShow)="alertsWithPagination.disableRefreshOnBlur.refresh()">
    <mys-alerts-with-pagination #alertsWithPagination></mys-alerts-with-pagination>
</mys-icon-and-alert-panel>

<!--
        Swal warning the operator attempting to assign to a run that there are one or more unassigned alerts
        It should only be displayed in one AlertContainer (= the one always displayed at the top-right corner),
        but cannot be placed in AlertsComponent (since AlertsComponent does not actually exist until the
        user clicks on the notification icon at the top-right of the screen).
-->
<mys-swal-trip-operator-assign-alerts-failed
    #swalTripOperatorAssignAlertsFailed></mys-swal-trip-operator-assign-alerts-failed>
