import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { UserAutocompleteComponent } from '../../user-autocomplete/user-autocomplete.component';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SearchDriverAutocompleteState } from '../states/search-driver-autocomplete.state';
import { Driver } from 'mys-base';
import { ResetSearchDriversAutocomplete, SearchDriversAutocomplete } from '../actions/search-drivers-autocomplete.action';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DriverEligibilityFilter } from '../requests/driver-eligibility-filter';

@Component({
    selector: 'mys-driver-autocomplete',
    templateUrl: './driver-autocomplete.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverAutocompleteComponent implements AfterViewInit, OnDestroy
{
    @ViewChild('driverAutocomplete') innerAutocomplete: UserAutocompleteComponent;

    @Select(SearchDriverAutocompleteState.drivers) drivers$: Observable<Driver[]>;

    @Input() label: string = 'client-detail.actions.add-driver-to-client-favorite.SELECT-DRIVER';
    @Input() driverEligibilityFilter: DriverEligibilityFilter | null;
    @Output() driverSelected = new EventEmitter<MatAutocompleteSelectedEvent>();

    constructor(private ngxsStore: Store) {}

    ngAfterViewInit(): void
    {
        this.innerAutocomplete.autocomplete.errorNoValueSelected = 'errors.my-sam-errors.NO-DRIVER-SELECTED';
    }

    ngOnDestroy(): void
    {
        this.ngxsStore.dispatch(new ResetSearchDriversAutocomplete());
    }

    /**
     * Search all Drivers matching the 'term' in their lastname or firstname
     * @param {string} term
     */
    searchDriversByTerm(term: string): void
    {
        this.ngxsStore.dispatch(new SearchDriversAutocomplete(term, this.driverEligibilityFilter));
    }

    /**
     * Set the DriverAutocompleteComponent in errors
     * @param errorKey errors to display in the DriverAutocompleteComponent
     */
    setInError(errorKey: string): void
    {
        this.innerAutocomplete.autocomplete.autocomplete.setFieldErroneous(errorKey);
    }

}
