import { EventEmitter, Output, ViewChild, Directive } from '@angular/core';
import { MslAutocompleteWrapperComponent } from 'msl-components';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';

/**
 * Created by Adrien Dos Reis on 18/12/2019
 */
@Directive()
export abstract class AbstractAutocompleteComponent<T>
{
    // region Attributes

    /**
     * The actual autocomplete field
     */
    @ViewChild('autocomplete') autocomplete: MslAutocompleteWrapperComponent;

    /**
     * Outputs
     */
    @Output() optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();
    @Output() inputChangedAndDebounced = new EventEmitter<string>();
    @Output() inputCleared = new EventEmitter<void>(); // Emitted when the input is cleared by the user

    // endregion

    // region Abstract Methods

    abstract displayInInput(obj: T): string;
    abstract displayInOption(obj: T): string;

    // endregion

    /**
     * Exposing the inner "autocomplete" object$ to the outside world
     */
    get object$(): BehaviorSubject<any>
    {
        return this.autocomplete.object$;
    }
}
