export const MARK_TRIP_OPERATOR_AS_ENDED = '[TripOperatorEnded] Mark trip operator as ended';
export const RESET_TRIP_OPERATOR_ENDED = '[TripOperatorEnded] Reset trip operator ended attributes';

export class MarkTripOperatorAsEnded
{
    static readonly type = MARK_TRIP_OPERATOR_AS_ENDED;
    constructor(public tripId: number) {}
}

export class ResetTripOperatorEndedAttributes
{
    static readonly type = RESET_TRIP_OPERATOR_ENDED;

    constructor(){}
}
