import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GenericState, GenericStateModel } from 'mys-base';
import {
    AddToBlacklist,
    AddToFavorites,
    ResetAddToFavorites
} from '../actions/add-driver-to-client-favorites-or-blacklist.action';
import { AddToFavoritesOrBlacklistError } from '../errors/add-to-favorites-or-blacklist.error';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

const initialState = {
    ...GenericState.init()
};

import { Injectable } from '@angular/core';
import { AddDriverToClientFavoritesOrBlacklistService } from "../services/add-driver-to-client-favorites-or-blacklist.service";

@State({
    name: 'addDriverToClientFavoritesOrBlacklist',
    defaults: initialState
})
@Injectable()

export class AddToFavoritesOrBlacklistState
{
    constructor(private favoriteOrBlacklistService: AddDriverToClientFavoritesOrBlacklistService)
    {
    }

    // region Selectors

    @Selector()
    static loaded(state: GenericStateModel): boolean
    {
        return state.loaded;
    }

    @Selector()
    static loading(state: GenericStateModel): boolean
    {
        return state.loading;
    }

    @Selector()
    static error(state: GenericStateModel): AddToFavoritesOrBlacklistError
    {
        return state.error as AddToFavoritesOrBlacklistError;
    }

    // endregion

    // region Favorite

    @Action(AddToFavorites)
    addDriverToClientFavorites(ctx: StateContext<GenericStateModel>, action: AddToFavorites)
    {
        ctx.patchState({
            ...GenericState.load()
        });

        return this.favoriteOrBlacklistService.addDriverToClientFavorites(action.clientId, action.driverId).pipe(
            map(() => this.addToFavoritesOrBlacklistSuccess(ctx)),
            catchError((error: any) => of(this.addToFavoritesOrBlacklistFail(ctx, this.favoriteOrBlacklistService.createApiError(error))))
        );
    }

    // endregion

    // region Blacklist

    @Action(AddToBlacklist)
    addDriverToClientBlacklist(ctx: StateContext<GenericStateModel>, action: AddToBlacklist)
    {
        ctx.patchState({
            ...GenericState.load()
        });

        return this.favoriteOrBlacklistService.addDriverToClientBlacklist(action.clientId, action.driverId).pipe(
            map(() => this.addToFavoritesOrBlacklistSuccess(ctx)),
            catchError((error: any) => of(this.addToFavoritesOrBlacklistFail(ctx, this.favoriteOrBlacklistService.createApiError(error))))
        );
    }

    // end region

    // region Common

    addToFavoritesOrBlacklistSuccess(ctx: StateContext<GenericStateModel>)
    {
        return ctx.patchState({
            ...GenericState.success()
        });
    }

    addToFavoritesOrBlacklistFail(ctx: StateContext<GenericStateModel>, error: AddToFavoritesOrBlacklistError)
    {
        return ctx.patchState({
            ...GenericState.error(error)
        });
    }

    // endregion

    // region Reset

    @Action(ResetAddToFavorites)
    resetAddDriverToClientFavorites(ctx: StateContext<GenericStateModel>, action: ResetAddToFavorites)
    {
        return ctx.setState(initialState);
    }

    // endregion
}
