import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import {
    BindOperatorToTrip,
    ResetBindOperatorToTripAndAssignAlertsStates
} from "../../../trip-operator-binding/actions/trip-operator-binding.action";
import { MysamTranslateService } from "msl-translate";
import { OperatorError, TripOperatorError } from '../../../trips/errors/trip.operator.error';
import { Store } from '@ngxs/store';
import { ResetAlertMetaAttributes } from '../../../alerts/actions/alert.action';

@Component({
    selector: 'mys-swal-trip-operator-assign-alerts-failed',
    templateUrl: './swal-trip-operator-assign-alerts-failed.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwalTripOperatorAssignAlertsFailedComponent
{

    // region Attributes

    @ViewChild('swalTripOperatorAssignAlertsFailed') swalTripOperatorAssignAlertsFailed: SwalComponent;
    errorDescription: string;

    // endregion

    // region constructor

    constructor(private store: Store, private translate: MysamTranslateService)
    {
    }

    // endregion

    fire(tripOperatorError: TripOperatorError)
    {
        this.errorDescription = tripOperatorError.getErrorMessage(this.translate);

        this.swalTripOperatorAssignAlertsFailed.fire().then((result) =>
        {
            // When user click on confirm button we bind him to the trip and all unassigned alerts
            // Only if is a unhandledAlertOnTripException
            if (result.isConfirmed && tripOperatorError.type == OperatorError.Type.UnhandledAlertOnTripException)
            {
                const tripId = tripOperatorError.extraParams.tripId
                this.store.dispatch(new BindOperatorToTrip(tripId, true, true));
            }
            /**
             * If the user clicks on "Cancel", we want to clear the state in order to reset the UI state (= remove
             * the "loading/error" state on our buttons)
             * We only clear the meta attributes and not the whole state, in order to keep the lists loaded (otherwise,
             * we would not only reset the UI state but also clear the list, and that's not what we want : we want the
             * list to stay displayed but the buttons to be reset)
             */
            else if (!result.isConfirmed)
            {
                this.store.dispatch(new ResetAlertMetaAttributes());
            }
        });
    }

}
