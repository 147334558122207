import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class PagetitleService
{
    private page = {
        title: ''
    };

    constructor(private titleService: Title)
    {
    }

    setTitle(newTitle: string)
    {
        this.page.title = newTitle;
    }

    /**
     * Sets the inner title of this Service, as well as the <title> of the tab
     */
    setTitleAndTab(newTitle: string)
    {
        this.setTitle(newTitle)
        this.titleService.setTitle(newTitle);
    }

    getTitle(): string
    {
        return this.page.title;
    }

}
