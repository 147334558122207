import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UserDetailsPipe } from '../../pipes/user-details.pipe';
import { UserPipe } from '../../pipes/user.pipe';
import { UserAutocompleteComponent } from './user-autocomplete/user-autocomplete.component';
import { Subscription } from 'rxjs';
import { MysamTranslateService } from 'msl-translate';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
    selector: 'mys-referrer-autocomplete',
    templateUrl: '../../utils/components/abstract-autocomplete/abstract-autocomplete.component.html'
})
export class ReferrerAutocompleteComponent extends UserAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy
{
    // region Attributes

    private subscription = new Subscription();

    // endregion
    // region Constructor

    constructor(userDetailsPipe: UserDetailsPipe, userPipe: UserPipe, private translate: MysamTranslateService)
    {
        super(userDetailsPipe, userPipe);
    }

    // endregion

    // region Lifecycle

    ngOnInit()
    {
        this.subscription.add(
            this.translate.get('client-detail.actions.update-user-referrer.PLACEHOLDER').subscribe(
                label => this.label = label
            )
        );
    }

    ngAfterViewInit()
    {
        this.autocomplete.errorNoValueSelected = 'errors.update-user-referrer.UPDATE-USER-REFERRER-NO-REFERRER-SELECTED';
    }

    ngOnDestroy(): void
    {
        this.subscription.unsubscribe();
    }

    // endregion
}
