import { DocumentReference } from './document-reference';
import { DocumentState } from './constants/document-state';

/**
 * Created by Aurélien Rieu on 05/03/2019
 */
export class DriverDocument
{
    id: number;
    content: Uint8Array;
    lastModified: Date;
    documentReference: DocumentReference;
    state: DocumentState;
    isBankInfo: Boolean; // Calculated on front-end side

    static assign(uncasted: DriverDocument): DriverDocument
    {
        const document = Object.assign(new DriverDocument(), uncasted);

        document.documentReference = Object.assign(new DocumentReference(), uncasted.documentReference);

        /**
         * https://mysamcab.atlassian.net/browse/MYS-4534
         * If the current document is a "bank information" document, we might need to tell our Components
         * So far, we don't have any other way of knowing if the document is a
         * "bank information", other than looking at its "documentReferenceId" .... :/
         */
        document.isBankInfo = document.documentReference.documentReferenceId === 16;

        return document;
    }
}
