import { ApiService, Client } from 'mys-base';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Created by Adrien Dos Reis on 07/01/2019
 */
@Injectable({ providedIn: 'root' })
export class ClientAutocompleteService extends ApiService
{
    /**
     * Get all Clients matching a given term
     *
     * @param term
     * @param companyId If given, the backend will return only Clients bound to the given companyId
     * @returns
     */
    public getClientsByTerm(term: string, companyId: number | null): Observable<Client[]>
    {
        /**
         * If the companyId is given, we want to append it to the URL
         */
        const urlParams = !!companyId ? `&companyId=${companyId}` : '';

        /**
         * Now the term is passed as request param in the URL, because we need to encode it to avoid problems with special characters in email addresses
         */
        return this.httpClient.get<Client[]>(`${this.BASE_URL}/alfred/clients/search?term=${encodeURIComponent(term)}${urlParams}`).pipe(
            // Enforcing the return type here
            map((clients: any[]) => clients.map(client => Client.assign(client)))
        );
    }
}
