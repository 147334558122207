<!--
    Contains the whole Component :
    - Height 100% to fill exactly the whole available space
    - Displayed as a flex-column, to expand/shrink the height of its children based on their content
-->
<div class="d-flex flex-column h-100">

    <!--
            This div can grow on all the available space (without shrinking the remaining blocks at the bottom
            (bank info and submit buttons))
            We forced the "max-height" to none, because the Material library defines a "max-height" of 65vh.
            We want it to be bigger if needed (controlled by flex-grow-1), so we override this behavior
    -->
    <div mat-dialog-content class="flex-grow-1" style="max-height: none">
        <img src="{{ Constants.BASE_64_IMAGE }}{{ document.content }}" width="100%" height="auto"/>
    </div>

    <!--
            If the current document is a Bank document, we also display a little form, allowing our user to
            input the bank information
    -->
    <div *ngIf="document.isBankInfo">
        <mat-divider></mat-divider>

        <!--
            On submit, either one of the outputs will be emitted
            On success, we can validate the driver document (as we usually do)
            On errors, we reset the loading flags, in order to reset the "mat-dialog-actions" buttons states
        -->
        <mys-new-bank-information #newBankInfoComponent [driver]="driver"
                                  (newBankInfoAdded)="doValidateDriverDocument()"
                                  (errorWhileAdding)="resetLoadingFlags()"></mys-new-bank-information>
    </div>

    <div mat-dialog-actions id="mat-dialog-actions" class="justify-content-center">

        <!--
                The following buttons are both disabled if :
                - The document status is incompatible with it
                - The document status is being updated

                Furthermore, the loader is displayed if the document is being updated, but only on the latest button clicked

                UPDATE 20/11/2020
                https://mysamcab.atlassian.net/browse/MYS-4658
                In addition to the previous rules, the "Validate" button is also never "disabled" if the current
                document is a "BankInformation"
        -->
        <msl-primary-button [displayLoader]="isValidating()" [class]="'me-2'"
                            [disabled]="!document.isBankInfo && (isDocumentValidated(document.state) || isRefusing())"
                            (click)="validateDriverDocument()">{{ 'labels.SUBMIT' | mysamTranslate }}</msl-primary-button>

        <msl-accent-button [displayLoader]="isRefusing()" [class]="'ms-2'"
                           [disabled]="isDocumentRefused(document.state) || isValidating()"
                           (click)="refuseDriverDocument()">{{ 'labels.REFUSE' | mysamTranslate }}</msl-accent-button>
    </div>
</div>
