<div class="notification-list-container p-0">

    <mat-tab-group animationDuration="0ms" (selectedIndexChange)="onSelectedTabChanged($event)">

        <!-- Tab of unassigned alerts -->
        <mat-tab [label]="'notification.unassigned.TITLE' | mysamTranslate">

            <mys-alerts [alerts]="(unassignedAlerts$ | async)?.content"></mys-alerts>

            <!-- Handling the pagination here, by plugin our Page object to a <mat-paginator> -->
            <mat-paginator #unassignedAlertPaginator
                           [hidden]="!(unassignedAlerts$ | async) || (unassignedAlerts$ | async)?.totalElements <=1"
                           [length]="(unassignedAlerts$ | async)?.totalElements"
                           [pageSize]="(unassignedAlerts$ | async)?.size"
                           [pageIndex]="(unassignedAlerts$ | async)?.number"
                           hidePageSize showFirstLastButtons
                           (page)="changePage($event)"></mat-paginator>
        </mat-tab>

        <!-- Tab of assigned alert -->
        <mat-tab #assignedAlertTab [label]="'notification.assigned.TITLE' | mysamTranslate" class="p-0">

            <!-- Lazy loading of the assign alerts data -->
            <ng-template matTabContent>
                <mys-alerts [alerts]="assignedAlerts$ | async"></mys-alerts>
            </ng-template>
        </mat-tab>

    </mat-tab-group>
</div>
