import { TripOperator } from "../../../../mys-base/src/lib/models/trip-operator";
import { DatePipe } from "@angular/common";
import { UserPipe } from "../pipes/user.pipe";
import { MysamTranslateService } from "msl-translate";
import { Injectable } from "@angular/core";

/**
 * Created by Sandra Bénard on 07/09/2022
 */
@Injectable({ providedIn: 'root' })
export class TripOperatorUtils
{

    constructor(public datePipe: DatePipe, public userPipe: UserPipe, private translate: MysamTranslateService) {
    }

    /**
     * Returns the name of the operator, the date and time the operator bound to the trip
     */
    public getOperatorNameAndBindingDate(operator: TripOperator): string
    {
        let date: Date;
        let beginOrTerminate: string;
        if(!!operator.endDate)
        {
            date = operator.endDate;
            beginOrTerminate = this.translate.instant("attributes.OPERATOR-END-TOOLTIP");
        }
        else
        {
            date = operator.date;
            beginOrTerminate = this.translate.instant("attributes.OPERATOR-START-TOOLTIP");
        }
        let dateFormat = this.datePipe.transform(date, "dd/MM") + " " + this.translate.instant("labels.AT") + " " + this.datePipe.transform(date, "HH:mm");

        return this.userPipe.transform(operator.operator) + " " +
            beginOrTerminate + " " +
            this.translate.instant("labels.ON").toLowerCase() + " " + dateFormat ;
    }

}
