/**
 * Created by Aurélien Rieu on 05/03/2019
 */
export class DocumentReference
{
    documentReferenceId: number;
    label: string;
    mandatory: boolean;
    active: boolean;
}
