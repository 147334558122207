export class PageMetadata
{
    totalElements = 0;
    totalPages = 0;
    number = 0; // The page number
    size = 0; // The size of a page
    numberOfElements = 0; // The number of elements on the current page (can be different from "size" on the last page)
    first = false;
    last = false;
}

export class PageMetadataWrapper
{
    constructor(private pageMetadata = new PageMetadata() as PageMetadata)
    {
    }

    public getTotalElements(): number
    {
        return this.pageMetadata.totalElements;
    }

    public getTotalPages(): number
    {
        return this.pageMetadata.totalPages;
    }

    get pageSize(): number
    {
        return this.pageMetadata.size;
    }

    get pageNumber(): number
    {
        return this.pageMetadata.number;
    }

    public isLastPage(): boolean
    {
        return this.pageMetadata.number === this.pageMetadata.totalPages;
    }

    public isFirstPage(): boolean
    {
        return this.pageMetadata.first;
    }
}
