import { Action } from '@ngrx/store';
import { Client } from 'mys-base';
import { ClientUpdateRequest } from '../../../http/requests/clients/client-update.request';

// Load Clients
export const LOAD_CLIENT_BY_ID = '[Alfred] Load Client';
export const LOAD_CLIENT_BY_ID_FAIL = '[Alfred] Load Client Fail';
export const LOAD_CLIENT_BY_ID_SUCCESS = '[Alfred] Load Client Success';

export class LoadClientById implements Action
{
    readonly type = LOAD_CLIENT_BY_ID;

    constructor(public payload: string)
    {
    }
}

export class LoadClientByIdFail implements Action
{
    readonly type = LOAD_CLIENT_BY_ID_FAIL;

    constructor(public payload: any)
    {
    }
}

export class LoadClientByIdSuccess implements Action
{
    readonly type = LOAD_CLIENT_BY_ID_SUCCESS;

    constructor(public payload: Client)
    {
    }
}

// Edit a few properties from a Driver
export const EDIT_CLIENT = '[Alfred] Edit Client';
export const EDIT_CLIENT_FAIL = '[Alfred] Edit Client Fail';
export const EDIT_CLIENT_SUCCESS = '[Alfred] Edit Client Success';

export class EditClient implements Action
{
    readonly type = EDIT_CLIENT;

    constructor(public id: string, public changes: ClientUpdateRequest, public isFromListEdit: boolean = false)
    {
    }
}

export class EditClientFail implements Action
{
    readonly type = EDIT_CLIENT_FAIL;

    constructor(public payload: any)
    {
    }
}

export class EditClientSuccess implements Action
{
    readonly type = EDIT_CLIENT_SUCCESS;

    constructor(public payload: Client, public isFromListEdit: boolean = false)
    {
    }
}

// Action types
export type ClientAction = EditClient |
                           EditClientFail |
                           EditClientSuccess |
                           LoadClientById |
                           LoadClientByIdFail |
                           LoadClientByIdSuccess;
