import { Action } from '@ngrx/store';

// Notifiy available Drivers
export const TRIP_NOTIFIY_AVAILABLE_DRIVERS = '[Trip] Notifiy available Drivers';
export const TRIP_NOTIFIY_AVAILABLE_DRIVERS_FAIL = '[Trip] Notifiy available Drivers Fail';
export const TRIP_NOTIFIY_AVAILABLE_DRIVERS_SUCCESS = '[Trip] Notifiy available Drivers Success';

export class TripNotifyAvailableDrivers implements Action
{
    readonly type = TRIP_NOTIFIY_AVAILABLE_DRIVERS;

    constructor(public tripId: number, public ignoreVehicleType: boolean)
    {
    }
}

export class TripNotifyAvailableDriversFail implements Action
{
    readonly type = TRIP_NOTIFIY_AVAILABLE_DRIVERS_FAIL;

    constructor(public payload: any)
    {
    }
}

export class TripNotifyAvailableDriversSuccess implements Action
{
    readonly type = TRIP_NOTIFIY_AVAILABLE_DRIVERS_SUCCESS;

    constructor()
    {
    }
}

// Action types
export type TripNotifyAvailableDriversAction =
    TripNotifyAvailableDrivers |
    TripNotifyAvailableDriversFail |
    TripNotifyAvailableDriversSuccess;
