/**
 * Created by Aurélien Rieu on 22/05/2019
 */
export const LOAD_ADMINISTRATIVE_AREAS_BY_NAME = '[AdministrativeAreas] Load AdministrativeAreas by Name';
export const LOAD_ADMINISTRATIVE_AREAS_BY_REGION = '[AdministrativeAreas] Load AdministrativeAreas by Region';
export const LOAD_ADMINISTRATIVE_AREAS_FOR_DRIVER = '[AdministrativeAreas] Load AdministrativeAreas for Driver';
export const RESET_ADMINISTRATIVE_AREAS = '[AdministrativeAreas] Reset AdministrativeAreas';

/**
 * Load AdministrativeAreas by Region
 */
export class LoadAdministrativeAreasByRegion
{
    static readonly type = LOAD_ADMINISTRATIVE_AREAS_BY_REGION;

    constructor()
    {
    }
}

/**
 * Load AdministrativeAreas for Driver
 */
export class LoadAdministrativeAreasForDriver
{
    static readonly type = LOAD_ADMINISTRATIVE_AREAS_FOR_DRIVER;

    constructor(public driverId: string)
    {
    }
}

/**
 * Load AdministrativeAreas by Name
 */
export class LoadAdministrativeAreasByName
{
    static readonly type = LOAD_ADMINISTRATIVE_AREAS_BY_NAME;

    constructor(public partialName: string)
    {
    }
}

/**
 * Reset AdministrativeAreas
 */
export class ResetAdministrativeAreas
{
    static readonly type = RESET_ADMINISTRATIVE_AREAS;

    constructor()
    {
    }
}
