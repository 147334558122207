import { ApiService } from 'mys-base';
import { AlfredUserService } from './alfred-user.service';
import { ClientService } from './client.service';
import { ClientReferrerUpdateService } from './client.referrer.update.service';
import { LoginService } from '../../../../../msl-login/src/lib/services/login.service';
import { DiscussionsService } from './discussions.service';
import { UsersService } from './users.service';

export * from './alfred-user.service';
export * from './client.service';
export * from './client.referrer.update.service';
export * from './discussions.service';
export * from './users.service';

export const apiServices: any[] = [
    AlfredUserService,
    ApiService,
    ClientService,
    ClientReferrerUpdateService,
    DiscussionsService,
    UsersService
];

