import { AlfredState, getAlfredState } from '../reducers/index';
import { createSelector } from '@ngrx/store';
import * as fromUsersReducer from '../reducers/users.reducer';

export const getUsersState = createSelector(getAlfredState, (state: AlfredState) => state.users);

export const getUsersEntities = createSelector(getUsersState, fromUsersReducer.selectUsersEntities);
export const getAllUsers = createSelector(getUsersState, fromUsersReducer.selectAllUsers);

export const getUsersLoaded = createSelector(getUsersState, fromUsersReducer.getLoadedFromUsersReducer);
export const getUsersLoading = createSelector(getUsersState, fromUsersReducer.getLoadingFromUsersReducer);
export const getUsersError = createSelector(getUsersState, fromUsersReducer.getErrorFromUsersReducer);
