import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { filter } from 'rxjs/operators';
import { map, Observable, Subscription } from 'rxjs';
import { TripOperatorError } from '../../../../trips/errors/trip.operator.error';
import { ResetMetaAttributesAndKeepTripAction } from '../../../../trips/actions';
import { Select, Store } from '@ngxs/store';
import { GenericState } from 'mys-base';
import { TripOperatorBindingState } from '../../../../trip-operator-binding/states/trip-operator-binding.state';
import {
    SwalTripOperatorAssignAlertsFailedComponent
} from '../../../../trip-operator/components/swal-trip-alert-error/swal-trip-operator-assign-alerts-failed.component';
import { AlertState } from '../../../state/alert.state';
import { AlertsWithPaginationComponent } from '../../alerts-with-pagination/alerts-with-pagination.component';

@Component({
    selector: 'mys-header-alerts-container',
    templateUrl: './header-alerts-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderAlertsContainerComponent implements OnInit
{
    // region Attributes

    private subscription = new Subscription();

    @ViewChild('alertsWithPagination') alertsWithPagination: AlertsWithPaginationComponent;

    @ViewChild('swalTripOperatorAssignAlertsFailed') swalTripOperatorAssignAlertsFailed: SwalTripOperatorAssignAlertsFailedComponent;

    @Select(AlertState.nbUnassignedAlerts) nbUnassignedAlerts$: Observable<number | null>;
    @Select(GenericState.errorSelector(TripOperatorBindingState)) bindOperatorError$: Observable<TripOperatorError>;

    // endregion

    // region Constructor

    constructor(private store: Store)
    {
    }

    // endregion

    // region Lifecycle

    ngOnInit(): void
    {
        this.subscription.add(this.bindOperatorError$.pipe(
            filter((error) => !!error),
            map((error: TripOperatorError) =>
            {
                /**
                 Displays an information dialog to warn the user when another person tries to assign themselves at the same time
                 OR
                 When an operator attempts to assign themselves to a trip with unassigned alerts
                 */
                this.swalTripOperatorAssignAlertsFailed.fire(error);

                this.store.dispatch(new ResetMetaAttributesAndKeepTripAction());
            })
        ).subscribe());
    }

    // endregion
}
