import { Pipe, PipeTransform } from '@angular/core';

/**
 * Displays a User object as "firstName lastName", or an empty string if an empty user
 * object was passed to the "transform" method
 */
@Pipe({ name: 'user' })
export class UserPipe implements PipeTransform
{
    transform(user?: { firstName, lastName }): string
    {
        if (!!user)
        {
            return user.lastName + ' ' + user.firstName;
        }

        return '';
    }
}
