import { MysamTranslateService } from 'msl-translate';
import { ApiError } from 'mys-base';

/**
 * Created by Aurélien Rieu on 04/06/2019
 */
export class DriverError extends ApiError
{
    constructor(apiError: ApiError)
    {
        super(apiError.type, apiError.description, apiError.httpStatus, apiError.extraParams);
    }

    public getErrorMessage(translate: MysamTranslateService): string
    {
        switch (this.type)
        {
            // The AlfredUser was not found
            // The userId extra param is not used in the returned errors message for user readability
            case TripAppealParametersError.Type.AlfredUserNotFound:
                return translate.instant('errors.driver.DRIVER-BASE') + ' ' +
                    translate.instant('errors.my-sam-errors.ALFRED-USER-NOT-FOUND', { userId: this.extraParams.userId });

            // The Driver was not found
            // The userId extra param is not used in the returned errors message for user readability
            case TripAppealParametersError.Type.DriverNotFoundException:
                return translate.instant('errors.driver.DRIVER-BASE') + ' ' +
                    translate.instant('errors.my-sam-errors.DRIVER-NOT-FOUND', { userId: this.extraParams.userId });

            // The User was not found
            // The userId extra param is not used in the returned errors message for user readability
            case TripAppealParametersError.Type.UserNotFoundException:
                return translate.instant('errors.driver.DRIVER-BASE') + ' ' +
                    translate.instant('errors.my-sam-errors.USER-NOT-FOUND', { userId: this.extraParams.userId });

            // The email syntax is invalid
            case TripAppealParametersError.Type.InvalidEmailSyntaxException:
                return translate.instant('errors.user-update.USER-UPDATE-BASE') + ' ' +
                    translate.instant('errors.user-update.INVALID-EMAIL-SYNTAX');

            // The email is already used by another account
            case TripAppealParametersError.Type.EmailAlreadyExistsException:
                return translate.instant('errors.user-update.USER-UPDATE-BASE') + ' ' +
                    translate.instant('errors.user-update.EMAIL-ALREADY-EXISTS');

            /**
             * For now, we don't handle other errors
             */
            default:
                return translate.instant('errors.my-sam-errors.DEFAULT-ERROR');
        }
    }
}

export namespace TripAppealParametersError
{
    export enum Type
    {
        AlfredUserNotFound = 'ALFRED_USER_NOT_FOUND', // userId
        DriverNotFoundException = 'DRIVER_NOT_FOUND', // userId
        UserNotFoundException = 'USER_NOT_FOUND', // userId
        InvalidEmailSyntaxException = 'INVALID_EMAIL_SYNTAX',
        EmailAlreadyExistsException = 'EMAIL_ALREADY_EXISTS'
    }
}
