import * as fromActions from '../../actions/clients/client.action';
import * as referrerlUpdateActions from '../../actions/clients/client.referrer.update';
import { Client } from 'mys-base';

export interface ClientState
{
    edited: boolean;
    editing: boolean;
    loaded: boolean;
    loading: boolean;
    premiumDriversAdded: boolean;
    premiumDriversAdding: boolean;
    currentClient: Client;
    error: any;
}

export const initialState: ClientState =
{
    edited: false,
    editing: false,
    loaded: false,
    loading: false,
    premiumDriversAdded: false,
    premiumDriversAdding: false,
    currentClient: null,
    error: null
};

export function reducer(state: ClientState = initialState,
                        action:
                            fromActions.ClientAction |
                            referrerlUpdateActions.ClientReferrerUpdateAction): ClientState
{
    switch (action.type)
    {
        /* Editing actions */
        case fromActions.EDIT_CLIENT:
        {
            if (!action.isFromListEdit)
            {
                return {
                    ...state,
                    edited: false,
                    editing: true,
                    error: null
                };
            }
            else
            {
                return state;
            }
        }

        case referrerlUpdateActions.CLIENT_REFERRER_UPDATE:
        {
            return {
                ...state,
                edited: false,
                editing: true,
                error: null
            };
        }

        case fromActions.EDIT_CLIENT_SUCCESS:
        case referrerlUpdateActions.CLIENT_REFERRER_UPDATE_SUCCESS:
        {
            return {
                ...state,
                currentClient: action.payload,
                edited: true,
                editing: false,
                error: null
            };
        }

        case fromActions.EDIT_CLIENT_FAIL:
        case referrerlUpdateActions.CLIENT_REFERRER_UPDATE_FAIL:
        {
            return {
                ...state,
                premiumDriversAdded: false,
                premiumDriversAdding: false,
                error: action.payload
            };
        }

        /* Loading actions */
        case fromActions.LOAD_CLIENT_BY_ID:
        {
            return {
                ...state,
                loading: true,
                loaded: false,
                edited: false,
                currentClient: null,
                error: null
            };
        }

        case fromActions.LOAD_CLIENT_BY_ID_SUCCESS:
        {
            return {
                ...state,
                loading: false,
                loaded: true,
                currentClient: action.payload,
                error: null
            };
        }

        case fromActions.LOAD_CLIENT_BY_ID_FAIL:
        {
            return {
                ...state,
                loading: false,
                loaded: false,
                currentClient: null,
                error: action.payload
            };
        }
    }

    return state;
}

/* Edition selectors */
export const getEditedFromClientReducer = (state: ClientState) => state.edited;
export const getEditingFromClientReducer = (state: ClientState) => state.editing;

/* Loading selectors */
export const getLoadedFromClientReducer = (state: ClientState) => state.loaded;
export const getLoadingFromClientReducer = (state: ClientState) => state.loading;

/* Add regional premiums drivers to clients selectors */
export const getPremiumDriversAddedFromClientReducer = (state: ClientState) => state.premiumDriversAdded;
export const getPremiumDriversAddingFromClientReducer = (state: ClientState) => state.premiumDriversAdding;

export const getCurrentClientFromClientReducer = (state: ClientState) => state.currentClient;
export const getErrorFromClientReducer = (state: ClientState) => state.error;
