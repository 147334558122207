import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { dependencies } from './index';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [ ...dependencies ],
    exports: [ ...dependencies ]
})
export class ErrorsModule
{
}
