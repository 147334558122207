import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Order } from './order';
import { $enum } from 'ts-enum-util';
import { Direction } from './direction';

@Component({
    selector: 'mys-datatable-column-sorter',
    templateUrl: './column-sorter.component.html',
    styleUrls: [ './column-sorter.component.scss' ]
})
export class ColumnSorterComponent
{

    // region Attributes

    @Input() title: string;
    @Input() sortKey: string;

    @Output()
    orderBy: EventEmitter<Order> = new EventEmitter<Order>();

    _orderDirectionDesc: boolean;
    get orderDirectionDesc(): boolean {
        return this._orderDirectionDesc;
    }

    set orderDirectionDesc(value: boolean) {
        this._orderDirectionDesc = value;
    }

    // endregion

    // region Constructor

    constructor() {}

    // endregion

    // region Methods

    /**
     * Force the value undefined for the Order
     */
    public forceUndefined()
    {
        this.orderDirectionDesc = undefined;
    }

    /**
     * Manage the display of the sort arrows and emit the associated Order
     */
    public toggleOrder()
    {
        // When the component is loaded, no Direction is selected
        if (this.orderDirectionDesc === undefined)
        {
            this.orderDirectionDesc = true;
            this.orderBy.emit(new Order(this.sortKey, $enum(Direction).getKeyOrDefault(Direction.DESC)));
            return;
        }

        if (this.orderDirectionDesc === true)
        {
            this.orderDirectionDesc = false;
            this.orderBy.emit(new Order(this.sortKey, $enum(Direction).getKeyOrDefault(Direction.ASC)));
            return;
        }

        if (this.orderDirectionDesc === false)
        {
            this.orderDirectionDesc = undefined;
            this.orderBy.emit(new Order(this.sortKey, $enum(Direction).getKeyOrDefault(Direction.NONE)));
            return;
        }
    }

    // endregion
}
