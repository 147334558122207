import './modernizr.js'; // 'npm run modernizr' to create this file

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { isLibertyDriver } from './environments/liberty-driver/environment.alfred';

if (environment.production)
{
    enableProdMode();
}

/**
 * If we are on Liberty Driver (and in production, we want to add a specific code block in the <HEAD> section of the DOM
 * Problem is : The <HEAD> is located in index.html, where there is no Angular Component to control whether we are on
 * Liberty Driver or on Alfred
 * To bypass this, we add the code block directly from here
 */
if (isLibertyDriver)
{
    const scriptAsync = document.createElement('script');
    const scriptSync = document.createElement('script');
    scriptAsync.src = `https://www.googletagmanager.com/gtag/js?id=${environment.LIBERTY_DRIVER_ANALYTICS_KEY}`;
    scriptAsync.async = true;
    scriptSync.innerHTML = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.LIBERTY_DRIVER_ANALYTICS_KEY}');
        gtag('send', 'pageview');`;
    document.head.appendChild(scriptAsync);
    document.head.appendChild(scriptSync);
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .then(() =>
    {
        (<any>window).appBootstrap && (<any>window).appBootstrap();
    });
// .catch(err => console.errors(err));
