/**
 * Created by Adrien Dos Reis on 07/01/2019
 */
import { UserAutocompleteComponent } from './user-autocomplete/user-autocomplete.component';
import { MslAutocompleteWrapperModule } from 'msl-components';
import { ReferrerAutocompleteComponent } from './referrer-autocomplete.component';
import { ClientAutocompleteComponent } from './client-autocomplete/components/client-autocomplete.component';
import { ClientsState } from './client-autocomplete/state/clients.state';
import { ClientAutocompleteService } from './client-autocomplete/services/client-autocomplete.service';
import { DriverAutocompleteComponent } from './driver-autocomplete/components/driver-autocomplete.component';
import { SearchDriverService } from './driver-autocomplete/services/search-driver.service';
import { SearchDriverAutocompleteState } from './driver-autocomplete/states/search-driver-autocomplete.state';
import { MysamTranslateModule } from 'msl-translate';
import { AddToFavoritesOrBlacklistState } from "./driver-autocomplete/states/add-to-favorites-or-blacklist.states";
import { AddDriverToClientFavoritesOrBlacklistService } from "./driver-autocomplete/services/add-driver-to-client-favorites-or-blacklist.service";

export const dependencies = [ UserAutocompleteComponent, ReferrerAutocompleteComponent, ClientAutocompleteComponent,
    DriverAutocompleteComponent ];

export const libraryDependencies = [ MslAutocompleteWrapperModule, MysamTranslateModule ];

export const services = [ ClientAutocompleteService, AddDriverToClientFavoritesOrBlacklistService, SearchDriverService ];

export const ngxsStates = [ ClientsState, SearchDriverAutocompleteState, AddToFavoritesOrBlacklistState ];
