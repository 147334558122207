import { MysamTranslateService } from 'msl-translate';
import { ApiError } from 'mys-base';

/**
 * Created by Aurélien Rieu on 06/06/2019
 */
export class WorkingAreasError extends ApiError
{
    constructor(apiError: ApiError)
    {
        super(apiError.type, apiError.description, apiError.httpStatus, apiError.extraParams);
    }

    public getErrorMessage(translate: MysamTranslateService): string
    {
        switch (this.type)
        {
            // The AlfredUser was not found
            // The userId extra param is not used in the returned errors message for user readability
            case WorkingAreasError.Type.AlfredUserNotFound:
                return translate.instant('errors.update-user-working-areas.UPDATE-USER-WORKING-AREAS-BASE') + ' ' +
                    translate.instant('errors.my-sam-errors.ALFRED-USER-NOT-FOUND', { userId: this.extraParams.userId });

            // The Driver was not found
            // The userId extra param is not used in the returned errors message for user readability
            case WorkingAreasError.Type.DriverNotFoundException:
                return translate.instant('errors.update-user-working-areas.UPDATE-USER-WORKING-AREAS-BASE') + ' ' +
                    translate.instant('errors.my-sam-errors.DRIVER-NOT-FOUND', { userId: this.extraParams.userId });

            // The Driver must have at least one WorkingArea
            case WorkingAreasError.Type.DriverMustHaveAtLeastOneWorkingAreaException:
                return translate.instant('errors.update-user-working-areas.UPDATE-USER-WORKING-AREAS-BASE') + ' ' +
                    translate.instant('errors.update-user-working-areas.UPDATE-USER-WORKING-AREAS-DRIVER-MUST-HAVE-AT-LEAST-ONE-WORKING-AREA');

            /**
             * For now, we don't handle other errors
             */
            default:
                return translate.instant('errors.my-sam-errors.DEFAULT-ERROR');
        }
    }
}

export namespace WorkingAreasError
{
    export enum Type
    {
        AlfredUserNotFound = 'ALFRED_USER_NOT_FOUND', // userId
        DriverNotFoundException = 'USER_NOT_FOUND', // userId
        DriverMustHaveAtLeastOneWorkingAreaException = 'DRIVER_MUST_HAVE_AT_LEAST_ONE_WORKING_AREA'
    }
}
