import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AccessRightsService } from '../../../../../msl-driver-registration/src/lib/authentication/access-rights/access-rights.service';
import { SpringAuthority } from 'mys-base';

@Injectable()
export class MenuService
{
    menuItems: Observable<Array<any>>; // Turned into an Observable by Adrien Dos Reis on 14/12/2018

    constructor(private accessRights: AccessRightsService)
    {
    }

    addMenu(items: Array<{
        name: string,
        link?: string,
        href?: string,
        imgpath?: string,
        order?: number,
        iconclass?: string,
        label?: any,
        availableForRoles: SpringAuthority[], // Added by Adrien on 04/09/2019
        subitems?: Array<any>
    }>): void
    {
        /**
         * We build the menu items asynchronously, since we have to wait until we know if the user is bound to a company
         * before building the menu
         */
        this.menuItems = this.accessRights.currentUser$.pipe(
            filter(currentUser => !!currentUser),
            map(currentUser =>
            {
                const tempArray = [];

                /**
                 * We display the "item" if the current user has a role that allows him to see the "item"
                 */
                items.filter(item => item.availableForRoles
                    .filter(availableRole => currentUser.authorities.includes(availableRole)).length > 0)
                    .forEach((item) => tempArray.push(item));

                return tempArray.sort((a, b) =>
                {
                    return a.order - b.order;
                });
            })
        );
    }
}
