import { ApiService } from 'mys-base';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AdministrativeArea } from '../../models/administrative-area';
import { HttpErrorResponse } from '@angular/common/http';
import { AdministrativeAreasError } from '../errors/administrative-areas.error';

/**
 * Created by Aurélien Rieu on 22/05/2019
 */
@Injectable({ providedIn: 'root' })
export class AdministrativeAreasService extends ApiService
{
    private readonly ADMINISTRATIVE_AREAS_PUBLIC_URL = this.BASE_URL + '/alfred/public/administrative-areas';

    /**
     * Get the list of all AdministrativeAreas ordered by Region ID then by name
     *
     * @returns {Observable<AdministrativeArea[]>} The list of all AdministrativeAreas ordered by Region ID then by name
     */
    public getAdministrativeAreasByRegion(): Observable<AdministrativeArea[]>
    {
        return this.getAdministrativeAreaListByUrl( this.ADMINISTRATIVE_AREAS_PUBLIC_URL + '/all');
    }

    /**
     * Get the list of all AdministrativeAreas ordered by Region ID then by name
     *
     * @returns {Observable<AdministrativeArea[]>} The list of all AdministrativeAreas ordered by Region ID then by name
     */
    public getAdministrativeAreasForDriver(driverId: string): Observable<AdministrativeArea[]>
    {
        return this.getAdministrativeAreaListByUrl(this.BASE_URL + '/alfred/administrative-areas/driver/', driverId);
    }

    /**
     * Get the list of all AdministrativeAreas ordered by Region ID then by name
     *
     * @returns {Observable<AdministrativeArea[]>} The list of all AdministrativeAreas ordered by Region ID then by name
     */
    public getAdministrativeAreasByName(partialName: string): Observable<AdministrativeArea[]>
    {
        return this.httpClient.get<AdministrativeArea[]>(`${this.ADMINISTRATIVE_AREAS_PUBLIC_URL}/by-name?name=${partialName}`)

            // For each entry in administrativeAreas list, we enforce the type as "AdministrativeArea"
            .pipe(map(administrativeAreas => this.mapAdminAreas(administrativeAreas)));
    }

    // region Private Methods

    /**
     * Get the list of all AdministrativeAreas using an endpoint URL as parameter
     *
     * @param {string} url The URL of the endpoint being called
     * @param {String} driverId The ID of the Driver
     * @returns {Observable<AdministrativeArea[]>} The list of all AdministrativeAreas
     */
    private getAdministrativeAreaListByUrl(url: string, driverId?: String): Observable<AdministrativeArea[]>
    {
        const urlComplete = driverId === undefined ? url : url + driverId;
        return this.httpClient.get<AdministrativeArea[]>(urlComplete)

        // For each entry in administrativeAreas list, we enforce the type as "AdministrativeArea"
            .pipe(map(administrativeAreas => this.mapAdminAreas(administrativeAreas)));
    }

    private mapAdminAreas(unmapped: any[]): AdministrativeArea[]
    {
        return unmapped.map(administrativeArea => Object.assign(new AdministrativeArea(), administrativeArea));
    }

    // endregion

    /**
     * Error builder for the services
     *
     * @param {HttpErrorResponse} err The errors to cast in AdministrativeAreasError
     * @returns {AdministrativeAreasError} An errors typed as AdministrativeAreasError
     */
    public buildAdministrativeAreasError(err: HttpErrorResponse): AdministrativeAreasError
    {
        const error = super.createApiError(err);
        return new AdministrativeAreasError(error);
    }
}
