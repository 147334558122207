import { $enum } from 'ts-enum-util';
import { DocumentState } from '../models/constants/document-state';
import { Store } from '@ngxs/store';

export class DriverDocumentsUtils
{

    // region Constructor

    constructor(protected store: Store)
    {
    }

    // endregion

    // region Checks on DocumentState

    /**
     * Get the DocumentState formatted as string
     *
     * @param {DriverDocumentState} documentState The DocumentState to convert in string
     * @returns The string representing DocumentState
     */
    private getDriverDocumentState(documentState: DocumentState): string
    {
        return $enum(DocumentState).getKeyOrDefault(documentState);
    }

    /**
     * Checks if the states of a DriverDocument is equal to a given DocumentState
     *
     * @param {DocumentState} documentState The states of the DriverDocument
     * @param {DocumentState} documentStateToCompare The DocumentState to compare with the DriverDocument states
     * @returns {boolean} True if both DocumentState are equals, else if not
     */
    private isDocumentStateEqualToGivenDocumentSate(documentState: string, documentStateToCompare: DocumentState): boolean
    {
        return documentState === this.getDriverDocumentState(documentStateToCompare);
    }

    /**
     * Checks if the states of a DriverDocument is equal to DocumentState.VALIDATED
     *
     * @param {DocumentState} documentState The states of the DriverDocument
     * @returns {boolean} True if DriverDocument states equal DocumentState.VALIDATED, else if not
     */
    isDocumentValidated(documentState: string): boolean
    {
        return this.isDocumentStateEqualToGivenDocumentSate(documentState, DocumentState.VALIDATED);
    }

    /**
     * Checks if the states of a DriverDocument is equal to DocumentState.VALIDATION_PROCESS
     *
     * @param {DocumentState} documentState The states of the DriverDocument
     * @returns {boolean} True if DriverDocument states equal DocumentState.VALIDATION_PROCESS, else if not
     */
    isDocumentValidationProcess(documentState: string): boolean
    {
        return this.isDocumentStateEqualToGivenDocumentSate(documentState, DocumentState.VALIDATION_PROCESS);
    }

    /**
     * Checks if the states of a DriverDocument is equal to DocumentState.REFUSED
     *
     * @param {DocumentState} documentState The states of the DriverDocument
     * @returns {boolean} True if DriverDocument states equal DocumentState.REFUSED, else if not
     */
    isDocumentRefused(documentState: string): boolean
    {
        return this.isDocumentStateEqualToGivenDocumentSate(documentState, DocumentState.REFUSED);
    }

    // endregion
}
