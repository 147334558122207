import { NgModule } from "@angular/core";
import { RedirectButtonComponent } from "./component/redirect-button.component";
import { MslButtonModule } from "msl-components";
import { MysamTranslateModule } from "msl-translate";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [RedirectButtonComponent],
    imports: [
        CommonModule,
        MslButtonModule,
        MysamTranslateModule
    ],
    exports: [ RedirectButtonComponent ]
})
export class RedirectButtonModule {}
