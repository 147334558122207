import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GenericState, GenericStateModel } from 'mys-base';
import { Driver } from '../../../../../../../mys-base/src/lib/models/users/driver';
import { SearchDriverService } from '../services/search-driver.service';
import { SearchDriversAutocomplete, ResetSearchDriversAutocomplete } from '../actions/search-drivers-autocomplete.action';
import { SearchDriversAutocompleteError } from '../errors/search-driver.error';
import { catchError, map, tap} from 'rxjs/operators';
import { of } from 'rxjs';

export interface DriverAutocompleteStateModel extends GenericStateModel
{
    data: Driver[];
}

const initialState = {
    data: [],
    ...GenericState.init()
};

import { Injectable } from '@angular/core';
import { NotifPaymentIntentsStateModel } from '../../../../payment-intents/state/notif-payment-intents.state';

@State({
    name: 'searchDriverAutocomplete',
    defaults: initialState
})
@Injectable()

export class SearchDriverAutocompleteState
{
    constructor(private searchDriverService: SearchDriverService)
    {
    }

    // region Selectors

    @Selector()
    static drivers(state: DriverAutocompleteStateModel): Driver[]
    {
        return state.data;
    }

    @Selector()
    static error(state: DriverAutocompleteStateModel): SearchDriversAutocompleteError
    {
        return state.error as SearchDriversAutocompleteError;
    }

    // endregion

    // region Actions

    @Action(ResetSearchDriversAutocomplete)
    resetSearchDriversAutocomplete(ctx: StateContext<DriverAutocompleteStateModel>, action: ResetSearchDriversAutocomplete)
    {
        return ctx.setState(initialState);
    }

    @Action(SearchDriversAutocomplete)
    searchDriversAutocomplete(ctx: StateContext<DriverAutocompleteStateModel>, action: SearchDriversAutocomplete)
    {
        ctx.patchState({
            ...GenericState.load()
        });

        /**
         * Two cases here :
         * - Either we have a "driverEligibilityFilter" and we want to call "searchEligibleDriversByTerm"
         * - Or we don't, and we just want to call "searchDriversByTerm"
         */
        return this.searchDriverService.searchDriversByTermWithEligibilityIfGiven(action.term, action.driverEligibilityFilter).pipe(
            map((drivers: Driver[]) => this.searchDriversAutocompleteSuccess(ctx, drivers)),
            catchError((error: any) => of(this.searchDriversAutocompleteFail(ctx, this.searchDriverService.createApiError(error))))
        );
    }

    // endregion

    // region Utils

    searchDriversAutocompleteSuccess(ctx: StateContext<DriverAutocompleteStateModel>, drivers: Driver[])
    {
        ctx.patchState({
            data: drivers,
            ...GenericState.success()
        });
    }

    searchDriversAutocompleteFail<M extends GenericStateModel>(ctx: StateContext<M>, error: SearchDriversAutocompleteError)
    {
        /**
         * Does not compile anymore starting from May 2023 :/
         * https://github.com/microsoft/TypeScript/issues/50818
         * Ignored through ts-ignore
         */
        // @ts-ignore
        ctx.patchState({
            ...GenericState.error(error),
            loaded: true
        });
    }

    // endregion
}
