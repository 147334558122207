import { NgModule } from '@angular/core';
import { declarations, libraryDependencies, materialDependencies, ngxsStates, services } from './index';
import { NgxsModule } from '@ngxs/store';

@NgModule({
    imports: [
        NgxsModule.forFeature([ ...ngxsStates ]),
        ...materialDependencies,
        ...libraryDependencies
    ],
    declarations: [ ...declarations ],
    providers: [ ...services ],
    exports: [ ...declarations, ...materialDependencies, ...libraryDependencies ]
})
export class SelectCompanyModule
{
}
