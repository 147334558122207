import { Region } from './region';

/**
 * Created by Aurélien Rieu on 22/05/2019
 */
export class AdministrativeArea
{
    id: number;
    code: string;
    name: string;
    region: Region;
    isDefaultForRegion: boolean;
    latitude: number;
    longitude: number;
}
