<button (click)="stopEventPropagation($event)"
        mat-raised-button class="w-100"
        [matMenuTriggerFor]="tripDetailMenu"
        [ngClass]="{ 'cursor-default' : !displayCaret}">
    {{ title }}
    <span *ngIf="displayCaret" class="caret"></span>
</button>
<mat-menu #tripDetailMenu="matMenu">
    <ng-content></ng-content>
</mat-menu>
