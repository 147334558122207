/**
 * Created by Adrien Dos Reis on 17/12/2018
 */
import { Injectable, OnDestroy } from '@angular/core';
import { AlfredUser, Company, Driver } from 'mys-base';
import { filter, tap } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { merge } from 'rxjs/internal/observable/merge';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AlfredLoginState } from '../../login/state/alfred-login.state';

@Injectable({ providedIn: 'root' })
export class AccessRightsService implements OnDestroy
{
    // region Attributes

    currentUserSub = new BehaviorSubject<AlfredUser | null>(null);
    currentDriverSub = new BehaviorSubject<Driver | null>(null);
    isUserBoundToMySamSub = new BehaviorSubject<boolean>(false);
    companySub = new BehaviorSubject<Company | null>(null);
    private subscription = new Subscription();

    // endregion

    // region Selectors
    @Select(AlfredLoginState.isSupervisor) isSupervisor$: Observable<boolean>;

    /**
     * An Observable<boolean | null> (null if the current user is not loaded) that contains true if the
     * current user is not bound to any company
     */
    @Select(AlfredLoginState.isMySam) isUserBoundToMySam$: Observable<boolean | null>;

    /**
     * The AlfredUser currently connected
     */
    @Select(AlfredLoginState.user) currentUser$: Observable<AlfredUser>;

    /**
     * The Driver currently connected
     * (If the user is not a driver this variable will be Null)
     */
    @Select(AlfredLoginState.driver) currentDriver$: Observable<Driver | null>;

    // endregion

    // region Constructor & Lifecycle

    constructor()
    {
        /**
         * Sometimes, if this services is called too soon, NGXS can trigger an error
         * Wrapping into a "setTimeout" protects from this error (zzzzzzzz)
         */
        setTimeout(() =>
        {
            this.subscription.add(this.isUserBoundToMySam$.subscribe(
                fromMySam => this.isUserBoundToMySamSub.next(fromMySam)
            ));

            /**
             * We also store the Company
             */
            this.subscription.add(this.currentUser$.pipe(
                filter(user => !!user),
                tap(user =>
                {
                    this.currentUserSub.next(user);
                    this.companySub.next(user.company);
                })
            ).subscribe());

            this.subscription.add(this.currentDriver$.subscribe(
                currentDriver => this.currentDriverSub.next(currentDriver)
            ));
        });
    }

    ngOnDestroy(): void
    {
        this.subscription.unsubscribe();
    }

    // endregion

    /**
     * Returns an Observable containing true if any of the "isSupervisor$" or "isUserBoundToMySam$" observables
     * return a true value
     */
    public isSupervisorOrMySam$(): Observable<boolean>
    {
        return merge(this.isSupervisor$, this.isUserBoundToMySam$).pipe(
            filter(value => !!value) // True-ish value
        );
    }

    /**
     * Returns an Observable containing true if the current user is NOT a driver and if its Company is not null
     * (this is the definition of a B2B user)
     */
    public isUserB2B(): boolean
    {
        /**
         * The current user is loaded...
         */
        return !!this.currentUserSub.value

            /**
             * ... and is not a Driver ...
             */
            && !this.currentDriverSub.value

            /**
             * ... and is bound to a Company
             */
            && !!this.companySub.value;
    }
}
