import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'mys-download-driver-mobile-app',
    templateUrl: './download-driver-mobile-app.component.html',
    styleUrls: [ './download-driver-mobile-app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadDriverMobileAppComponent
{
    @Input() imgWidth = '300px';
}
