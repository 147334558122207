<h4 class="text-center">{{ 'bank-information.TITLE' | mysamTranslate }}</h4>

<!-- Row added to control the size of the inner columns (IBAN & BIC) -->
<div class="row">

    <!-- IBAN -->
    <div class="col-8 col-xl-6 offset-xl-1">
        <mat-form-field>
            <input matInput type="text"
                   [formControl]="ibanCtrl" [mask]="'[AA__] [____] [____] [____] [____] [____] [___]'"
                   [placeholder]="'bank-information.IBAN' | mysamTranslate">
            <mat-error *ngIf="ibanCtrl.errors?.required">{{ 'errors.inputs.REQUIRED' | mysamTranslate }}</mat-error>
            <mat-error
                    *ngIf="ibanCtrl.errors?.invalidatedFromServer">{{ ibanCtrl.errors.invalidatedFromServer }}</mat-error>
        </mat-form-field>
    </div>

    <!-- BIC -->
    <div class="col-4 col-xl-3">
        <mat-form-field>
            <input matInput type="text"
                   [formControl]="bicCtrl" [maxLength]="11"
                   [placeholder]="'bank-information.BIC' | mysamTranslate">
            <mat-error *ngIf="bicCtrl.errors?.required">{{ 'errors.inputs.REQUIRED' | mysamTranslate }}</mat-error>
            <mat-error
                    *ngIf="bicCtrl.errors?.invalidatedFromServer">{{ bicCtrl.errors.invalidatedFromServer }}</mat-error>
        </mat-form-field>
    </div>
</div> <!-- End row -->