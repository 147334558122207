/**
 * Created by Sandra Bénard on 19/05/2022
 */
export class OpenOnNewTab
{
    /**
     * We create this function because Safari blocking any call to window.open() which is made inside an async call.
     * So we need to wrap it in a setTimeout, because "setTimeout" runs on the main thread, instead of async.
     */
    static openUrlOnNewTab(url: string)
    {
        setTimeout(() => {
            window.open(url, '_blank');
        })
    }
}
