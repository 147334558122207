import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from "@angular/core";
import { CommonModule } from "@angular/common";
import { EmailFieldComponent } from "./components/email-field/email-field.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ForgotPasswordService } from "./services/forgot-password.service";
import { ForgotPasswordState } from "./state/forgot-password.state";
import { MatInputModule } from "@angular/material/input";
import { MysamTranslateModule } from "msl-translate";
import { NgxsModule } from "@ngxs/store";
import { ReactiveFormsModule} from "@angular/forms";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import {TranslateModule} from "@ngx-translate/core";
import { ENDPOINT_URL } from '.';

const components = [ EmailFieldComponent, ForgotPasswordComponent ];
const modules = [ MatInputModule, MysamTranslateModule, SweetAlert2Module ];
const services = [ ForgotPasswordService ];
const ngxsStates = [ ForgotPasswordState ];


@NgModule({
  declarations: [ ...components ],
  imports: [
    CommonModule,
    ...modules,
    NgxsModule.forFeature([...ngxsStates]),
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [ ...components, ...modules ],
  providers: [ ...services ],

  /**
   * See https://stackoverflow.com/a/63881579 - Option 2
   * Needed to avoid Angular complaining that 'ion-icon' is not a known element.
   */
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})

export class ForgotPasswordModule
{
  /**
   * This method allows 3rd-party applications to specify the URL this module should use to reset a password
   */
  static forRoot(endpointURL: string): ModuleWithProviders<ForgotPasswordModule>
  {
    return {
      ngModule: ForgotPasswordModule,
      providers: [
        {
          provide: ENDPOINT_URL,
          useValue: endpointURL
        }
      ]
    };
  }
}
