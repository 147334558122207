<mys-detail-item *ngIf="!!zoneSurcharge" [titleKey]="'attributes.ZONE-SURCHARGE'"
                 [ionIcon]="'trending-up'" [withVerticalMargin]="false"
                 [iconColorClass]="!!ionColorClass ? ionColorClass : (displayAsCompany ? 'text-success' : 'pink')">

    <div id="container" [matTooltip]="zoneSurcharge.zoneName">
        <!--
                First, we display the surcharge as a currency
                Since it can also be negative (sometimes we want a trip to be cheaper if starting/arriving somewhere),
                we need to check it before displaying the '+' symbol
        -->
        <span>
            <ng-container *ngIf="zoneSurcharge.zdSurcharge > 0">+</ng-container>
            {{ zoneSurcharge.zdSurcharge | zeroDCurrency }}
        </span>

        <!-- Then, we display the zone name, in a special <div> that does not display all the text if too long -->
        <div class="hide-text-if-too-long d-flex align-items-center">
            <ion-icon name="arrow-forward" class="mx-2"></ion-icon>{{ zoneSurcharge.zoneName }}
        </div>
    </div>
</mys-detail-item>