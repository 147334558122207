import { Action } from '@ngrx/store';
import { Trip } from '../../../../../mys-base/src/lib/models/trip';

// Cancel Trip
export const CANCEL_TRIP = '[Alfred] Cancel Trip';
export const CANCEL_TRIP_FAIL = '[Alfred] Cancel Trip Fail';
export const CANCEL_TRIP_SUCCESS = '[Alfred] Cancel Trip Success';

export class CancelTrip implements Action
{
    readonly type = CANCEL_TRIP;

    constructor(public tripId: number, public applyPenalty: boolean)
    {
    }
}

export class CancelTripFail implements Action
{
    readonly type = CANCEL_TRIP_FAIL;

    constructor(public payload: any)
    {
    }
}

export class CancelTripSuccess implements Action
{
    readonly type = CANCEL_TRIP_SUCCESS;

    constructor(public payload: Trip)
    {
    }
}

// Action types
export type CancelTripAction = CancelTrip | CancelTripFail | CancelTripSuccess;
