import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as tripReducers from './trip.reducer';
import * as tripsReducers from './trips.reducer';

export interface TripCommonState
{
    currentTrip: tripReducers.TripState;
    trips: tripsReducers.TripsState;
}

export const reducers: ActionReducerMap<TripCommonState> =
{
    currentTrip: tripReducers.reducer,
    trips: tripsReducers.reducer
};

export const getTripCommonState = createFeatureSelector<TripCommonState>('trip');
