import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuService } from '../../core/menu/menu.service';
import { SettingsService } from '../../shared/settings/settings.service';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AccessRightsService } from '../../../../../msl-driver-registration/src/lib/authentication/access-rights/access-rights.service';

declare var $: any;

@Component({
    selector: 'mys-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: [ './sidebar.component.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit, OnDestroy
{
    menu: Observable<Array<any>>; // Turned into an Observable by Adrien Dos Reis on 14/12/2018

    private subscription = new Subscription();

    constructor(private menuService: MenuService, public settings: SettingsService, private router: Router,
                public accessRights: AccessRightsService)
    {
        this.menu = this.menuService.menuItems;
    }

    ngOnInit()
    {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => this.closeSidebar());
    }

    ngOnDestroy()
    {
        this.subscription.unsubscribe();
    }

    closeSidebar()
    {
        this.settings.app.sidebar.visible = false;

        /**
         * Added on 09/07/2018 by Adrien Dos Reis
         */
        if (this.settings.app.sidebar.offcanvas)
        {
            this.settings.app.sidebar.offcanvasVisible = false;
        }
    }

    handleSidebar(event)
    {
        const item = this.getItemElement(event);
        // check click is on a tag
        if (!item)
        {
            return;
        }

        const ele = $(item), liparent = ele.parent()[ 0 ];

        const lis = ele.parent().parent().children(); // markup: ul > li > a
        // remove .active from childs
        lis.find('li').removeClass('active');
        // remove .active from siblings ()
        $.each(lis, function (key, li)
        {
            if (li !== liparent)
            {
                $(li).removeClass('active');
            }
        });

        const next = ele.next();
        if (next.length && next[ 0 ].tagName === 'UL')
        {
            ele.parent().toggleClass('active');
            event.preventDefault();
        }
    }

    // find the a element in click context
    // doesn't check deeply, asumens two levels only
    getItemElement(event)
    {
        const element = event.target, parent = element.parentNode;
        if (element.tagName.toLowerCase() === 'a')
        {
            return element;
        }
        if (parent.tagName.toLowerCase() === 'a')
        {
            return parent;
        }
        if (parent.parentNode.tagName.toLowerCase() === 'a')
        {
            return parent.parentNode;
        }
    }
}
