<div class="d-flex align-items-center" (click)="toggleOrder()" id="sorterContainer">
        <div>{{ title }}</div>
        <div class="d-flex flex-column ps-3">

            <ion-icon *ngIf="!orderDirectionDesc || (orderDirectionDesc === undefined)"
                      [ngClass]="{'padding-top-6px': orderDirectionDesc === false}"
                      name="caret-up-outline"></ion-icon>

            <ion-icon *ngIf="orderDirectionDesc || (orderDirectionDesc === undefined)"
                      [ngClass]="{'padding-top-6px': orderDirectionDesc === true}"
                      name="caret-down-outline"></ion-icon>
        </div>
</div>
