import { Pipe, PipeTransform } from '@angular/core';

/**
 * Displays a User object as "firstName lastName", or an empty string if an empty user
 * object was passed to the "transform" method
 */
@Pipe({
    name: 'alfredUser'
})
export class AlfredUserPipe implements PipeTransform
{
    transform(alfredUser?: { firstName, lastName }): string
    {
        if (!!alfredUser)
        {
            return alfredUser.firstName + ' ' + alfredUser.lastName;
        }

        return '';
    }
}
