import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { SvgreplaceDirective } from './directives/svgreplace/svgreplace.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { KnobDirective } from './directives/knob/knob.directive';
import { RippleDirective } from './directives/ripple/ripple.directive';

import { SettingsService } from './settings/settings.service';

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        SettingsService
    ],
    declarations: [
        EasypiechartDirective,
        SvgreplaceDirective,
        VectormapDirective,
        KnobDirective,
        RippleDirective
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        EasypiechartDirective,
        SvgreplaceDirective,
        VectormapDirective,
        KnobDirective,
        RippleDirective
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        };
    }
}
