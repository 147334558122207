<!--
    swal to alert the user when trying to bind to an alert/trip and errors appears
-->
<swal #swalTripOperatorAssignAlertsFailed
      [title]="'bind-operator.ERROR-TITLE' | mysamTranslate"
      [showLoaderOnConfirm]="true" [allowOutsideClick]="false"
      [showConfirmButton]="true" [confirmButtonText]="'labels.SUBMIT' | mysamTranslate"
      [showCancelButton]="true" [cancelButtonText]="'labels.CANCEL' | mysamTranslate">
    <div *swalPortal>
        <p [innerHTML]="errorDescription"></p>
        <p class="fs-2 mt-5" style="color: orangered">{{ 'bind-operator.FORCE-BINDING-CONFIRM' | mysamTranslate }}</p>
    </div>
</swal>
