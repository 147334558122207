/**
 * This class represents a Page in a given pagination.
 * The definition of this class matches the definition of Spring PageImpl class
 */
import { PageMetadata } from './page-metadata';

export class Page<T> extends PageMetadata
{
    constructor(public content: T[] = [])
    {
        super();

        /**
         * We infer some default values based on whatever content was provided
         */
        this.totalElements = this.content.length;
        this.size = this.content.length;
        this.numberOfElements = this.content.length;

        this.number = 0;
        this.totalPages = 1;

        this.first = true;
        this.last = true;
    }
}
