import { AfterViewInit, Component, Inject, ViewEncapsulation } from '@angular/core';
import {
    AccessRightsService
} from '../../../msl-driver-registration/src/lib/authentication/access-rights/access-rights.service';
import { MysamTranslateService } from 'msl-translate';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ThemeableComponent } from 'mys-base';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { DOCUMENT } from '@angular/common';

// Declaration of the Google Analytics method here
declare let gtag: Function;

@Component({
    selector: 'mys-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent extends ThemeableComponent implements AfterViewInit
{
    // region Constructor

    constructor(public accessRights: AccessRightsService,
                /**
                 * This services is imported in order to set up the language from the AppComponent.
                 * This way, wherever we are in the app, the language is set up
                 */
                private translate: MysamTranslateService,
                router: Router, @Inject(DOCUMENT) document: Document)
    {
        super(router, document);

        /**
         * We initialize our DebugLogger here (as soon as possible, since we want it to capture as many logs as possible)
         */
        // TODO Activate this when ready
        // DebugLogger.getInstance();

        /**
         * Kudos to https://medium.com/better-programming/how-to-properly-add-google-analytics-tracking-to-your-angular-web-app-bc7750713c9e
         * for helping with this one
         */
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            tap((event: NavigationEnd) =>
            {
                if (typeof gtag !== 'undefined') // If Google Analytics is enabled
                {
                    gtag('config', environment.LIBERTY_DRIVER_ANALYTICS_KEY, { 'page_path': event.urlAfterRedirects });
                }
            })
        ).subscribe();
    }

    // endregion

    // region Themes

    protected getPrimaryColor(): string
    {
        return '#14cab3';
    }

    protected getAccentColor(): string
    {
        return '#ff3366';
    }

    protected forceLightContrast(): boolean | null
    {
        return false;
    }

    protected getFontPathFromClientSpecificConfig$(): Observable<string>
    {
        return of('assets/fonts/Lato/Lato-Regular.ttf');
    }

    // endregion

    // region Lifecycle

    ngAfterViewInit()
    {
        super.ngAfterViewInit()
    }

    // endregion
}
