import { Injectable } from '@angular/core';
import { ApiService, MobileUser } from 'mys-base';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class UsersService extends ApiService
{
    private readonly BASE_USER_URL = environment.BASE_URL + '/alfred/users';

    public searchUsersByNameAndExcludeOneUser(userId: string, searchedName: string): Observable<MobileUser[]>
    {
        const url = this.BASE_USER_URL + '/' + userId + '/search/' + searchedName;

        return this.httpClient.get<MobileUser[]>(url).pipe(
            // Enforcing the return type here
            map((users: any[]) => users.map(user => Object.assign(new MobileUser(), user)))
        );
    }
}
