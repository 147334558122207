import { GenericState, GenericStateModel, Trip, TripOperator} from "mys-base";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {TripOperatorService} from "../service/trip-operator.service";
import {MarkTripOperatorAsEnded, ResetTripOperatorEndedAttributes} from "../action/trip-operator.action";
import {catchError, map} from "rxjs/operators";
import {of} from "rxjs";
import {
    AccessRightsService
} from "../../../../../msl-driver-registration/src/lib/authentication/access-rights/access-rights.service";

export interface TripOperatorStateModel extends GenericStateModel
{
    tripOperatorEnded: TripOperator | null;
}

const tripOperatorInitialState = {
    tripOperatorEnded: null,
    ...GenericState.init()
};

@State({
    name: 'tripOperator',
    defaults: tripOperatorInitialState
})

@Injectable()
export class TripOperatorState {

    // region Constructor

    constructor(private tripOperatorService: TripOperatorService, private accessRight: AccessRightsService) {
    }

    // region

    @Selector()
    static tripOperatorEnded(state: TripOperatorStateModel): TripOperator
    {
        return state.tripOperatorEnded;
    }

    @Action(MarkTripOperatorAsEnded)
    markTripOperatorAsEnded(ctx: StateContext<TripOperatorStateModel>, action: MarkTripOperatorAsEnded)
    {
        ctx.patchState({
            ...GenericState.load(),
        });

        return this.tripOperatorService.markTripOperatorAsEnded(action.tripId).pipe(
            map((trip: Trip) => this.markTripOperatorAsEndedSuccess(ctx, trip)),
            catchError((error: any) => of(this.markTripOperatorAsEndedFail(ctx, error)))
        );
    }

    markTripOperatorAsEndedSuccess(ctx: StateContext<TripOperatorStateModel>, trip: Trip)
    {
        const tripOperatorUpdated = trip.operators.find(a => a.operator.userId == this.accessRight.currentUserSub.value.userId
            && a.endDate != null);

        return ctx.patchState({
            tripOperatorEnded: tripOperatorUpdated,
            ...GenericState.success(),
        });
    }

    markTripOperatorAsEndedFail(ctx: StateContext<TripOperatorStateModel>, error: any)
    {
        return ctx.patchState({
            tripOperatorEnded: null,
            ...GenericState.error(error),
        });
    }

    // endregion

    // region Reset

    @Action(ResetTripOperatorEndedAttributes)
    reset(ctx: StateContext<TripOperatorStateModel>)
    {
        return ctx.setState(tripOperatorInitialState);
    }

    // endregion
}
