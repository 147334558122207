import { Action } from '@ngrx/store';
import { Client } from 'mys-base';
import { ClientFilter } from '../../../http/responses/filters/clients/client-filter';
import { ClientFilters } from '../../../http/responses/filters/clients/client-filters';
import { Page } from '../../../../../../msl-driver-registration/src/lib/http/responses/pagination/page';

// Load Clients
export const LOAD_CLIENTS = '[Alfred] Load Clients';
export const LOAD_CLIENTS_FAIL = '[Alfred] Load Clients Fail';
export const LOAD_CLIENTS_SUCCESS = '[Alfred] Load Clients Success';

export class LoadClients implements Action
{
    readonly type = LOAD_CLIENTS;

    constructor(public payload: ClientFilter)
    {
    }
}

export class LoadClientsFail implements Action
{
    readonly type = LOAD_CLIENTS_FAIL;

    constructor(public payload: any)
    {
    }
}

export class LoadClientsSuccess implements Action
{
    readonly type = LOAD_CLIENTS_SUCCESS;

    constructor(public payload: Page<Client>)
    {
    }
}

// Load Clients as csv
export const LOAD_CLIENTS_CSV = '[Alfred] Load Clients as csv';
export const LOAD_CLIENTS_CSV_FAIL = '[Alfred] Load Clients as csv Fail';
export const LOAD_CLIENTS_CSV_SUCCESS = '[Alfred] Load Clients as csv Success';

export class LoadClientsCsv implements Action
{
    readonly type = LOAD_CLIENTS_CSV;

    constructor(public payload: ClientFilters)
    {
    }
}

export class LoadClientsCsvFail implements Action
{
    readonly type = LOAD_CLIENTS_CSV_FAIL;

    constructor(public payload: any)
    {
    }
}

export class LoadClientsCsvSuccess implements Action
{
    readonly type = LOAD_CLIENTS_CSV_SUCCESS;

    constructor(public payload: any)
    {
    }
}

// Action types
export type ClientsAction = LoadClients | LoadClientsFail | LoadClientsSuccess
    | LoadClientsCsv | LoadClientsCsvFail | LoadClientsCsvSuccess;
