import { Component, Input } from '@angular/core';
import { UserSource } from 'mys-base';

@Component({
    selector: 'ld-layout[source]',
    templateUrl: 'ld-layout.component.html'
})
export class LdLayoutComponent
{
    @Input() outerContainerClass = '';
    @Input() innerContainerClass = '';
    @Input() textBelowLogo = '';
    @Input() source: UserSource;

    UserSource = UserSource;
}
