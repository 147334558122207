import { Injectable } from '@angular/core';
import {
    EDIT_CLIENT,
    EditClient,
    EditClientFail,
    EditClientSuccess,
    LOAD_CLIENT_BY_ID,
    LoadClientById,
    LoadClientByIdFail,
    LoadClientByIdSuccess
} from '../../actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ClientService } from '../../../services';
import { of } from 'rxjs';
import { Client } from 'mys-base';

@Injectable()
export class ClientEffect
{
    constructor(private actions$: Actions, private clientService: ClientService)
    {
    }

    editClient$ = createEffect(() => this.actions$.pipe(ofType<EditClient>(EDIT_CLIENT),
        switchMap((action: EditClient) => this.clientService.updateClient(action.id, action.changes).pipe(
            map((client: Client) => new EditClientSuccess(client, action.isFromListEdit)),
            catchError((error: any) => of(new EditClientFail(this.clientService.createApiError(error))))
        ))
    ));

    loadDriverById$ = createEffect(() => this.actions$.pipe(ofType<LoadClientById>(LOAD_CLIENT_BY_ID),
        map((action: LoadClientById) => action.payload),
        switchMap(clientId => this.clientService.getClientById(clientId).pipe(
            map((client: Client) => new LoadClientByIdSuccess(client)),
            catchError((error: any) => of(new LoadClientByIdFail(error))))
        )
    ));
}
