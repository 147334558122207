import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { MysamTranslateService } from 'msl-translate';
import { AccessRightsService } from '../../../../../msl-driver-registration/src/lib/authentication/access-rights/access-rights.service';
import { Company } from 'mys-base';
import { Subscription } from 'rxjs';
import { GetCompaniesAction } from '../../../../../msl-driver-registration/src/lib/select-company/actions/companies.action';

@Component({
    selector: 'mys-detail-item-company[company]',
    templateUrl: './detail-item-company.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailItemCompanyComponent implements OnInit, OnDestroy
{
    // region Component Inputs / Outputs

    @Input() emptyKey = 'labels.NONE-FEMALE'; // Message key displayed when there is no data (no company)
    @Input() ionIcon = 'briefcase-outline';
    @Input() ionIconColorClass = 'pink';
    @Input() selectCompanyAction = new GetCompaniesAction();
    @Input() titleKey: string;
    @Input() title: string;
    @Input() company: Company;
    @Input() editableIfNotEmpty = false; // By default, if a Company is set, we cannot un-set it

    @Output() newCompany: EventEmitter<Company> = new EventEmitter<Company>();

    // endregion

    // region Attributes

    isEditing = false;
    selectedCompany: Company;

    private subscription: Subscription = new Subscription();

    // endregion

    // region Constructor

    constructor(private translate: MysamTranslateService,
                private changeDetectorRef: ChangeDetectorRef,
                public accessRights: AccessRightsService)
    {
    }

    // endregion

    // region Angular lifecycle

    ngOnInit(): void
    {
        if (!!this.titleKey)
        {
            this.subscription.add(this.translate.get(this.titleKey).subscribe(title =>
            {
                this.title = title;
                this.changeDetectorRef.detectChanges();
            }));
        }
    }

    ngOnDestroy(): void
    {
        this.subscription.unsubscribe();
    }

    // endregion

    // region UI Actions

    /**
     * Switch the UI (display/edit) of the buttons for the component
     */
    public switchEditMode(): void
    {
        this.isEditing = !this.isEditing;
    }

    /**
     * Update the value selected by the component
     * @param {Company} $event The value selected
     */
    selectCompany($event: Company): void
    {
        this.selectedCompany = $event;
    }

    /**
     * Validate the Company selected and send the value to the parent component
     */
    public validateSelectedCompany(): void
    {
        /* Emit the value selected to the parent component */
        this.newCompany.emit(this.selectedCompany);

        /* Switch back to display mode */
        this.switchEditMode();
    }

    // endregion
}
