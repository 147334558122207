import { ApiError } from 'mys-base';
import { $enum } from 'ts-enum-util';
import { MySamError } from './models/my-sam-error';
import { MysamTranslateService } from 'msl-translate';

export class ClientReferrerUpdateError extends ApiError
{
    private readonly REFERRER = 'referrer';
    private readonly USER = 'user';
    private readonly USER_ID = 'userId';

    constructor(apiError: ApiError)
    {
        super(apiError.type, apiError.description, apiError.httpStatus, apiError.extraParams);
    }

    public getErrorMessage(translate: MysamTranslateService): string
    {
        switch (this.type) {
            case $enum(MySamError).getKeyOrDefault(MySamError.ALFRED_USER_NOT_FOUND): {
                return translate.instant(MySamError.UPDATE_USER_REFERRER_BASE) + ' ' +
                    translate.instant(MySamError.ALFRED_USER_NOT_FOUND);
            }
            case $enum(MySamError).getKeyOrDefault(MySamError.USER_NOT_FOUND): {
                return translate.instant(MySamError.UPDATE_USER_REFERRER_BASE) + ' ' +
                    translate.instant(MySamError.USER_NOT_FOUND,
                        { userId: this.extraParams[this.USER_ID] });
            }
            case $enum(MySamError).getKeyOrDefault(MySamError.UPDATE_USER_REFERRER_USER_ALREADY_WITH_REFERRER): {
                return translate.instant(MySamError.UPDATE_USER_REFERRER_BASE) + ' ' +
                    translate.instant(MySamError.UPDATE_USER_REFERRER_USER_ALREADY_WITH_REFERRER,
                        {
                            referrer: this.extraParams[this.REFERRER],
                            user: this.extraParams[this.USER] });
            }
            default: {
                return translate.instant(MySamError.UPDATE_USER_REFERRER_BASE) + ' ' +
                    translate.instant(MySamError.DEFAULT_ERROR);
            }
        }
    }
}
