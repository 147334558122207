import { ApiError } from 'mys-base';
import { MysamTranslateService } from "msl-translate";

export class AddToFavoritesOrBlacklistError extends ApiError
{

    constructor(apiError: ApiError)
    {
        super(apiError.type, apiError.description, apiError.httpStatus, apiError.extraParams);
    }

    public getErrorMessage(translate: MysamTranslateService): string
    {
        switch (this.type)
        {
            case AddToFavoritesOrBlacklistError.Type.UserNotFound:
                return translate.instant('errors.my-sam-errors.USER-NOT-FOUND');

            case AddToFavoritesOrBlacklistError.Type.ClientNotFound:
                return translate.instant('errors.my-sam-errors.CLIENT-NOT-FOUND');

            case AddToFavoritesOrBlacklistError.Type.DriverNotFound:
                return translate.instant('errors.my-sam-errors.DRIVER-NOT-FOUND');

            default: return translate.instant('errors.DEFAULT-BODY');
        }
    }
}

export namespace AddToFavoritesOrBlacklistError
{
    export enum Type
    {
        UserNotFound = 'USER_NOT_FOUND',
        ClientNotFound = 'CLIENT_NOT_FOUND',
        DriverNotFound = 'DRIVER_NOT_FOUND'
    }
}
