import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Alert, GenericState } from "mys-base";
import { Observable } from "rxjs";
import { Select } from "@ngxs/store";
import { AlertState } from "../../state/alert.state";

@Component({
    selector: 'mys-alerts[alerts]',
    templateUrl: './alerts-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertsListComponent
{

    // region Attributes

    @Input() alerts: Alert[];

    /**
     * Controls the behavior of this component if "alerts" is empty
     * If true, will display a container with a "No alerts" text inside
     * If false, will hide the container
     */
    @Input() displayEmptyContainerIfEmpty: boolean = true;

    @Select(AlertState.errorLoading) alertsError$: Observable<boolean>;
    @Select(GenericState.loadingSelector(AlertState)) alertsLoading$: Observable<boolean>;

    // endregion

    /**
     * See https://angular.io/api/core/TrackByFunction
     * Method allowing to tell Angular how to track elements in the Alerts List (and apply animations when updating
     * them)
     */
    trackByAlertId(index: number, item: Alert): number
    {
        return item.id
    }
}
