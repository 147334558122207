import * as fromActions from '../../actions/clients/clients.action';
import * as singleClientActions from '../../actions/clients/client.action';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { PageMetadata } from '../../../../../../msl-driver-registration/src/lib/http/responses/pagination/page-metadata';
import { Client } from 'mys-base';
import { RESET_CSV, ResetCsv } from '../../actions';

export interface ClientState extends EntityState<Client>
{
    loaded: boolean;
    loading: boolean;
    pageMetadata: PageMetadata;
    csv: Blob;
}

const clientAdapter = createEntityAdapter<Client>({ selectId: (client: Client) => client.userId });
const initialState: ClientState = clientAdapter.getInitialState({
    loaded: false,
    loading: false,
    pageMetadata: new PageMetadata(),
    csv: null
});

export function reducer(state: ClientState = initialState,
                        action: fromActions.ClientsAction | singleClientActions.ClientAction | ResetCsv): ClientState
{
    switch (action.type)
    {
        /**
         * Loading actions
         */
        case fromActions.LOAD_CLIENTS:
        {
            return {
                ...state,
                loading: true
            };
        }

        case fromActions.LOAD_CLIENTS_SUCCESS:
        {
            const newClientList = action.payload.content;
            const { content, ...pageMetadata } = action.payload;

            /**
             * We remove the clients already stored in-memory, in order to fully refresh the list
             */
            const tempState = clientAdapter.removeAll(state);
            return clientAdapter.addMany(newClientList, { ...tempState, loading: false, loaded: true, pageMetadata });
        }

        case fromActions.LOAD_CLIENTS_FAIL:
        {
            return {
                ...state,
                loading: false,
                loaded: false,
                pageMetadata: new PageMetadata()
            };
        }

        /**
         * LOAD CLIENTS CSV ACTIONS
         */
        case fromActions.LOAD_CLIENTS_CSV_FAIL:
        {
            return { ...state };
        }

        case fromActions.LOAD_CLIENTS_CSV_SUCCESS:
        {
            return { ...state, csv: action.payload };
        }

        case RESET_CSV:
        {
            return { ...state, csv: null };
        }

        /**
         * Editing actions
         */

        // At this point, nothing to do on "EDIT_CLIENT" or "EDIT_CLIENT_FAIL"

        case singleClientActions.EDIT_CLIENT_SUCCESS:
        {
            if (action.isFromListEdit) /* If not, the actions hasn't been dispatch from the client's list */
            {
                return clientAdapter.updateOne(
                    { id: action.payload.userId, changes: action.payload },
                    { ...state, loading: false, loaded: true }
                );
            }
            else
            {
                return state;
            }
        }
    }

    return state;
}

export const {
    // select the array of Client ids
    selectIds: selectClientIds,

    // select the dictionary of Client entities
    selectEntities: selectClientEntities,

    // select the array of Clients
    selectAll: selectAllClients,

    // select the total Clients count
    selectTotal: selectClientsTotal
} = clientAdapter.getSelectors();


export const getLoadedFromClientsReducer = (state: ClientState) => state.loaded;
export const getLoadingFromClientsReducer = (state: ClientState) => state.loading;
export const getMetadataFromClientsReducer = (state: ClientState) => state.pageMetadata;
export const getCsvFromClientsReducer = (state: ClientState) => state.csv;
