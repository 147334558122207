import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UsersService } from '../../services';
import { SEARCH_USERS, SearchUsers, SearchUsersFail, SearchUsersSuccess } from '../actions';
import { MobileUser } from 'mys-base';

@Injectable()
export class UsersEffect
{
    constructor(private actions$: Actions, private usersService: UsersService)
    {
    }

    loadUsers$ = createEffect(() =>
        this.actions$.pipe(ofType<SearchUsers>(SEARCH_USERS),
            switchMap((action: SearchUsers) =>
                this.usersService.searchUsersByNameAndExcludeOneUser(action.userId, action.searchedName).pipe(
                    map((users: MobileUser[]) => new SearchUsersSuccess(users)),
                    catchError((error: any) => of(new SearchUsersFail(error)))
                ))
        )
    );
}
