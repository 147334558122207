import { Action } from '@ngrx/store';
import { Trip } from 'mys-base';
import {UpdateAddressRequest} from "../request/update-address-request";
import { TripUpdateRequest } from '../request/trip-update-request';

// Load trip by ID
export const LOAD_TRIP_BY_ID = '[Alfred] Load Trip By Id';
export const LOAD_TRIP_BY_ID_FAIL = '[Alfred] Load Trip By Id Fail';
export const LOAD_TRIP_BY_ID_SUCCESS = '[Alfred] Load Trip By Id Success';

export class LoadTripById implements Action
{
    readonly type = LOAD_TRIP_BY_ID;

    constructor(public payload: number)
    {
    }
}

export class LoadTripByIdFail implements Action
{
    readonly type = LOAD_TRIP_BY_ID_FAIL;

    constructor(public payload: any)
    {
    }
}

export class LoadTripByIdSuccess implements Action
{
    readonly type = LOAD_TRIP_BY_ID_SUCCESS;

    constructor(public payload: Trip)
    {
    }
}

// Edit a few properties from a Trip
export const EDIT_TRIP = '[Alfred] Edit Trip';
export const EDIT_TRIP_FAIL = '[Alfred] Edit Trip Fail';
export const EDIT_TRIP_SUCCESS = '[Alfred] Edit Trip Success';

export class EditTrip implements Action
{
    readonly type = EDIT_TRIP;

    constructor(public id: number, public changes: Partial<TripUpdateRequest>)
    {
    }
}

export class EditTripFail implements Action
{
    readonly type = EDIT_TRIP_FAIL;

    constructor(public payload: any)
    {
    }
}

export class EditTripSuccess implements Action
{
    readonly type = EDIT_TRIP_SUCCESS;

    constructor(public payload: Trip)
    {
    }
}

// Edit trip address
export const EDIT_TRIP_ADDRESS = '[Alfred] Edit Trip address';
export const EDIT_TRIP_ADDRESS_SUCCESS = '[Alfred] Edit Trip address Success';
export const RESET_TRIP_ADDRESS_EDITED = '[Alfred] Reset Trip address edited';

export class EditTripAddress implements Action
{
    readonly type = EDIT_TRIP_ADDRESS;

    constructor(public id: number, public addressRequest: UpdateAddressRequest)
    {
    }
}

export class EditTripAddressSuccess implements Action
{
    readonly type = EDIT_TRIP_ADDRESS_SUCCESS;

    constructor(public payload: Trip)
    {
    }
}

export class ResetTripAddressEdited implements Action
{
    readonly type = RESET_TRIP_ADDRESS_EDITED;

    constructor()
    {
    }
}

// This actions is used to synchronise a Trip from the NGXS Store into the NGRX Store
// Used before refactoring all the trip module in NGXS
export const SYNCHRONIZE_TRIP_BETWEEN_STORES = '[Alfred] Synchronize Trip Between Stores';

export class SynchronizeTripBetweenStores implements Action
{
    readonly type = SYNCHRONIZE_TRIP_BETWEEN_STORES;

    constructor(public payload: Trip)
    {
    }
}

// Used to keep the current trip, but reset all its meta-attributes to their original states
export const RESET_META_ATTRIBUTES_AND_KEEP_TRIP = '[Trip] Reset Meta Attributes and Keep Trip';

export class ResetMetaAttributesAndKeepTripAction implements Action
{
    readonly type = RESET_META_ATTRIBUTES_AND_KEEP_TRIP;

    constructor()
    {
    }
}
export const RESET_TRIP = '[Trip] Reset Trip';
export class ResetTrip implements Action
{
    readonly type = RESET_TRIP;

    constructor()
    {
    }
}

// Action types
export type TripAction = LoadTripById | LoadTripByIdSuccess | LoadTripByIdFail
    | EditTrip | EditTripFail | EditTripSuccess | EditTripAddress
    | EditTripAddressSuccess | ResetTripAddressEdited
    | SynchronizeTripBetweenStores | ResetMetaAttributesAndKeepTripAction | ResetTrip;
