import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlfredUser, SpringAuthority } from 'mys-base';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { AlfredLoginState } from '../../login/state/alfred-login.state';

/**
 * Created by Adrien Dos Reis on 20/09/2019
 *
 * Abstract util guard class to ensure that the current user is loaded and matches a given role.
 * If the roles did not match, redirects to a given URL
 */
export abstract class SpecificRoleGuard 
{
    // region Constructor & Attributes

    @Select(AlfredLoginState.user) currentUser$: Observable<AlfredUser>;

    protected constructor(protected store: Store)
    {
    }

    // endregion

    /**
     * Ensures that the current user is loaded and matches a given predicate about his roles.
     * If the predicateWithRoles method returns false, redirects to "urlToRedirect"
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    {
        return this.currentUser$.pipe(

            /**
             * We let the user through ONLY if he matches a given predicate about his roles
             */
            map(user => this.getPredicateWithRoles(user.authorities)),
            tap((matchesRole: boolean) =>
            {
                /**
                 * Otherwise, we redirect him to "urlToRedirect"
                 */
                if (!matchesRole)
                {
                    this.store.dispatch(new Navigate([ this.getUrlToRedirect() ]));
                }
            }),
            take(1)
        );
    }

    abstract getPredicateWithRoles(roles: SpringAuthority[]): boolean
    abstract getUrlToRedirect(): string
}
