import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiError, Company, GenericState, GenericStateModel } from 'mys-base';
import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { CompaniesService } from '../services/companies.service';
import { AddCompanyInStoreAction, GetCompaniesAction, GetGroupsOfDriversAction } from '../actions/companies.action';
import { Observable } from 'rxjs';

/**
 * Created by Adrien Dos Reis on 21/02/2019
 */
export interface CompaniesStateModel extends GenericStateModel
{
    companies: Company[];
}

export const companiesInitialState = {
    companies: [],
    ...GenericState.init()
};

import { Injectable } from '@angular/core';

@State({
    name: 'companies',
    defaults: companiesInitialState
})
@Injectable()
export class CompaniesState
{
    constructor(private companiesService: CompaniesService)
    {
    }

    @Selector()
    static companies(state: CompaniesStateModel): Company[]
    {
        return state.companies;
    }

    @Action(AddCompanyInStoreAction)
    addCompanyInStore(ctx: StateContext<CompaniesStateModel>, action: AddCompanyInStoreAction)
    {
        return ctx.patchState({
            companies: [ ...ctx.getState().companies, action.payload ]
        });
    }

    @Action(GetCompaniesAction)
    getCompanies(ctx: StateContext<CompaniesStateModel>, action: GetCompaniesAction)
    {
        return this.loadCompanies(ctx, () => this.companiesService.getCompanies(action.name));
    }

    @Action(GetGroupsOfDriversAction)
    getGroupsOfDrivers(ctx: StateContext<CompaniesStateModel>, action: GetGroupsOfDriversAction)
    {
        return this.loadCompanies(ctx, () => this.companiesService.getGroupsOfDrivers());
    }

    /**
     * Util method to load a list of Companies through the given "serviceCall", and put it in our Store
     */
    private loadCompanies(ctx: StateContext<CompaniesStateModel>, serviceCall: () => Observable<Company[]>)
    {
        ctx.patchState({ ...GenericState.load() });

        return serviceCall().pipe(
            map(companies => this.getCompaniesSuccess(ctx, companies)),
            catchError((error: any) => of(this.getCompaniesFail(ctx, this.companiesService.createApiError(error))))
        );
    }

    // noinspection JSMethodCanBeStatic
    getCompaniesSuccess(ctx: StateContext<CompaniesStateModel>, companies: Company[])
    {
        return ctx.patchState({
            companies,
            ...GenericState.success()
        });
    }

    // noinspection JSMethodCanBeStatic
    getCompaniesFail(ctx: StateContext<CompaniesStateModel>, error: ApiError)
    {
        return ctx.patchState({
            ...GenericState.error(error)
        });
    }
}
