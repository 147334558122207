import { GenericState, GenericStateModel, MySamPair } from "mys-base";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { Alert } from "projects/mys-base/src/lib/models/alert";
import { TripOperator } from "projects/mys-base/src/lib/models/trip-operator";
import { TripOperatorBindingService } from '../services/trip-operator-binding.service';
import {
    BindOperatorToTrip,
    BindOperatorToTripAndAssignAlertsError,
    BindOperatorToTripAndAssignAlertsSuccess,
    ResetBindOperatorToTripAndAssignAlertsStates
} from '../actions/trip-operator-binding.action';

/**
 * Created by Adrien Dos Reis on 10/03/2023
 */

export interface TripOperatorBindingStateModel extends GenericStateModel
{
    tripOperatorAdded: TripOperator | null;

    /**
     * Since we can bind multiple trips at once, we maintain an array of "tripIds currently being bound", instead of
     * a simple "loading" boolean
     */
    tripIdsBeingBound: number[];
}

const tripOperatorBindingInitialState = {
    tripOperatorAdded: null,
    tripIdsBeingBound: [],
    ...GenericState.init()
};

@State({
    name: 'tripOperatorBinding',
    defaults: tripOperatorBindingInitialState
})

@Injectable()
export class TripOperatorBindingState
{
    // region Constructor

    constructor(private tripOperatorBindingService: TripOperatorBindingService)
    {
    }

    // endregion

    // region Selector

    @Selector()
    static tripOperatorAdded(state: TripOperatorBindingStateModel): TripOperator
    {
        return state.tripOperatorAdded;
    }

    @Selector()
    static tripIdsBeingBound(state: TripOperatorBindingStateModel): number[]
    {
        return state.tripIdsBeingBound;
    }

    // endregion

    @Action(BindOperatorToTrip)
    bindOperatorToTrip(ctx: StateContext<TripOperatorBindingStateModel>, action: BindOperatorToTrip)
    {
        ctx.patchState({
            ...GenericState.load(),

            /**
             * Adding "action.tripId" to the list of "tripIds being bound"
             */
            tripIdsBeingBound: [action.tripId, ...ctx.getState().tripIdsBeingBound]
        });

        return this.tripOperatorBindingService.bindOperatorToTrip(
            action.tripId, action.isAnotherOperatorAlreadyBound, action.bindOperatorToAlertsToo)
            .pipe(
                /**
                 * Dispatching a specific action here, in order to update both our current state and the AlertState
                 * as well
                 */
                map((operatorAndAlertsAssigned: MySamPair<TripOperator | null, Alert[]>) =>
                    ctx.dispatch(new BindOperatorToTripAndAssignAlertsSuccess(operatorAndAlertsAssigned))),
                catchError((error: any) => ctx.dispatch(new BindOperatorToTripAndAssignAlertsError(error)))
            );
    }

    @Action(BindOperatorToTripAndAssignAlertsSuccess)
    bindOperatorToTripSuccess(ctx: StateContext<TripOperatorBindingStateModel>, action: BindOperatorToTripAndAssignAlertsSuccess)
    {
        /**
         * We will retrieve the tripId from the action, and remove it from the "tripIdsBeingBound" array
         */
        const tripId = action.payload.first?.tripId;
        const removedFromTripIdsBeingBound = ctx.getState().tripIdsBeingBound.filter(id => id !== tripId);

        return ctx.patchState({
            tripOperatorAdded: action.payload.first,
            tripIdsBeingBound: [...removedFromTripIdsBeingBound],
            ...GenericState.success()
        });
    }

    @Action(BindOperatorToTripAndAssignAlertsError)
    bindOperatorToTripFail(ctx: StateContext<TripOperatorBindingStateModel>, action: BindOperatorToTripAndAssignAlertsError)
    {
        const error = this.tripOperatorBindingService.buildTripOperatorError(action.httpErrorResponse);
        return ctx.patchState({
            tripOperatorAdded: null,

            /**
             * We don't know which request failed : For convenience, we simply clean all the "tripIdsBeingBound" array
             */
            tripIdsBeingBound: [],
            ...GenericState.error(error)
        });
    }

    // region Reset

    @Action(ResetBindOperatorToTripAndAssignAlertsStates)
    resetBindOperatorToTripState(ctx: StateContext<TripOperatorBindingStateModel>)
    {
        return ctx.patchState(tripOperatorBindingInitialState);
    }

    // endregion
}
