export class Constants
{
    static readonly AUTHENTICATION_BASIC: string = 'Basic YWxmcmVkLWFwcDpvSXpZcDE3MHk4N0tPN2pjUzM4ZW1wRmFHVHE5aEU4VGRKN0I2UWlTMXkyOVNCaks=';

    static readonly TRIP_EVENT_LOG_EXCLUSIVITY_CREATED: string = 'Exclusivity created';

    // Csv
    static readonly CLIENTS_DASH: string = 'clients-';
    static readonly CSV: string = '.csv';
    static readonly DASH: string = '-';
    static readonly DRIVERS_DASH: string = 'drivers-';
    static readonly DRIVERS_TRANSACTIONS_DASH = this.DRIVERS_DASH + 'transactions-';
    static readonly TRIPS_DASH: string = 'trips-';
    static readonly XML: string = '.xml';

    // DateTime
    static readonly DATE_FORMAT: string = 'yyyy-MM-dd';
    static readonly HOUR_FORMAT: string = 'HH-mm-ss';
    static readonly DD_MM_YYY___HH_MM___FR: string = 'dd/MM/yyyy HH:mm';
    static readonly DD_MM_YYY___HH_MM_SS___FR: string = 'dd/MM/yyyy HH:mm:ss';

    // HttpResponse
    static readonly HTTP_RESPONSE_TYPE_TEXT_CSV: string = 'text/csv';
    static readonly HTTP_RESPONSE_TYPE_TEXT_PDF: string = 'application/pdf';
    static readonly HTTP_RESPONSE_TYPE_TEXT_XML: string = 'text/xml';

    // Images
    static readonly BASE_64_IMAGE = 'data:image/jpeg;base64,';
}
