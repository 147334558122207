import { Driver } from 'mys-base';

/**
 * Created by Adrien Dos Reis on 09/11/2020
 */
export const POST_BANK_INFO = '[BankInformation] Post new bank info';
export const RESET_BANK_INFO = '[BankInformation] Reset bank info';

/**
 * Posts a new bank information to the server
 */
export class PostNewBankInformation
{
    static readonly type = POST_BANK_INFO;

    constructor(public driver: Driver, public iban: string, public bic: string)
    {
    }
}

export class ResetBankInformation
{
    static readonly type = RESET_BANK_INFO;

    constructor()
    {
    }
}