import { createSelector } from '@ngrx/store';
import * as fromTripReducer from '../reducers/trip.reducer';
import * as fromTripsReducer from '../reducers/trips.reducer';
import { getTripCommonState, TripCommonState } from '../reducers';

// region Trips

export const getTripsState = createSelector(getTripCommonState, (state: TripCommonState) => state.trips);

export const getTrips = createSelector(getTripsState, fromTripsReducer.selectAllTrips);

export const getTripsLoading = createSelector(getTripsState, fromTripsReducer.getLoadingFromTripsReducer);
export const getTripsMetadata = createSelector(getTripsState, fromTripsReducer.getMetadataFromTripsReducer);
export const getNewestTripIDs = createSelector(getTripsState, fromTripsReducer.getNewestIDsFromTripsReducer);
export const getTripsCsv = createSelector(getTripsState, fromTripsReducer.getCsvFromTripsReducer);
export const resetNewestTripIDs = createSelector(getTripsState, fromTripsReducer.getNewestIDsFromTripsReducer);
// endregion

// region Trip

export const getTripState = createSelector(getTripCommonState, (state: TripCommonState) => state.currentTrip);

export const getTripNotifyingDrivers = createSelector(getTripState, fromTripReducer.getNotifyingDriversFromTripReducer);
export const getTripEdited = createSelector(getTripState, fromTripReducer.getEditedFromTripReducer);
export const getTripEditing = createSelector(getTripState, fromTripReducer.getEditingFromTripReducer);
export const getCurrentTrip = createSelector(getTripState, fromTripReducer.getCurrentTripFromTripReducer);
export const getCurrentTripChecker = createSelector(getTripState, fromTripReducer.getCurrentTripCheckerFromTripReducer);
export const getTripError = createSelector(getTripState, fromTripReducer.getErrorFromTripReducer);
export const getTripAddressEdited = createSelector(getTripState, fromTripReducer.getEditedTripAddressFromTripReducer);
// endregion
