import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, OnDestroy, Renderer2,
    ViewChild
} from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {AlertState} from "../../state/alert.state";
import {Observable} from "rxjs";
import {Page} from "../../../../../../msl-driver-registration/src/lib/http/responses/pagination/page";
import {Alert} from "mys-base";
import {MatPaginator} from "@angular/material/paginator";
import {LoadUnassignedAlertsOnDashboard, ResetAlertMetaAttributes} from "../../actions/alert.action";
import {Pageable} from "../../../../../../msl-driver-registration/src/lib/http/responses/pagination/pageable";
import {DisableRefreshOnBlur} from "../../../utils/disable-refresh-on-blur";

@Component({
    selector: 'mys-unassigned-alert-container',
    templateUrl: './unassigned-alert-container.component.html',
    styleUrls: ['./unassigned-alert-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnassignedAlertContainerComponent implements AfterViewInit, OnDestroy {

    // region Attributes

    @ViewChild('alertPaginator', {static: false}) unassignedAlertPaginator: MatPaginator;
    @Select(AlertState.unassignedAlertsDashboard) unassignedAlertsPag$: Observable<Page<Alert>>;

    /**
     * This object handles the periodic refresh of the trip list, and auto-disables the refresh if the tab is
     * blurred
     */
    disableRefreshOnBlur = new DisableRefreshOnBlur(
        this.renderer, 30000, this.refreshList.bind(this));

    // endregion

    // region Constructor

    constructor(public store: Store, public cdRef: ChangeDetectorRef, private renderer: Renderer2) {
    }

    // endregion

    // region LifeCycle

    ngAfterViewInit() {
        this.store.dispatch(new LoadUnassignedAlertsOnDashboard(new Pageable(this.unassignedAlertPaginator.pageIndex, 4)));
        this.disableRefreshOnBlur.refreshPeriodically();
    }

    ngOnDestroy() {
        this.store.dispatch(new ResetAlertMetaAttributes());
        this.disableRefreshOnBlur.cleanup();
    }

    // endregion

    getUnassignedAlerts(pageNumber: number = this.unassignedAlertPaginator.pageIndex,
                        pageSize: number = this.unassignedAlertPaginator.pageSize) {
        this.store.dispatch(new LoadUnassignedAlertsOnDashboard(new Pageable(pageNumber, pageSize)));
    }

    // region refresh

    public refreshList(): void
    {
        this.getUnassignedAlerts();
    }

    // endregion

}
