/**
 * Created by Adrien Dos Reis on 28/09/2020
 */
import { UserSourceGenerator } from '../../../../../mys-base/src/lib/services/interfaces/UserSourceGenerator';
import { Injectable } from '@angular/core';
import { AccessRightsService } from '../access-rights/access-rights.service';
import { Driver, UserSource } from 'mys-base';

@Injectable({ providedIn: 'root' })
export class AlfredUserSourceGenerator implements UserSourceGenerator
{
    // region Constructor

    constructor(private readonly accessRights: AccessRightsService)
    {
    }

    // endregion

    getSource(): UserSource
    {
        /**
         * If the user currently logged in is a Driver, we consider the source to be Liberty Driver
         */
        if (Driver.isDriver(this.accessRights.currentUserSub.value))
        {
            return UserSource.LIBERTY_DRIVER
        }

        /**
         * B2B_ALFRED if the user currently logged in is bound to a Company.
         * Otherwise -> MYSAM
         */
        return (!!this.accessRights.companySub.value) ? UserSource.B2B_ALFRED : UserSource.MYSAM;
    }
}