// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json.old`.

export const environment = {
    production: false,
    BASE_URL: 'https://api.demo.mysam.fr',
    BASE_REGISTER_LM_URL : 'http://localhost:8081',

    /**
     * Key from jc.ricard@mysam.fr HERE Account (dev key)
     */
    hereApiKey: 'mpRP3apgMo4qTIVAMdUfdvtKI_e-X_TP21f5YwG1cJI',

    // Stripe Publishable Key
    STRIPE_PUBLIC_KEY: 'pk_test_mUI1twK00YJBwDsFCIERESiS',

    // Google Analytics (for Liberty Driver)
    LIBERTY_DRIVER_ANALYTICS_KEY: 'UA-109460926-3'
};
