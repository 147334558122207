export const ADD_DRIVER_TO_CLIENT_FAVORITES = '[DriverAutocomplete] Add driver to client favorites';

export class AddToFavorites
{
    static readonly type = ADD_DRIVER_TO_CLIENT_FAVORITES;

    constructor(public clientId: string, public driverId: string)
    {
    }
}

export const ADD_DRIVER_TO_CLIENT_BLACKLIST = '[DriverAutocomplete] Add driver to client blacklist';

export class AddToBlacklist
{
    static readonly type = ADD_DRIVER_TO_CLIENT_BLACKLIST;

    constructor(public clientId: string, public driverId: string)
    {
    }
}

export const RESET_ADD_DRIVER_TO_CLIENT_FAVORITES = '[DriverAutocomplete] Reset add driver to client favorites';

export class ResetAddToFavorites
{
    static readonly type = RESET_ADD_DRIVER_TO_CLIENT_FAVORITES;

    constructor()
    {
    }
}
