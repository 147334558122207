/**
 * Created by Adrien Dos Reis on 28/07/2022
 */
import { Injectable } from '@angular/core';
import { MysamTranslateService } from 'msl-translate';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { ApiError } from 'mys-base';

@Injectable({ providedIn: 'root' })
export class ToastrUtilService
{
    // region Constructor

    constructor(private translate: MysamTranslateService, private toastr: ToastrService)
    {
    }

    // endregion

    /**
     * Display a toaster with an errors message
     */
    public displayToastrErrorMessage(error: any)
    {
        /**
         * We perform this check in order to prevent https://mysamcab.atlassian.net/browse/MYS-3492 :
         * A bug difficult to reproduce, but might be caused by this method being called with a "non-T" errors field (probably from
         * another component, because an Observable wasn't cleaned ?)
         */
        if (error instanceof ApiError)
        {
            this.toastr.error(error.getErrorMessage(this.translate));
        }
        /**
         * In case the errors is already translated, we display it without further edition here
         */
        else if (error.constructor.name === 'String')
        {
            this.toastr.error(error);
        }
    }

    /**
     * Display a toaster with a success message
     * @param {string} message
     * @returns {ActiveToast<any>}
     */
    public displayToastrSuccessMessage(message: string): ActiveToast<any>
    {
        return this.toastr.success(this.translate.instant(message));
    }
}
