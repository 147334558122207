import { CUSTOM_ELEMENTS_SCHEMA, effect, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationModule } from '../authentication/authentication.module';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MslButtonModule } from 'msl-components';
import { MysamTranslateModule, MysamTranslateService, areAllAsyncTranslationsLoaded } from 'msl-translate';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { LdLayoutModule } from '../ld-layout/ld-layout.module';
import { AlfredLoginState } from './state/alfred-login.state';
import { ForgotPasswordModule } from "../../../../msl-login/src/forgot-password/forgot-password.module";
import { environment } from "../../../../liberty-order/src/environments/environment";
import { USER_SOURCE } from "./index";
import { Trip, UserSource } from 'mys-base';

const loginModules = [AuthenticationModule,
    LdLayoutModule, MatCardModule, MatInputModule, MatFormFieldModule, MslButtonModule, MysamTranslateModule];

const loginComponents = [LoginComponent, LogoutComponent];
const ngxsStates = [AlfredLoginState];

@NgModule({
    imports: [
        CommonModule,
        ForgotPasswordModule.forRoot(environment.BASE_URL + '/alfred'),
        FormsModule,
        ReactiveFormsModule,
        ...loginModules,
        NgxsModule.forFeature([...ngxsStates])
    ],
    declarations: [...loginComponents],
    exports: [ForgotPasswordModule, ...loginModules, ...loginComponents],

    /**
     * See https://stackoverflow.com/a/63881579 - Option 2
     * Needed to avoid Angular complaining that 'ion-icon' is not a known element.
     */
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LoginModule
{
    /**
     * This module provides translations that will only be used there ->
     * It is more convenient to have them here (and lazy loaded) instead of inside
     * one of our JSON files
     */
    constructor(translate: MysamTranslateService)
    {
        translate.setTranslation<LoginTranslations>('fr', {
                "login-form": {
                    "ALFRED-LOGIN-TITLE": "Identification",
                    "DRIVER-LOGIN-TITLE": "Connexion Chauffeur",
                    EMAIL: "Adresse email",
                    errors: {
                        "BAD-CREDENTIALS": "Email ou mot de passe incorrect",
                        DEFAULT: "Erreur serveur, contactez le support",
                        "EMAIL-REQUIRED": "Adresse email requise",
                        "INVALID-EMAIL": "Entrez un email valide",
                        "PASSWORD-REQUIRED": "Mot de passe requis",
                        "SERVER-UNREACHABLE": "Serveur injoignable, réessayez ultérieurement"
                    },
                    "forgot-password": {
                        CANCEL: "Annuler",
                        EMAIL: "Entrez votre adresse email ici",
                        errors: {
                            DEFAULT: "Une erreur est survenue lors de l'envoi de l'email de régénération du mot de passe. Réessayez plus tard.",
                            "EMAIL-NOT-FOUND": "Aucun compte chauffeur n'a été trouvé avec l'adresse email {{ email }}. Êtes-vous sûr d'avoir entré la bonne adresse ?"
                        },
                        OK: "Ok",
                        SUBMIT: "Nouveau mot de passe",
                        SUCCESS: "Un email vous a été envoyé, contenant les instructions à suivre pour générer votre nouveau mot de passe.",
                        TITLE: "Mot de passe oublié ?"
                    },
                    "LOGIN-WITH-MYSAM-CREDENTIALS": "Connectez-vous avec vos identifiants Chauffeur MySam",
                    "no-account": {
                        "NOT-REGISTERED-YET-HTML": "Pas encore de compte chauffeur&nbsp;MySam&nbsp;?",
                        "REGISTER-NOW": "Créez votre compte chauffeur"
                    },
                    PASSWORD: "Mot de passe",
                    SUBMIT: "S'identifier"
                }
            }
        );

        translate.setTranslation<LoginTranslations>('en', {
                "login-form": {
                    "ALFRED-LOGIN-TITLE": "Login form",
                    "DRIVER-LOGIN-TITLE": "Driver Login",
                    EMAIL: "Email address",
                    errors: {
                        "BAD-CREDENTIALS": "Invalid credentials",
                        DEFAULT: "Error on server-side, please try again, and contact My Sam if the error occurs again",
                        "EMAIL-REQUIRED": "Email address required",
                        "INVALID-EMAIL": "Enter a valid email address",
                        "PASSWORD-REQUIRED": "Password required",
                        "SERVER-UNREACHABLE": "Server unreachable, please try again in a few minutes"
                    },
                    "forgot-password": {
                        CANCEL: "Cancel",
                        EMAIL: "Enter your email address here",
                        errors: {
                            DEFAULT: "An error occurred while sending the \"Reset Password\" email. Try again later",
                            "EMAIL-NOT-FOUND": "No Driver account was found with the email address {{ email }}."
                        },
                        OK: "Ok",
                        SUBMIT: "Request a new password",
                        SUCCESS: "An email has been sent to your address. Follow the email instructions to change your password",
                        TITLE: "Forgot your password ?"
                    },
                    "LOGIN-WITH-MYSAM-CREDENTIALS": "Log in with your MySam Driver credentials",
                    "no-account": {
                        "NOT-REGISTERED-YET-HTML": "You don't have a MySam&nbsp;Driver account&nbsp;yet&nbsp;?",
                        "REGISTER-NOW": "Create it now"
                    },
                    PASSWORD: "Password",
                    SUBMIT: "Log in"
                }
            }
        );
    }

    static forRoot(source: UserSource): ModuleWithProviders<LoginModule>
    {
        return {
            ngModule: LoginModule,
            providers: [
                {
                    provide: USER_SOURCE,
                    useValue: source
                }
            ]
        };
    }
}

type LoginTranslations = {
    "login-form": {
        "ALFRED-LOGIN-TITLE": string,
        "DRIVER-LOGIN-TITLE": string,
        EMAIL: string,
        errors: {
            "BAD-CREDENTIALS": string,
            DEFAULT: string,
            "EMAIL-REQUIRED": string,
            "INVALID-EMAIL": string,
            "PASSWORD-REQUIRED": string,
            "SERVER-UNREACHABLE": string
        },
        "forgot-password": {
            CANCEL: string,
            EMAIL: string,
            errors: {
                DEFAULT: string,
                "EMAIL-NOT-FOUND": string
            },
            OK: string,
            SUBMIT: string,
            SUCCESS: string,
            TITLE: string
        },
        "no-account": {
            "NOT-REGISTERED-YET-HTML": string,
            "REGISTER-NOW": string
        }
        "LOGIN-WITH-MYSAM-CREDENTIALS": string,
        PASSWORD: string,
        SUBMIT: string
    }
}


