import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Driver } from 'mys-base';
import { DriverState } from '../drivers/drivers-without-components/states/driver.state';
import { LoadDriverById } from '../drivers/drivers-without-components/actions/driver.action';
import { UtilGuardNgxs } from './util-guard-ngxs';
import { Select, Store } from '@ngxs/store';
import { DriverError } from '../drivers/drivers-without-components/errors/driver.error';

@Injectable()
export class DriverDetailGuard 
{
    // region Constructor & Attributes

    @Select(DriverState.currentDriver) currentDriver$: Observable<Driver>;
    @Select(DriverState.error) driverError$: Observable<DriverError>;

    constructor(private store: Store)
    {
    }

    // endregion

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        // We need a "driverId" parameter
        const driverId: string = route.params[ 'driverId' ];

        return !!driverId && this.reloadDriver(driverId).pipe(
            switchMap((loadedSuccessfully: boolean) => of(loadedSuccessfully)),
            catchError(() => of(false))
        );
    }

    reloadDriver(driverId: string): Observable<boolean>
    {
        return UtilGuardNgxs.loadThenRedirect(this.store, () => new LoadDriverById(driverId), this.currentDriver$, this.driverError$, Driver);
    }
}
