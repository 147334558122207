import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DriverDocument } from '../../../models/driver-document';
import { Select, Store } from '@ngxs/store';
import { DriverDocumentState } from '../../../driver-documents/states/driver-document.state';
import { filter, map, take } from 'rxjs/operators';
import { GetDocumentById, ResetDriverDocument } from '../../../driver-documents/actions/driver-documents.action';
import { Constants } from '../../../models/constants/constants';
import { Observable } from 'rxjs';
import { ModalDocumentImageDialogComponent } from '../../../driver-documents/components/modal-document-image-dialog/modal-document-image-dialog.component';
import { Driver } from 'mys-base';

@Component({
    selector: 'mys-modal-image-thumbnail[driver][document]',
    templateUrl: './modal-image-thumbnail.component.html'
})
export class ModalImageThumbnailComponent
{
    // region Inputs

    @Input() driver: Driver;
    @Input() document: DriverDocument;

    // endregion

    // region Attributes

    @Select(DriverDocumentState.driverDocument) driverDocument$: Observable<DriverDocument>;

    Constants = Constants;

    // endregion

    // region Constructor

    constructor(private dialog: MatDialog, private store: Store)
    {
    }

    // endregion

    // region Events

    openDialog(): void
    {
        /**
         * This subscription aims to reload the current DriverDocument when the dialog is opened
         * More details on comments below
         */
        const subscription = this.driverDocument$.pipe(
                filter(loaded => !!loaded),
                map(driverDocument =>
                {
                    this.dialog.open(
                        ModalDocumentImageDialogComponent,
                        {
                            panelClass: [ 'driver-document-modal' ],
                            data: {
                                driver: this.driver,
                                driverDocument: driverDocument
                            }
                        })
                        .afterClosed()
                        .subscribe(() =>
                        {
                            /**
                             * If we open one image and close it, when clicking on a second image, the first image will be displayed.
                             * That's why we have to reset the current DriverDocument in states
                             */
                            this.store.dispatch(new ResetDriverDocument());
                            subscription.unsubscribe();
                        });
                }),
                /**
                 * Need to use take(1), because, for no reason the map is triggered 2 times and open 2 popups.
                 * take(1) close the subscription stream after the first subscription.add
                 */
                take(1)
            ).subscribe();

        /**
         * MYS-3449 : due to performance issues, we first load all DriverDocument as thumbnails
         * When opening a DriverDocument, reload it in order to get the full image
         */
        this.store.dispatch(new GetDocumentById(this.document.id));
    }

    // endregion
}
