import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AlfredState } from '../store/reducers';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadClientById } from '../store/actions';
import { getClientError, getCurrentClient } from '../store/selectors';
import { UtilGuard } from './util-guard';
import { Client } from 'mys-base';

@Injectable()
export class ClientDetailGuard 
{
    constructor(private store: Store<AlfredState>)
    {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        // We need a "clientId" parameter
        const clientId: string = route.params[ 'clientId' ];

        return !!clientId && this.reloadClient(clientId).pipe(
            switchMap((loadedSuccessfully: boolean) => of(loadedSuccessfully)),
            catchError(() => of(false))
        );
    }

    reloadClient(clientId: string): Observable<boolean>
    {
        return UtilGuard.loadThenRedirect(
            this.store, () => new LoadClientById(clientId), getCurrentClient, getClientError, Client
        );
    }
}
