import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatingActionButtonComponent } from './components/floating-action-button/floating-action-button.component';
import { MslButtonModule } from 'msl-components';
import { MatIconModule } from '@angular/material/icon';

const components = [ FloatingActionButtonComponent ];

@NgModule({
    declarations: [ ...components, FloatingActionButtonComponent ],
    imports: [
        CommonModule,
        MslButtonModule,
        MatIconModule
    ],
    exports: [ ...components ]
})
export class FloatingActionButtonModule
{
}
