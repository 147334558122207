<div class="layout-container" [ngClass]="layout()">
    <!-- top navbar-->
    <app-header></app-header>
    <!-- sidebar-->
    <mys-sidebar></mys-sidebar>
    <div (click)="closeSidebar()" class="sidebar-layout-obfuscator"></div>
    <!-- Main section-->
    <main class="main-container">
        <!-- Page content-->
        <section>
            <router-outlet></router-outlet>
        </section>
        <!-- Page footer-->
        <footer class="row w-100">
            <div class="col" *ngIf="!isLibertyDriver">{{ 'footer.TITLE' | mysamTranslate }}</div>
            <div class="col" *ngIf="isLibertyDriver">{{ 'footer.LIBERTY-DRIVER' | mysamTranslate }}</div>
            <div class="col-auto">
                <div>{{ 'footer.CONTACT-US' | mysamTranslate }}</div>
                <div>{{ 'footer.EMAIL' | mysamTranslate }}<a [href]="mailtoLink">{{ mailAddress }}</a></div>
                <div>{{ 'footer.PHONE' | mysamTranslate }}05.32.62.94.62</div>
            </div>
        </footer>
    </main>
</div>
