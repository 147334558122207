import { Alert } from "../../../../../mys-base/src/lib/models/alert";
import { TripOperator } from "../../../../../mys-base/src/lib/models/trip-operator";
import { MySamPair } from "mys-base";
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Created by Sandra Bénard on 02/08/2022
 */

export const BIND_OPERATOR_TO_TRIP = '[TripOperatorBinding] Bind operator to trip';
export const BIND_OPERATOR_TO_TRIP_AND_ASSIGN_ALERTS_SUCCESS = '[TripOperatorBinding & Alerts] SUCCESS - Bind operator to trip and Assign Alerts';
export const BIND_OPERATOR_TO_TRIP_AND_ASSIGN_ALERTS_ERROR = '[TripOperatorBinding & Alerts] ERROR - Bind operator to trip and Assign Alerts';
export const RESET_BIND_OPERATOR_TO_TRIP_AND_ASSIGN_ALERTS = '[TripOperatorBinding & Alerts] Reset';

/**
 * Bind operator to trip
 */
export class BindOperatorToTrip
{
    static readonly type = BIND_OPERATOR_TO_TRIP;

    constructor(public tripId: number, public isAnotherOperatorAlreadyBound: boolean,
                public bindOperatorToAlertsToo: boolean = false)
    {
    }
}

/**
 * Transversal success action, called in two scenarios :
 * - When an operator is successfully bound to a trip
 * - When an alert is successfully assigned to an operator
 *
 * Those two scenarios might update BOTH the TripOperatorBindingState (w/ a new TripOperator) and the AlertState (adding
 * new assigned alerts to the current user
 *
 * That's why we defined a dedicated action (that should only be called from either one of those two states)
 */
export class BindOperatorToTripAndAssignAlertsSuccess
{
    static readonly type = BIND_OPERATOR_TO_TRIP_AND_ASSIGN_ALERTS_SUCCESS;

    constructor(public payload: MySamPair<TripOperator | null, Alert[]>)
    {
    }
}

/**
 * Another transversal action, but for the error scenarios above
 */
export class BindOperatorToTripAndAssignAlertsError
{
    static readonly type = BIND_OPERATOR_TO_TRIP_AND_ASSIGN_ALERTS_ERROR;

    constructor(public httpErrorResponse: HttpErrorResponse)
    {
    }
}

/**
 * And again another transversal action, allowing to reset both states at once
 */
export class ResetBindOperatorToTripAndAssignAlertsStates
{
    static readonly type = RESET_BIND_OPERATOR_TO_TRIP_AND_ASSIGN_ALERTS;
}
