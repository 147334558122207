import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CurrentUserUtils } from '../../../../../msl-login/src/lib/utils/current-user-utils';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { AlfredUser } from 'mys-base';
import { AlfredLoginState } from '../../login/state/alfred-login.state';

/**
 * Updated on 14/08/2019
 * This Guard ensures that the current user is loaded, and that he is a "paying" user
 * (either an AlfredUser or a Paying Driver)
 *
 * This class does NOT need to extend CurrentUserLoadedGuard, since it is already defined as the
 * ProtectedGuard in AuthenticationModule
 */
@Injectable({ providedIn: 'root' })
export class CurrentUserIsPayingGuard 
{
    // region Constructor & Attributes

    @Select(AlfredLoginState.user) currentUser$: Observable<AlfredUser>;

    constructor(protected store: Store)
    {
    }

    // endregion

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    {
        /**
         * CurrentUserLoadedGuard ensures that "currentUser$" will be loaded
         */
        return this.currentUser$.pipe(
            /**
             * If the user is NOT a free user, we let him through
             */
            map(user => !CurrentUserUtils.isRoleFreeDriver(user.authorities)),
            tap((isRolePayingDriver: boolean) =>
            {
                /**
                 * Otherwise, we redirect him to our Subscription module
                 * URL updated on 09/03/2023 - https://mysamcab.atlassian.net/browse/MYS-5301
                 * We don't use our "store.dispatch" here, because we need to move away from the current
                 * Angular app (Alfred/LD), to go to a different URL
                 */
                if (!isRolePayingDriver)
                {
                    window.location.href = 'https://register.libertymobile.fr';
                }
            }),
            take(1)
        );
    }
}
