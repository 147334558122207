import * as clientsReducers from './clients/clients.reducer';
import * as clientReducers from './clients/client.reducer';
import * as usersReducers from './users.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export interface AlfredState
{
    clients: clientsReducers.ClientState;
    currentClient: clientReducers.ClientState;
    users: usersReducers.UsersState;
}

export const reducers: ActionReducerMap<AlfredState> = {
    clients: clientsReducers.reducer,
    currentClient: clientReducers.reducer,
    users: usersReducers.reducer
};

export const getAlfredState = createFeatureSelector<AlfredState>('alfred');
