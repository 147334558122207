<!--
      This component contains 2 SWALs :
      - a Swal allowing to enter an email and request a new password
      - a Swal confirming that the email has been successfully sent
-->
<swal #swalForgotPassword
      [title]="'login-form.forgot-password.TITLE' | translate"
      [showCancelButton]="true" [showLoaderOnConfirm]="true"
      [allowOutsideClick]="false"
      [cancelButtonText]="'login-form.forgot-password.CANCEL' | mysamTranslate"
      [confirmButtonText]="'login-form.forgot-password.SUBMIT' | mysamTranslate"
      [preConfirm]="requestNewPasswordToken.bind(this)">
    <div *swalPortal>
        <div class="col-12 col-sm-8 offset-sm-2">
            <mys-email-field (emailCtrl)="emailCtrl = $event" [defaultEmail]="defaultEmail"
                             [placeholderKey]="'login-form.forgot-password.EMAIL'"></mys-email-field>

            <!-- Error displayed if the Server invalidated our request (User not found on backend-side ?) -->
            <mat-error *ngIf="emailCtrl.valid && (forgotRequestError$ | async) as error">{{ error }}</mat-error>
        </div>
    </div>
</swal>

<!-- Swal displayed when the "New Password Token" has been sent -->
<swal #swalEmailSuccessfullySent [icon]="'success'"
      [text]="'login-form.forgot-password.SUCCESS' | mysamTranslate"
      [confirmButtonText]="'login-form.forgot-password.OK' | mysamTranslate"></swal>
