import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'mys-detail-menu',
    templateUrl: './detail-menu.component.html',
    styleUrls: [ '../../../app/shared/styles/mysam/mysam.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailMenuComponent
{
    @Input() title: string;
    @Input() displayCaret: boolean;

    stopEventPropagation($event)
    {
        $event.stopPropagation();
    }
}
