<mys-detail-item
    [ionIcon]="'location-sharp'"
    [titleKey]="titleKey"
    [iconColorClass]="iconColorClass"
    [isEditable]="isEditable"
    [validatorFn]="validatorFn.bind(this)"
    (contentWhileEditingChange)="updateAddressField.emit(autocompleteAddress)">
    <ng-content></ng-content>
    <!-- In order to be able to modify this address without duplicating code, we use "ng-content"
    (angular doc: https://angular.io/guide/content-projection#multi-slot-content-projection) :
    Here we define an "addressFieldAutocomplete" tag as well as the content we want to insert in mys-detail-item -->
    <msl-address-autocomplete #autocompleteAddress displayInEditMode
                              [values]="values"
                              [errorFieldBlank]="errorFieldBlank"
                              [errorNoValueSelected]="'errors.my-sam-errors.NO-ADDRESS-SELECTED'"
                              (inputChangedAndDebounced)="autocompleteAddresses($event)">
    </msl-address-autocomplete>
</mys-detail-item>
