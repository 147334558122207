<div class="my-2 p0">

    <!-- List item icon -->
    <div class='me-3 me-md-4 d-flex justify-content-center my-0'  style="width: 40px; max-width: 40px">
        <mys-icon [colorFolder]="colorFolder" [size]="size" [imageName]="imageName"></mys-icon>
    </div>

    <!-- Content to display -->
    <div>
        <div>
            {{ content }}
        </div>

        <!-- Content description -->
        <div class="text-muted">{{ title$ | async }}</div>
    </div>
</div>
