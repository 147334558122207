import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Select, Store } from "@ngxs/store";
import { AlertState } from "../../state/alert.state";
import { Observable } from "rxjs";
import { GetAssignedAlerts, GetUnassignedAlerts } from "../../actions/alert.action";
import { Alert } from "projects/mys-base/src/lib/models/alert";
import { MatTab } from "@angular/material/tabs";
import { Page } from '../../../../../../msl-driver-registration/src/lib/http/responses/pagination/page';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Pageable } from "../../../../../../msl-driver-registration/src/lib/http/responses/pagination/pageable";
import { DisableRefreshOnBlur } from "../../../utils/disable-refresh-on-blur";
import {
    ResetBindOperatorToTripAndAssignAlertsStates
} from '../../../trip-operator-binding/actions/trip-operator-binding.action';

@Component({
    selector: 'mys-alerts-with-pagination',
    templateUrl: './alerts-with-pagination.component.html',
    styleUrls: ['./alerts-with-pagination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertsWithPaginationComponent implements AfterViewInit, OnDestroy
{

    // region Attributes

    @ViewChild('assignedAlertTab', { static: false }) assignedAlertTab: MatTab;
    @ViewChild('unassignedAlertPaginator', { static: false }) unassignedAlertPaginator: MatPaginator;

    // region Selectors

    @Select(AlertState.unassignedAlerts) unassignedAlerts$: Observable<Page<Alert>>;
    @Select(AlertState.assignAlerts) assignedAlerts$: Observable<Alert[]>;

    // endregion

    /**
     * This object handles the periodic refresh of the unassigned alerts count, and auto-disables the refresh if the tab is
     * blurred
     */
    disableRefreshOnBlur = new DisableRefreshOnBlur(
        this.renderer, 30000, this.getUnassignedAlerts.bind(this));

    // endregion

    // region Constructor

    constructor(private store: Store, private readonly renderer: Renderer2)
    {
    }

    // endregion

    // region Lifecycle

    ngAfterViewInit(): void
    {
        /**
         * When creating this Component, we want to force a "selected tab change" on the first element. Even though
         * the index 0 is already selected, forcing this would allow to trigger the loading of the alerts (and the
         * setup of our periodic refresh)
         */
        this.onSelectedTabChanged(0);
    }

    ngOnDestroy()
    {
        // Stop the auto refresh and reset the alert state when the notification was closed
        this.disableRefreshOnBlur.cleanup();
        this.store.dispatch(new ResetBindOperatorToTripAndAssignAlertsStates());
    }

    // endregion

    // region Check tab

    /**
     * Checks when the tab is changed
     */
    onSelectedTabChanged(index: number)
    {
        switch (index)
        {
            /**
             * On the index 0 (unassigned alerts), we refresh the unassigned alerts, and we set up a periodic refresh
             */
            case 0:
                this.getUnassignedAlerts();
                this.disableRefreshOnBlur.refreshPeriodically();
                break;

            /**
             * On the index 1 (assigned alerts), we refresh the assigned alerts.
             */
            case 1:
                this.store.dispatch(new GetAssignedAlerts());
                break;
        }
    }

    // endregion

    // region Paginator and Refresh data

    changePage(event: PageEvent)
    {
        this.getUnassignedAlerts(event.pageIndex, event.pageSize);
    }

    getUnassignedAlerts(pageNumber: number = this.unassignedAlertPaginator.pageIndex,
                        pageSize: number = this.unassignedAlertPaginator.pageSize)
    {
        this.store.dispatch(new GetUnassignedAlerts(new Pageable(pageNumber, pageSize)));
    }

    // endregion
}
