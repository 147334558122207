import { ApiService } from 'mys-base';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Driver } from '../../../../../mys-base/src/lib/models/users/driver';
import { WorkingAreasError } from '../errors/working-areas.error';

/**
 * Created by Aurélien Rieu on 06/06/2019
 */
@Injectable({ providedIn: 'root' })
export class WorkingAreasService extends ApiService
{
    private WORKING_AREAS_BASE_URL = this.BASE_URL + '/alfred/working-areas/driver/';

    /**
     * Update the WorkingAreas of the Driver, with the AdministrativeAreas associated to the administrativeAreaIdList list
     *
     * @param {string} driverId The Id of the Driver we are looking for
     * @param {number[]} administrativeAreaIds The list of AdministrativeArea's Ids to associate to the Driver
     * @returns {Observable<Driver>} The Driver updated
     */
    public updateDriverWorkingAreas(driverId: string, administrativeAreaIds: number[]): Observable<Driver>
    {
        return this.httpClient.post<Driver>(`${this.WORKING_AREAS_BASE_URL}${driverId}`, administrativeAreaIds).pipe(
            // Enforcing the return type here
            map((driver) => Driver.assign(driver))
        );
    }

    /**
     * Error builder for the services
     *
     * @param {HttpErrorResponse} err The errors to cast in WorkingAreasError
     * @returns {WorkingAreasError} An errors typed as WorkingAreasError
     */
    public buildWorkingAreasError(err: HttpErrorResponse): WorkingAreasError
    {
        const error = super.createApiError(err);
        return new WorkingAreasError(error);
    }
}
