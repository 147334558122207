import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AlfredUser, ConnectableUser, Driver } from 'mys-base';
import { Injectable } from '@angular/core';
import { loginInitialState, LogoutAction, MslLoginState, MslLoginStateModel, OAuthToken } from 'msl-login';
import { Navigate } from '@ngxs/router-plugin';
import { Observable } from 'rxjs';

@State<MslLoginStateModel>({
    name: 'alfredLoginState',
    defaults: loginInitialState
})
@Injectable()
export class AlfredLoginState extends MslLoginState<AlfredUser>
{
    // region Selectors

    @Selector()
    static user(state: MslLoginStateModel): AlfredUser | null
    {
        return state.oauthTokenMetadata?.oauthToken?.user as AlfredUser;
    }

    /**
     * If the user currently connected is a Driver we cast the
     * connectable user retrieve as Driver.
     * Otherwise, is null
     */
    @Selector()
    static driver(state: MslLoginStateModel): Driver | null
    {
        return (state.oauthTokenMetadata.isDriver) ? state.oauthTokenMetadata?.oauthToken?.user as Driver : null;
    }

    // region Parent Selectors

    /**
     * Needed, otherwise our State won't work properly...
     */

    @Selector()
    static oauthToken(state: MslLoginStateModel): OAuthToken<AlfredUser> | null
    {
        return super.oauthToken(state) as OAuthToken<AlfredUser>;
    }

    @Selector()
    static isMySam(state: MslLoginStateModel): boolean | null
    {
        return super.isMySam(state);
    }

    @Selector()
    static isDriver(state: MslLoginStateModel): boolean | null
    {
        return super.isDriver(state);
    }

    @Selector()
    static isSupervisor(state: MslLoginStateModel): boolean | null
    {
        return super.isSupervisor(state);
    }

    // endregion Parent Selectors

    // endregion Selectors

    // region Actions

    @Action(LogoutAction)
    logout(ctx: StateContext<MslLoginStateModel>, _: LogoutAction): Observable<void>
    {
        this.doLogout(ctx);
        return ctx.dispatch(new Navigate([ '/login' ]));
    }

    // endregion

    // region Overridden methods

    protected castUserIntoSubclass(user: ConnectableUser): AlfredUser
    {
        return AlfredUser.assign(user);
    }

    // endregion
}
