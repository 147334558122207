import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, Discussion } from 'mys-base';
import { environment } from '../../../environments/environment';
import { DiscussionCreateRequest } from '../../http/requests/discussion.create.request';
import { map } from 'rxjs/operators';

@Injectable()
export class DiscussionsService extends ApiService
{
    /**
     * Sends a DiscussionCreateRequest object to the server, to create it and bind it to its user
     * @param {DiscussionCreateRequest} discussion
     * @return {Observable<Discussion>} The Discussion newly created
     */
    public createNewDiscussion(discussion: DiscussionCreateRequest): Observable<Discussion>
    {
        return this.httpClient.post<Discussion>(environment.BASE_URL + '/alfred/discussions', discussion).pipe(
            // Enforcing the return type here
            map((discu: any) => Object.assign(new Discussion(), discu))
        );
    }
}
