import { Trip } from 'mys-base';
import { TripChecker } from '../../models/checkers/trip-checker';
import * as appealUpdateActions from '../actions/trip.appeal.update.action';
import * as cancelActions from '../actions/trip.cancel.action';
import * as releaseActions from '../actions/trip.release.action';
import * as fromActions from '../actions/trip.action';
import * as tripCheckerActions from '../actions/trip.checker.action';
import * as notifyAvailableDriversActions from '../actions/trip.notify.available.drivers.action';
import * as startActions from '../actions/trip.start.action';
import * as stopActions from '../actions/trip.stop.action';

export interface TripState
{
    loaded: boolean;
    loading: boolean;
    addressEdited: boolean;
    driversNotified: boolean;
    notifyingDrivers: boolean;
    tripEdited: boolean;
    tripEditing: boolean;
    currentTrip: Trip;
    currentTripChecker: TripChecker;
    error: any;
}

export const initialState: TripState = {
    loaded: false,
    loading: false,
    addressEdited: false,
    driversNotified: false,
    notifyingDrivers: false,
    tripEdited: false,
    tripEditing: false,
    currentTrip: null,
    currentTripChecker: null,
    error: null
};

export function reducer(state: TripState = initialState,
                        action:
                            appealUpdateActions.TripAppealUpdateAction |
                            fromActions.TripAction |
                            cancelActions.CancelTripAction |
                            releaseActions.ReleaseTripAction |
                            tripCheckerActions.TripCheckerAction |
                            notifyAvailableDriversActions.TripNotifyAvailableDriversAction |
                            startActions.StartTripAction |
                            stopActions.StopTripAction): TripState
{
    switch (action.type)
    {
        case fromActions.LOAD_TRIP_BY_ID:
        {
            return {
                ...state,
                loading: true,
                loaded: false,
                /* We need to clear the currentTrip each time we want to load another Trip
                 * to avoid a load conflict in the trip-detail.guard */
                currentTrip: null,
                error: null
            };
        }

        case fromActions.LOAD_TRIP_BY_ID_SUCCESS:
        {
            return {
                ...state,
                loading: false,
                loaded: true,
                currentTrip: action.payload,
                error: null
            };
        }

        case fromActions.LOAD_TRIP_BY_ID_FAIL:
        {
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            };
        }

        case fromActions.EDIT_TRIP:
        case appealUpdateActions.TRIP_APPEAL_UPDATE:
        case cancelActions.CANCEL_TRIP:
        case releaseActions.RELEASE_TRIP:
        case startActions.START_TRIP:
        case stopActions.STOP_TRIP:
        {
            return {
                ...state,
                tripEdited: false,
                tripEditing: true,
                error: null
            };
        }

        case fromActions.EDIT_TRIP_SUCCESS:
        case appealUpdateActions.TRIP_APPEAL_UPDATE_SUCCESS:
        case cancelActions.CANCEL_TRIP_SUCCESS:
        case releaseActions.RELEASE_TRIP_SUCCESS:
        case startActions.START_TRIP_SUCCESS:
        case stopActions.STOP_TRIP_SUCCESS:
        {
            return {
                ...state,
                tripEdited: true,
                tripEditing: false,
                currentTrip: action.payload,
                error: null
            };
        }

        case fromActions.EDIT_TRIP_FAIL:
        case appealUpdateActions.TRIP_APPEAL_UPDATE_FAIL:
        case cancelActions.CANCEL_TRIP_FAIL:
        case releaseActions.RELEASE_TRIP_FAIL:
        case startActions.START_TRIP_FAIL:
        case stopActions.STOP_TRIP_FAIL:
        {
            return {
                ...state,
                tripEdited: false,
                tripEditing: false,
                error: action.payload
            };
        }

        case fromActions.EDIT_TRIP_ADDRESS_SUCCESS:
        {
            return {
                ...state,
                addressEdited: true,
                tripEdited: true,
                tripEditing: false,
                currentTrip: action.payload,
                error: null
            };
        }

        case fromActions.RESET_TRIP_ADDRESS_EDITED:
        {
            return {
                ...state,
                currentTrip: state.currentTrip,
                addressEdited: false,
            };
        }

        case notifyAvailableDriversActions.TRIP_NOTIFIY_AVAILABLE_DRIVERS:
        {
            return {
                ...state,
                driversNotified: false,
                notifyingDrivers: true,
                error: null
            };
        }

        case notifyAvailableDriversActions.TRIP_NOTIFIY_AVAILABLE_DRIVERS_SUCCESS:
        {
            return {
                ...state,
                driversNotified: true,
                notifyingDrivers: false,
                error: null
            };
        }

        case notifyAvailableDriversActions.TRIP_NOTIFIY_AVAILABLE_DRIVERS_FAIL:
        {
            return {
                ...state,
                driversNotified: false,
                notifyingDrivers: false,
                error: action.payload
            };
        }

        case tripCheckerActions.LOAD_TRIP_CHECKER_BY_TRIP_ID:
        {
            return {
                ...state,
                currentTripChecker: null,
                error: null
            };
        }

        case tripCheckerActions.LOAD_TRIP_CHECKER_BY_TRIP_ID_SUCCESS:
        {
            return {
                ...state,
                currentTripChecker: action.payload,
                error: null
            };
        }

        case tripCheckerActions.LOAD_TRIP_CHECKER_BY_TRIP_ID_FAIL:
        {
            return {
                ...state,
                currentTripChecker: null,
                error: action.payload
            };
        }

        case fromActions.SYNCHRONIZE_TRIP_BETWEEN_STORES:
        {
            return {
                ...state,
                currentTrip: action.payload
            };
        }

        // We reset all the attributes but we keep the current trip and its checker
        case fromActions.RESET_META_ATTRIBUTES_AND_KEEP_TRIP:
        {
            return { ...initialState, currentTrip: state.currentTrip, currentTripChecker: state.currentTripChecker };
        }

        case fromActions.RESET_TRIP:
        {
            return { ...initialState }
        }
    }

    return state;
}

export const getNotifyingDriversFromTripReducer = (state: TripState) => state.notifyingDrivers;
export const getEditedFromTripReducer = (state: TripState) => state.tripEdited;
export const getEditingFromTripReducer = (state: TripState) => state.tripEditing;
export const getCurrentTripFromTripReducer = (state: TripState) => state.currentTrip;
export const getCurrentTripCheckerFromTripReducer = (state: TripState) => state.currentTripChecker;
export const getErrorFromTripReducer = (state: TripState) => state.error;
export const getEditedTripAddressFromTripReducer = (state: TripState) => state.addressEdited;
