import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Alert, AlertPriority } from "mys-base";
import { Select, Store } from "@ngxs/store";
import { BindOperatorToAlert, ResolveAlert } from "../../actions/alert.action";
import { Observable, Subscription } from "rxjs";
import { AlertState } from "../../state/alert.state";
import { MysamTranslateService } from "msl-translate";
import { OpenOnNewTab } from "../../../utils/open-on-new-tab";
import { map, tap } from "rxjs/operators";

@Component({
    selector: 'mys-alert-row[alert]',
    templateUrl: './alert-row.component.html',
    styleUrls: ['./alert-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertRowComponent implements OnInit
{

    // region Attributes

    @Input() alert: Alert;

    // region Selector

    /**
     * Parameterized selectors
     */
    isAlertResolving$: Observable<boolean>;
    isAlertSuccessfullyResolved$: Observable<boolean>;
    isCurrentAlertBeingBound$: Observable<boolean>;
    isAlertSuccessfullyBound$: Observable<boolean>;

    @Select(AlertState.isAlertResolveError) isAlertResolveError$: Observable<boolean>;
    @Select(AlertState.isAlertBindError) isAlertBindError$: Observable<boolean>;

    // endregion

    subscription: Subscription = new Subscription();

    // endregion

    // region constructor

    constructor(private store: Store, private translate: MysamTranslateService)
    {
    }

    // endregion

    // region Lifecycle

    ngOnInit()
    {
        /**
         * Using the "AlertState.isAlertBeingBoundById", which takes a "number" parameter.
         * This parameter is passed using the "map" operator, and calling the "fn" function (which represents the
         * function returned by the "AlertState.isAlertBeingBoundById" selector
         */
        this.isCurrentAlertBeingBound$ = this.store.select(AlertState.isAlertBeingBoundById)
            .pipe(map(fn => fn(this.alert.id)));

        this.isAlertSuccessfullyBound$ = this.store.select(AlertState.isAlertSuccessfullyBoundById)
            .pipe(map(fn => fn(this.alert.id)));

        this.isAlertResolving$ = this.store.select(AlertState.isAlertResolvingById)
            .pipe(map(fn => fn(this.alert.id)));

        this.isAlertSuccessfullyResolved$ = this.store.select(AlertState.isAlertSuccessfullyResolvedById)
            .pipe(map(fn => fn(this.alert.id)));
    }

    // endregion

    // region Display & UI

    getBackgroundColorPriority(priority: AlertPriority)
    {
        switch (priority.toString())
        {
            case "RED":
                return 'highPriority';
            case "ORANGE":
                return 'mediumPriority';
            case "WHITE":
                return 'lowPriority';
        }
    }

    /**
     * Returns the difference between the alert creation date and the current date,
     * and based on the value of "deltaInMinutes", we will display the most appropriate message :
     *
     * 0 minutes --> "A few moments ago"
     * Between 1 and 59 mn --> "XX minutes ago"
     * Between 1h and 24h --> "XX hours ago"
     * Over 24h --> ""XX days ago"
     */
    getDeltaBetweenCreationToNow(): string
    {
        let currentDate = new Date(); // today's date

        let deltaInMinutes = Math.round((currentDate.getTime() - new Date(this.alert.creationDate).getTime()) / 60 / 1000);
        let deltaInHours = Math.round(deltaInMinutes / 60);
        let deltaInDays = Math.round(deltaInHours / 24);

        let createdAgo = this.translate.instant("attributes.CREATED-AGO");

        return deltaInDays > 0 ? createdAgo + deltaInDays + " " + this.translate.instant('date-and-time.DAYS').toLowerCase() :
            deltaInHours < 25 && deltaInHours > 0 ? createdAgo + deltaInHours + " " + this.translate.instant('date-and-time.HOURS').toLowerCase() :
                deltaInMinutes > 0 && deltaInMinutes < 60 ? createdAgo + deltaInMinutes + " " + this.translate.instant('date-and-time.MINUTES').toLowerCase() :
                    createdAgo + ' ' + this.translate.instant('labels.FEW-MOMENTS-AGO');
    }

    // endregion

    // region UI Actions

    /**
     * Bind the alert clicked to the current operator
     */
    bindAlertToOperator()
    {
        // mark the alert as being updated
        this.store.dispatch(new BindOperatorToAlert(this.alert.id));
    }

    /**
     * Mark the alert clicked as resolved
     */
    resolveAlert()
    {
        // mark the alert as being updated
        this.store.dispatch(new ResolveAlert(this.alert.id));
    }

    // endregion

    // region Navigation

    openOnNewTab(url: string)
    {
        OpenOnNewTab.openUrlOnNewTab(url);
    }

    // endregion
}
