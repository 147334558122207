import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Client, Company } from 'mys-base';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UserAutocompleteComponent } from '../../user-autocomplete/user-autocomplete.component';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { ClientsState } from '../state/clients.state';
import { GetClientsAction, ResetClientsStateAction } from '../actions/clients.action';

@Component({
    selector: 'mys-client-autocomplete',
    templateUrl: './client-autocomplete.component.html'
})
export class ClientAutocompleteComponent implements AfterViewInit, OnDestroy
{
    // region Attributes

    @ViewChild('clientAutocomplete') clientAutocomplete: UserAutocompleteComponent;

    @Select(ClientsState.clients) clients$: Observable<Client[]>;

    @Input() label: string = 'search-client.SEARCH-HERE';
    @Input() minCharsBeforeSearching = 4;
    @Input() company: Company | null = null; // Company to filter the search on
    @Output() clientSelected = new EventEmitter<MatAutocompleteSelectedEvent>();
    @Output() clientCleared = new EventEmitter<void>();

    // endregion

    // region Constructor

    constructor(private store: Store) {}

    // endregion

    /**
     * Searches any clients matching the given term
     * @param term
     */
    searchClients(term: string)
    {
        this.store.dispatch(new GetClientsAction(term, this.company));
    }

    // region Lifecycle

    ngAfterViewInit()
    {
        this.clientAutocomplete.autocomplete.errorNoValueSelected = 'search-client.NO-VALUE-SELECTED';
    }

    ngOnDestroy(): void
    {
        this.store.dispatch(new ResetClientsStateAction());
    }

    // endregion
}
