import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { Alert, MySamPair, TripOperator } from 'mys-base';
import { Page } from "../../../../../msl-driver-registration/src/lib/http/responses/pagination/page";
import { map } from "rxjs/operators";
import { Pageable } from "../../../../../msl-driver-registration/src/lib/http/responses/pagination/pageable";
import {
    AbstractTripOperatorBindingAlertAssignationService
} from '../../trip-operator-binding/services/abstract-trip-operator-binding-alert-assignation.service';

/**
 * Created by Sandra Bénard on 18/10/2022
 */

@Injectable({ providedIn: 'root' })
export class AlertService extends AbstractTripOperatorBindingAlertAssignationService
{
    private ALERT_BASE_URL = environment.BASE_URL + '/alfred/alert';

    // region Get alerts

    /**
     * Get assigned alert of the current operator
     */
    public getAssignedAlerts(): Observable<Alert[]>
    {
        return this.httpClient.get<Alert[]>(this.ALERT_BASE_URL + '/alerts-by-operator');
    }

    /**
     * Get unassigned Alerts
     */
    public getUnassignedAlerts(pageable: Pageable): Observable<Page<Alert>>
    {
        return this.httpClient.get<Page<Alert>>(this.ALERT_BASE_URL + '/page-unassigned-alerts?page=' + pageable.page + '&size=' + pageable.size)
            .pipe(
                // Enforcing the type of the page here
                map((page: Page<Alert>) =>
                {
                    page = Object.assign(new Page<Alert>(), page);
                    page.content = page.content.map(alert => Object.assign(new Alert(), alert));
                    return page;
                }));
    }

    /**
     * Get unassigned alerts by the tripId
     * @param tripId
     */
    public getUnassignedAlertsByTrip(tripId: number): Observable<Alert[]>
    {
        return this.httpClient.get<Alert[]>(this.ALERT_BASE_URL + '/unassigned-alerts-by-trip/' + tripId)
            .pipe(
                map((alerts: Alert[]) => alerts.map(alert => Object.assign(new Alert(), alert)))
            );
    }

    // endregion

    // region Update status of alert

    public bindOperatorToAlert(alertId: number, shouldBindOperatorToAllExistingAlerts: boolean): Observable<MySamPair<TripOperator | null, Alert[]>>
    {
        return this.post(this.ALERT_BASE_URL + '/bind-alert/' + alertId + '/' + shouldBindOperatorToAllExistingAlerts);
    }

    public resolveAlert(alertId: number): Observable<MySamPair<TripOperator | null, Alert[]>>
    {
        return this.httpClient.put<MySamPair<TripOperator | null, Alert[]>>(this.ALERT_BASE_URL + '/resolve/' + alertId, null);
    }

    // endregion
}
