import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AlfredState } from '../store/reducers';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CurrentUserIsPayingGuard } from '../../../../msl-driver-registration/src/lib/authentication/guards/current-paying-user-guard.service';
import { AccessRightsService } from '../../../../msl-driver-registration/src/lib/authentication/access-rights/access-rights.service';
import { AbstractBoundToGuard } from './abstract-bound-to.guard';

/**
 * Created by Adrien Dos Reis on 14/12/2018
 * This guard prevents some routes specific for Company users to be accessed by MySam Users
 */
@Injectable({ providedIn: 'root' })
export class BoundToCompanyGuard extends AbstractBoundToGuard 
{
    // region Constructor

    constructor(store: Store<AlfredState>, currentUserLoadedAndPayingGuard: CurrentUserIsPayingGuard,
                accessRights: AccessRightsService)
    {
        super(store, currentUserLoadedAndPayingGuard, accessRights)
    }

    // endregion

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    {
        return this.shouldActivate(route, state, false)
    }
}
