import { Injectable } from '@angular/core';
import {
    LOAD_CLIENTS,
    LOAD_CLIENTS_CSV,
    LoadClients,
    LoadClientsCsv,
    LoadClientsCsvFail,
    LoadClientsCsvSuccess,
    LoadClientsFail,
    LoadClientsSuccess
} from '../../actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Page } from '../../../../../../msl-driver-registration/src/lib/http/responses/pagination/page';
import { ClientService } from '../../../services';
import { Client } from 'mys-base';

@Injectable()
export class ClientsEffect
{
    constructor(private actions$: Actions, private clientService: ClientService)
    {
    }

    loadClients$ = createEffect(() =>
        this.actions$.pipe(ofType<LoadClients>(LOAD_CLIENTS),
            map((action: LoadClients) => action.payload),
            switchMap(filter => this.clientService.getClients(filter).pipe(
                map((clients: Page<Client>) => new LoadClientsSuccess(clients)),
                catchError((error: any) => of(new LoadClientsFail(error)))
            ))
        )
    );

    loadClientsCSv$ = createEffect(() =>
        this.actions$.pipe(ofType<LoadClientsCsv>(LOAD_CLIENTS_CSV),
            map((action: LoadClientsCsv) => action.payload),
            switchMap(filter => this.clientService.getClientsCsv(filter).pipe(
                map((data: Blob) => new LoadClientsCsvSuccess(data)),
                catchError((error: any) => of(new LoadClientsCsvFail(error)))
            ))
        )
    );
}
