import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { MysamTranslateService } from 'msl-translate';

/**
 * Icon Component, allowing to display a simple image with a given source, and a given squared size
 */
@Component({
    selector: 'mys-icon',
    template: IconComponent.template,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent
{
    constructor(protected translate: MysamTranslateService)
    {
    }

    protected static template = `
<img [ngStyle]="{ 'width': size, 'height': size }" src="assets/img/icons/{{ colorFolder }}/{{ imageName }}" 
title="{{ title$ | async }}" />
`;

    @Input() size = '40px';
    @Input() colorFolder = 'grey';
    @Input() imageName: string;
    title$: Observable<string> = of('');

    /**
     * Sets the "title" field asynchronously, once the given parameter has been translated
     * @param {string} titleToTranslate
     */
    protected setTitleTranslationAsync(titleToTranslate: string): Observable<string>
    {
        this.title$ = this.translate.get(titleToTranslate);
        return this.title$;
    }
}
