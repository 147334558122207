import { Action } from '@ngrx/store';
import { Trip } from '../../../../../mys-base/src/lib/models/trip';

// Release Trip
export const RELEASE_TRIP = '[Alfred] Release Trip';
export const RELEASE_TRIP_FAIL = '[Alfred] Release Trip Fail';
export const RELEASE_TRIP_SUCCESS = '[Alfred] Release Trip Success';

export class ReleaseTrip implements Action
{
    readonly type = RELEASE_TRIP;

    constructor(public tripId: number, public driverPenalty: boolean)
    {
    }
}

export class ReleaseTripFail implements Action
{
    readonly type = RELEASE_TRIP_FAIL;

    constructor(public payload: any)
    {
    }
}

export class ReleaseTripSuccess implements Action
{
    readonly type = RELEASE_TRIP_SUCCESS;

    constructor(public payload: Trip)
    {
    }
}

// Action types
export type ReleaseTripAction = ReleaseTrip | ReleaseTripFail | ReleaseTripSuccess;
