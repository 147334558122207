import { environment } from '../../../environments/environment';
import { filter, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'mys-base';
import { DriverDocumentsError } from '../errors/driver-documents.error';
import { DriverDocument } from '../../models/driver-document';
import { DocumentState } from '../../models/constants/document-state';
import { $enum } from 'ts-enum-util';

/**
 * Created by Aurélien Rieu on 05/03/2019
 */
@Injectable()
export class DriverDocumentsService extends ApiService
{
    private readonly DRIVER_DOCUMENTS_BASE_URL = environment.BASE_URL + '/alfred/documents/';
    private readonly DRIVER_DOCUMENT_BASE_URL = this.DRIVER_DOCUMENTS_BASE_URL + 'document/';

    /**
     * Gets the thumbnail of the DriverDocument bound to the given driverId and referenceId
     * The result can be null, if there is no DriverDocument bound to this referenceId provided by the driver yet
     */
    public getDocumentByDriverIdAndReferenceId(driverId: string, referenceId: number): Observable<DriverDocument | null>
    {
        return this.httpClient.get<DriverDocument>(`${this.DRIVER_DOCUMENTS_BASE_URL}${driverId}/${referenceId}`)

            /**
             * Enforce the type as "DriverDocument", if the result is not null
             */
            .pipe(map(driverDocument =>
            {
                if (!!driverDocument)
                {
                    return DriverDocument.assign(driverDocument);
                }

                return null;
            }));
    }

    /**
     * Get the DriverDocument for the given Driver Id
     *
     * @param {string} documentId Id of the requested DriverDocument
     * @returns {Observable<DriverDocument>} The DriverDocument for the given Driver Id
     */
    public getDocumentById(documentId: number): Observable<DriverDocument>
    {
        return this.httpClient.get<DriverDocument>(this.DRIVER_DOCUMENT_BASE_URL + documentId)

            // Enforce the type as "DriverDocument"
            .pipe(map(driverDocument => DriverDocument.assign(driverDocument)));
    }

    /**
     * Change the DocumentState of the given DriverDocument
     *
     * @param {number} driverDocumentId Id of the DriverDocument to update
     * @param {DocumentState} state The new DocumentState of the DriverDocument
     * @returns {Observable<DriverDocument>} The updated DriverDocument
     */
    public updateDriverDocumentState(driverDocumentId: number, state: DocumentState): Observable<DriverDocument>
    {
        const stateAsString = $enum(DocumentState).getKeyOrDefault(state);

        return this.httpClient.put<DriverDocument>(
            this.DRIVER_DOCUMENTS_BASE_URL + driverDocumentId + '/' + stateAsString, {}).pipe(
            // Enforcing the return type here
            map(driverDocument => DriverDocument.assign(driverDocument))
        );
    }

    /**
     * Error builder for the services
     *
     * @param {HttpErrorResponse} err The errors to cast in DriverDocumentsError
     * @returns {DriverDocumentsError} An errors typed as DriverDocumentsError
     */
    public buildDriverDocumentsError(err: HttpErrorResponse): DriverDocumentsError
    {
        const error = super.createApiError(err);
        return new DriverDocumentsError(error);
    }
}
