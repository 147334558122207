export enum MySamError
{
    // Add administrative high quality drivers to client
    ADD_ADMINISTRATIVE_AREA_PREMIUM_DRIVERS_TO_CLIENT_BASE =
        'errors.add-administrative-area-premium-drivers-to-client.ADD-ADMINISTRATIVE-AREA-PREMIUM-DRIVERS-TO-CLIENT-BASE',

    // Default
    ALFRED_USER_NOT_FOUND = 'errors.my-sam-errors.ALFRED-USER-NOT-FOUND',
    DEFAULT_ERROR = 'errors.my-sam-errors.DEFAULT-ERROR',
    REGION_NOT_FOUND = 'errors.my-sam-errors.REGION-NOT-FOUND',
    TRIP_NOT_FOUND = 'errors.my-sam-errors.TRIP-NOT-FOUND',
    TRIP_STATUS_INVALID = 'errors.my-sam-errors.TRIP-STATUS-INVALID',
    TRIP_UPDATE_FORBIDDEN = 'errors.my-sam-errors.TRIP-UPDATE-FORBIDDEN',
    USER_NOT_FOUND = 'errors.my-sam-errors.USER-NOT-FOUND',
    VALUE_IS_NOT_A_NUMBER = 'errors.my-sam-errors.VALUE-IS-NOT-A-NUMBER',
    VALUE_MUST_BE_FILLED = 'errors.my-sam-errors.VALUE-MUST-BE-FILLED',

    // Cashback
    NEGATIVE_CASHBACK = 'errors.my-sam-errors.NEGATIVE_CASHBACK',

    // Cancel Trip
    CANCEL_TRIP_BASE = 'errors.cancel-trip.CANCEL-TRIP-BASE',

    // Companies
    COMPANY_CONTACT_EXISTS = 'errors.my-sam-errors.COMPANY_CONTACT_EXISTS',
    COMPANY_NAME_EXISTS = 'errors.my-sam-errors.COMPANY_NAME_EXISTS',
    COMPANY_NOT_FOUND = 'errors.my-sam-errors.COMPANY_NOT_FOUND',

    // Load AlfredUsers
    LOAD_ALFRED_USERS_BASE = 'errors.load-alfred-users.LOAD-ALFRED-USERS-BASE',

    // Notify available Drivers
    NOTIFY_DRIVERS_BASE = 'errors.notify-available-drivers.NOTIFY-DRIVERS-BASE',
    NOTIFY_DRIVERS_INVALID_TRIP_STATUS = 'errors.notify-available-drivers.NOTIFY-DRIVERS-INVALID-TRIP-STATUS',
    NOTIFY_DRIVERS_INVALID_TRIP_TYPE = 'errors.notify-available-drivers.NOTIFY-DRIVERS-INVALID-TRIP-TYPE',
    NOTIFY_DRIVERS_TOO_EARLY_TO_NOTIFY = 'errors.notify-available-drivers.NOTIFY-DRIVERS-TOO-EARLY-TO-NOTIFY',

    // Release Trip exclusivity
    RELEASE_EXCLU_BASE = 'errors.release-trip-exclusivity.RELEASE-EXCLU-BASE',
    RELEASE_EXCLU_END_DATE_ALREADY_PAST = 'errors.release-trip-exclusivity.RELEASE-EXCLU-END-DATE-ALREADY-PAST',
    RELEASE_EXCLU_INVALID_DRIVER = 'errors.release-trip-exclusivity.RELEASE-EXCLU-INVALID-DRIVER',
    RELEASE_EXCLU_INVALID_END_DATE = 'errors.release-trip-exclusivity.RELEASE-EXCLU-INVALID-END-DATE',
    RELEASE_EXCLU_INVALID_TRIP_STATUS = 'errors.release-trip-exclusivity.RELEASE-EXCLU-INVALID-TRIP-STATUS',

    // Start Trip
    DRIVER_NOT_IN_RANGE = 'errors.start-trip.DRIVER-NOT-IN-RANGE',
    DRIVER_START_TOO_EARLY = 'errors.start-trip.DRIVER-START-TOO-EARLY',
    DRIVER_START_TOO_LATE = 'errors.start-trip.DRIVER-START-TOO-LATE',
    START_TRIP_ALREADY_STARTED = 'errors.start-trip.START-TRIP-ALREADY-STARTED',
    START_TRIP_BASE = 'errors.start-trip.START-TRIP-BASE',
    START_TRIP_DRIVER_IN_AN_OTHER_TRIP = 'errors.start-trip.START-TRIP-DRIVER-IN-AN-OTHER-TRIP',
    START_TRIP_INVALID_STATUS = 'errors.start-trip.START-TRIP-INVALID-STATUS',

    // Stop Trip
    STOP_TRIP_BASE = 'errors.stop-trip.STOP-TRIP-BASE',
    STOP_TRIP_INVALID_STATUS = 'errors.stop-trip.STOP-TRIP-INVALID-STATUS',

    // Trip appeal
    TRIP_APPEAL_MIN_GREATER_THAN_MAX = 'errors.trip-appeal.TRIP-APPEAL-MIN-GREATER-THAN-MAX',
    TRIP_APPEAL_POSITIVE_INTEGER = 'errors.trip-appeal.TRIP-APPEAL-POSITIVE-INTEGER',

    // Update Trip price
    UPDATE_PRICE_3D_VALIDATION_NEEDED = 'errors.update-trip-price.UPDATE-PRICE-3D-VALIDATION-NEEDED',
    UPDATE_PRICE_BASE = 'errors.update-trip-price.UPDATE-PRICE-BASE',
    UPDATE_PRICE_REAUTH_FAILED = 'errors.update-trip-price.UPDATE-PRICE-REAUTH-FAILED',
    UPDATE_PRICE_WRONG_PRICE = 'errors.update-trip-price.UPDATE-PRICE-WRONG-PRICE',

    // Update User referrer
    UPDATE_USER_REFERRER_BASE = 'errors.update-user-referrer.UPDATE-USER-REFERRER-BASE',
    UPDATE_USER_REFERRER_USER_ALREADY_WITH_REFERRER = 'errors.update-user-referrer.UPDATE-USER-REFERRER-USER-ALREADY-WITH-REFERRER',

    // User update
    EMAIL_ALREADY_EXISTS = 'errors.user-update.EMAIL-ALREADY-EXISTS',
    INVALID_EMAIL_SYNTAX = 'errors.user-update.INVALID-EMAIL-SYNTAX',
    USER_UPDATE_BASE = 'errors.user-update.USER-UPDATE-BASE'
}
