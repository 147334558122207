import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {MSLocation, MySamPair} from "mys-base";
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FromToLocationsResolver
{
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MySamPair<MSLocation, MSLocation>> | MySamPair<MSLocation, MSLocation> | null
    {
        /**
         * Extract coordinates (from and to latitude and longitude) from "paramsMap"
         */
        const fromLat = Number(route.paramMap.get('from_lat'));
        const fromLng = Number(route.paramMap.get('from_long'));
        const fromAreaCode = Number(route.paramMap.get('from_areaCode'));
        const toLat = Number(route.paramMap.get('to_lat'));
        const toLng = Number(route.paramMap.get('to_long'));
        const toAreaCode = Number(route.paramMap.get('to_areaCode'));

        /**
         * check that all parameters are present
         *
         * Update 23/05/2023
         * Remove the check on from/to area code because now these values can be null
         */
        if(!!fromLat && !!fromLng && !!toLat && !!toLng)
        {
            return new MySamPair(new MSLocation(fromLat, fromLng, null, fromAreaCode),
                new MSLocation(toLat, toLng, null, toAreaCode));
        }

        /**
         * If one of the parameters is not present, we return null
         */
        return null;
    }
}
