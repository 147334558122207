import * as fromActions from '../actions/trips.action';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { PageMetadata } from '../../../../../msl-driver-registration/src/lib/http/responses/pagination/page-metadata';
import { Trip } from '../../../../../mys-base/src/lib/models/trip';
import { RESET_CSV, ResetCsv } from '../../store/actions';

export interface TripsState extends EntityState<Trip>
{
    loaded: boolean;
    loading: boolean;
    pageMetadata: PageMetadata;
    newestIDs: number[];
    csv: Blob;
}

const tripsAdapter = createEntityAdapter<Trip>({ selectId: (trip: Trip) => trip.id });
const initialState: TripsState = tripsAdapter.getInitialState({
    loaded: false,
    loading: false,
    pageMetadata: new PageMetadata(),
    newestIDs: [],
    csv: null
});

export function reducer(state: TripsState = initialState, action: fromActions.TripsAction | ResetCsv): TripsState
{
    switch (action.type)
    {
        /**
         * LOAD TRIPS ACTIONS
         */
        case fromActions.LOAD_TRIPS:
        {
            return {
                ...state,
                loading: true
            };
        }

        case fromActions.LOAD_TRIPS_FAIL:
        {
            return {
                ...state,
                loading: false,
                loaded: false,
                pageMetadata: new PageMetadata()
            };
        }

        case fromActions.LOAD_TRIPS_SUCCESS:
        {
            const newTripList = action.payload.content;
            const { content, ...pageMetadata } = action.payload;

            /**
             * We remove the trips already stored in-memory, in order to fully refresh the list
             */
            const tempState = tripsAdapter.removeAll(state);
            return tripsAdapter.addMany(newTripList, { ...tempState, loading: false, loaded: true, pageMetadata });
        }

        /**
         * NEWEST TRIP IDS ACTIONS
         */
        case fromActions.LOAD_NEWEST_TRIP_IDS_FAIL:
        {
            return {
                ...state,
                newestIDs: []
            };
        }

        case fromActions.LOAD_NEWEST_TRIP_IDS_SUCCESS:
        {
            return {
                ...state,
                newestIDs: action.payload
            };
        }

        /**
         * LOAD TRIPS CSV ACTIONS
         */
        case fromActions.LOAD_TRIPS_CSV_FAIL:
        {
            return { ...state };
        }

        case fromActions.LOAD_TRIPS_CSV_SUCCESS:
        {
            return { ...state, csv: action.payload };
        }

        case fromActions.RESET_NEWEST_TRIP_IDS:
        {
            return { ...state, newestIDs: [] }
        }
        case RESET_CSV:
        {
            return { ...state, csv: null };
        }
    }

    return state;
}

export const {
    // select the array of Trip ids
    selectIds: selectTripIds,

    // select the dictionary of Trip entities
    selectEntities: selectTripEntities,

    // select the array of Trips
    selectAll: selectAllTrips,

    // select the total Trips count
    selectTotal: selectTripsTotal
} = tripsAdapter.getSelectors();


export const getLoadingFromTripsReducer = (state: TripsState) => state.loading;
export const getMetadataFromTripsReducer = (state: TripsState) => state.pageMetadata;
export const getNewestIDsFromTripsReducer = (state: TripsState) => state.newestIDs;
export const getCsvFromTripsReducer = (state: TripsState) => state.csv;
