<div class="sidebar-container">
    <div class="w-auto d-flex justify-content-center m-3">
        <mys-redirect-button [textBtn]="'create-trip.CREATE'"
                                [pathToRedirect]="'trip/create'"></mys-redirect-button>
    </div>
    <div class="sidebar-content">
        <div *ngIf="settings.app.sidebar.showtoolbar" class="sidebar-toolbar text-center">
            <a>
                <img src="assets/img/user/01.jpg" alt="Profile" class="rounded-circle thumb64"/>
            </a>
            <div class="mt">
                <span *ngIf="(accessRights.currentUser$) | async as user">
                    {{ 'WELCOME' | mysamTranslate }}, {{ user.firstName}} {{ user.lastName}}
                </span>
            </div>
        </div>
        <nav class="sidebar-nav" (click)=handleSidebar($event)>
            <!-- 1st level items-->
            <ul>
                <li *ngFor="let item of menu | async" [routerLinkActive]="['active']">
                    <!-- support for direct links-->
                    <a [href]="item.href" *ngIf="item.href" class="ripple">
                        <span *ngIf="item.subitems?.length" class="pull-right nav-caret">
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                        </span>
                        <span *ngIf="item.label" class="pull-right nav-label">
                            <span [innerHTML]="item.label.count" [ngClass]="item.label?.classname"></span>
                        </span>
                        <span class="nav-icon" *ngIf="item.iconclass || item.imgpath">
                            <ion-icon *ngIf="item.iconclass" name="{{ item.iconclass }}" class="d-inline"></ion-icon>
                            <img *ngIf="item.imgpath" svgreplace [src]="item.imgpath"/>
                        </span>
                        <span *ngIf="item.name" [innerHTML]="item.name | mysamTranslate"></span>
                    </a>
                    <!-- support for route states links-->
                    <a [routerLink]="item.link" *ngIf="!item.href && !item.subitems?.length" class="ripple">
                        <span *ngIf="item.subitems?.length" class="pull-right nav-caret">
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                        </span>
                        <span *ngIf="item.label" class="pull-right nav-label">
                            <span [innerHTML]="item.label?.count" [ngClass]="item.label?.classname"></span>
                        </span>
                        <span class="nav-icon" *ngIf="item.iconclass || item.imgpath">
                            <ion-icon *ngIf="item.iconclass" name="{{ item.iconclass }}" class="d-inline"></ion-icon>
                            <img *ngIf="item.imgpath" svgreplace [src]="item.imgpath"/>
                        </span>
                        <span *ngIf="item.name" [innerHTML]="item.name | mysamTranslate"></span>
                    </a>
                    <!-- parent menu items have empty links-->
                    <a *ngIf="!item.href && item.subitems?.length" class="ripple">
                        <span *ngIf="item.subitems?.length" class="pull-right nav-caret">
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                        </span>
                        <span *ngIf="item.label" class="pull-right nav-label">
                            <span [innerHTML]="item.label?.count" [ngClass]="item.label?.classname"></span>
                        </span>
                        <span class="nav-icon" *ngIf="item.iconclass || item.imgpath">
                            <ion-icon *ngIf="item.iconclass" name="{{ item.iconclass }}" class="d-inline"></ion-icon>
                            <img *ngIf="item.imgpath" svgreplace [src]="item.imgpath"/>
                        </span>
                        <span *ngIf="item.name" [innerHTML]="item.name | mysamTranslate"></span>
                    </a>
                    <!-- 2nd level items-->
                    <ul *ngIf="item.subitems?.length" class="sidebar-subnav">
                        <li *ngFor="let sitem of item.subitems" [routerLinkActive]="['active']">
                            <a [routerLink]="sitem.link" class="ripple">
                                <span *ngIf="sitem.subitems?.length" class="pull-right nav-caret">
                                    <ion-icon name="chevron-forward-outline"></ion-icon>
                                </span>
                                <span *ngIf="sitem.label" class="pull-right nav-label">
                                    <span [innerHTML]="sitem.label?.count" [ngClass]="sitem.label?.classname"></span>
                                </span>
                                <span class="nav-icon" *ngIf="sitem.iconclass || sitem.imgpath">
                                    <ion-icon *ngIf="item.iconclass" name="{{ item.iconclass }}" class="d-inline"></ion-icon>
                                    <img *ngIf="sitem.imgpath" svgreplace [src]="sitem.imgpath"/>
                                </span>
                                <span *ngIf="sitem.name" [innerHTML]="sitem.name | mysamTranslate"></span>
                            </a>
                            <!-- 3rd level items-->
                            <ul *ngIf="sitem.subitems?.length" class="sidebar-subnav">
                                <li *ngFor="let ssitem of sitem.subitems" ui-sref-active="active">
                                    <a [routerLink]="ssitem.sref">
                                        <span *ngIf="ssitem.subitems?.length" class="pull-right nav-caret">
                                            <ion-icon name="chevron-forward-outline"></ion-icon>
                                        </span>
                                        <span *ngIf="ssitem.label" class="pull-right nav-label">
                                            <span [innerHTML]="ssitem.label?.count"
                                                  [ngClass]="ssitem.label?.classname"></span>
                                        </span>
                                        <ion-icon *ngIf="item.iconclass" name="{{ item.iconclass }}" class="d-inline"></ion-icon>
                                        <span *ngIf="ssitem.name" [innerHTML]="ssitem.name | mysamTranslate"></span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</div>
