import { Action } from '@ngrx/store';
import { MobileUser } from 'mys-base';

// Load User
export const SEARCH_USERS = '[Alfred] Search Users';
export const SEARCH_USERS_FAIL = '[Alfred] Search Users Fail';
export const SEARCH_USERS_SUCCESS = '[Alfred] Search Users Success';

export class SearchUsers implements Action
{
    readonly type = SEARCH_USERS;

    constructor(public userId: string, public searchedName: string)
    {
    }
}

export class SearchUsersFail implements Action
{
    readonly type = SEARCH_USERS_FAIL;

    constructor(public payload: any)
    {
    }
}

export class SearchUsersSuccess implements Action
{
    readonly type = SEARCH_USERS_SUCCESS;

    constructor(public payload: MobileUser[])
    {
    }
}

// Action types
export type UsersAction = SearchUsers | SearchUsersFail | SearchUsersSuccess;
