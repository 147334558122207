/**
 * Created by Adrien Dos Reis on 01/10/2021
 */
import { Renderer2 } from '@angular/core';

export class DisableRefreshOnBlur
{
    // region Attributes

    /**
     * jira ticket : https://mysamcab.atlassian.net/browse/MYS-5046
     * we add this boolean to reduce unnecessary network calls when user was on other tab
     * We set the default value to true so that it is not undefined and when we load this tab the refresh can start
     */
    private isCurrentTabFocused: boolean = true;
    refreshTimer: NodeJS.Timeout = undefined;

    private readonly disposeFocus: () => void;
    private readonly disposeBlur: () => void;

    // endregion

    // region Constructor

    /**
     * "triggerInitialRefresh" added on 07/02/2023 - False by default :
     * - If true, a call to "refresh" will be made as soon as the DisableRefreshOnBlur is created
     * - If false, the initial call will be triggered after "millisBetweenEachRefresh" ms
     */
    constructor(renderer: Renderer2, private millisBetweenEachRefresh: number, public refresh: () => void,
                triggerInitialRefresh: boolean = false)
    {
        this.disposeFocus = renderer.listen(window, 'focus', this.currentTabFocus.bind(this));
        this.disposeBlur = renderer.listen(window, 'blur', this.currentTabBlur.bind(this));

        /**
         * If "triggerInitialRefresh" is true, we want to trigger an explicit call to "refresh" right now
         */
        if (triggerInitialRefresh)
        {
            this.refresh()
        }
    }

    // endregion

    /**
     * This function is not called when the page loads because we have already focus
     * But detect when the user return on this tab and set the boolean at true
     * And refresh the trip list to have recent data
     */
    currentTabFocus()
    {
        this.isCurrentTabFocused = true;

        // Recreate refreshTimer
        this.refreshPeriodically();
        this.refresh();
    }

    /**
     * Detect when user change tab
     * And set the boolean at false so that the trip list does not refresh automatically
     */
    currentTabBlur()
    {
        this.isCurrentTabFocused = false;

        // If we don't destroy the refreshTimer, another one will be added
        this.destroyRefreshTimer();
    }

    // region Timer handling

    /**
     * Handles the periodic refresh : Clears any existing timeout. Then, if the tab is currently focused,
     * sets a timer in "millisBetweenEachRefresh", which will execute "refresh"
     */
    refreshPeriodically(): void
    {
        clearTimeout(this.refreshTimer);

        // Refresh only if user was on this tab
        if(this.isCurrentTabFocused)
        {
            this.refreshTimer = setTimeout(() => {
                this.refresh();
                this.refreshPeriodically();
            }, this.millisBetweenEachRefresh);
        }
    }

    /**
     * Toggles the auto. refresh
     */
    public toggleAutoRefresh(): void
    {
        if (this.refreshTimer === undefined)
        {
            this.refreshPeriodically();
        }
        else
        {
            this.destroyRefreshTimer();
        }
    }

    /**
     * Cleans up all the timers and event listeners defined by this object
     */
    public cleanup()
    {
        this.destroyRefreshTimer()

        this.disposeFocus()
        this.disposeBlur()
    }

    /**
     * Destroys the refreshTimer
     */
    private destroyRefreshTimer(): void
    {
        clearTimeout(this.refreshTimer);
        this.refreshTimer = undefined;
    }

    // endregion
}
