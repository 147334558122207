<div class="my-2 p0 d-flex">

    <!-- List item icon -->
    <div [ngClass]="[ 'me-3 me-md-4 d-flex justify-content-center align-items-center my-0' ]"
         style="width: 30px; max-width: 30px; height: 48px">
        <ion-icon name="{{ ionIcon }}" class="{{ ionIconColorClass }} ion3x"></ion-icon>
    </div>

    <!-- Content display -->
    <div>
        <div *ngIf="!isEditing">
            {{ !company ? (emptyKey | mysamTranslate) : (company | company) }}
        </div>
        <div *ngIf="isEditing">
            <mys-select-company [action]="selectCompanyAction"
                                (companyChange)="selectCompany($event)"></mys-select-company>
        </div>

        <div class="text-muted">{{ title }}</div>
    </div>

    <!-- Buttons used through content editing process -->
    <div class="col d-flex justify-content-end my-2">

        <button *ngIf="!isEditing && (!company || editableIfNotEmpty)" mat-icon-button (click)="switchEditMode()">
            <mat-icon>edit</mat-icon>
        </button>

        <button *ngIf="isEditing"  mat-icon-button (click)="validateSelectedCompany()">
            <mat-icon>done</mat-icon>
        </button>

        <button *ngIf="isEditing"  mat-icon-button (click)="switchEditMode()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
