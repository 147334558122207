import { Component, Input, OnInit } from '@angular/core';
import { Alert } from "../../../../../../mys-base/src/lib/models/alert";
import { Observable } from "rxjs";
import { Select, Store } from "@ngxs/store";
import { AlertState } from "../../state/alert.state";
import { Trip } from 'mys-base';
import { GetUnassignedTripAlerts } from '../../actions/alert.action';

@Component({
    selector: 'trip-alerts[trip]',
    templateUrl: './trip-alerts.component.html',
    // use the notification-list scss because we will the same code
    styleUrls: ['./trip-alerts.component.scss', '../alerts-with-pagination/alerts-with-pagination.component.scss']
})
export class TripAlertsComponent implements OnInit
{

    // region Attributes

    @Input() trip: Trip;

    @Select(AlertState.unassignedCurrentTripAlerts) unassignedCurrentTripAlerts$: Observable<Alert[]>;
    @Select(AlertState.nbUnassignedCurrentTripAlerts) nbUnassignedCurrentTripAlerts$: Observable<number | null>;

    // endregion

    // region Constructor

    constructor(private store: Store)
    {
    }

    // endregion

    // region Lifecycle

    ngOnInit()
    {
        this.store.dispatch(new GetUnassignedTripAlerts(this.trip.id));
    }

    // endregion
}
