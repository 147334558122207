import { DriverEligibilityFilter } from '../requests/driver-eligibility-filter';

export const SEARCH_DRIVER_AUTOCOMPLETE = '[DriverAutocomplete] Search drivers';

export class SearchDriversAutocomplete
{
    static readonly type = SEARCH_DRIVER_AUTOCOMPLETE;

    constructor(public term: string, public driverEligibilityFilter?: DriverEligibilityFilter | null)
    {
    }
}

export const RESET_SEARCH_DRIVER_AUTOCOMPLETE = '[DriverAutocomplete] Reset Search drivers';

export class ResetSearchDriversAutocomplete
{
    static readonly type = RESET_SEARCH_DRIVER_AUTOCOMPLETE;

    constructor()
    {
    }
}
