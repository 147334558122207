import { DriverUpdateRequest } from '../../../http/requests/drivers/driver-update.request';
import { Driver } from 'mys-base';
import { Action } from '@ngrx/store';

/**
 * Created by Aurélien Rieu on 04/06/2019
 */
export const LOAD_DRIVER_BY_ID = '[Driver] Load Driver';
export const LOAD_DRIVER_BY_ID_SUCCESS = '[Driver] Load Driver success';
export const EDIT_DRIVER = '[Driver] Edit Driver';

/**
 * Load Driver
 */
export class LoadDriverById
{
    static readonly type = LOAD_DRIVER_BY_ID;

    constructor(public userId: string)
    {
    }
}

/**
 * Load Driver success
 */
export class LoadDriverByIdSuccess implements Action
{
    /**
     * Used in both NGRX & NGXS stores -> Must implement both their "type" attribute
     */
    static readonly type = LOAD_DRIVER_BY_ID_SUCCESS;
    readonly type = LOAD_DRIVER_BY_ID_SUCCESS;

    constructor(public driver: Driver)
    {
    }
}

/**
 * Edit Driver
 */
export class EditDriver
{
    static readonly type = EDIT_DRIVER;

    constructor(public userId: string, public changes: DriverUpdateRequest)
    {
    }
}
