import { ApiError } from 'mys-base';
import { MysamTranslateService } from 'msl-translate';

/**
 * Created by Aurélien Rieu on 05/03/2019
 */
export class DriverDocumentsError extends ApiError
{
    constructor(apiError: ApiError)
    {
        super(apiError.type, apiError.description, apiError.httpStatus, apiError.extraParams);
    }

    public getErrorMessage(translate: MysamTranslateService): string
    {
        switch (this.type)
        {
            // The AlfredUser was not found
            // The userId extra param is not used in the returned errors message for user readability
            case DriverDocumentsError.Type.AlfredUserNotFound:
                return translate.instant(
                    'errors.my-sam-errors.ALFRED-USER-NOT-FOUND', { userId: this.extraParams.userId }
                );

            // The Driver was not found
            // The userId extra param is not used in the returned errors message for user readability
            case DriverDocumentsError.Type.DriverNotFound:
                return translate.instant(
                    'errors.my-sam-errors.DRIVER-NOT-FOUND', { userId: this.extraParams.userId }
                );

            // The Driver document was not found
            // The driverDocumentId extra param is not used in the returned errors message for user readability
            case DriverDocumentsError.Type.DriverDocumentNotFound:
                return translate.instant('errors.driver-documents.DRIVER-DOCUMENT-NOT-FOUND');

            // Crash of the Amazon Client, the backend will recreate it
            case DriverDocumentsError.Type.AmazonS3Crashed:
                return translate.instant('errors.driver-documents.AMAZON-S3-CLIENT-CRASHED-TRY-AGAIN');

            /**
             * For now, we don't handle other errors
             */
            default:
                return translate.instant('errors.my-sam-errors.DEFAULT-ERROR');
        }
    }
}

export namespace DriverDocumentsError
{
    export enum Type
    {
        // Errors that should not happen in production (missing parameters, etc...)
        AlfredUserNotFound = 'ALFRED_USER_NOT_FOUND', // userId
        DriverNotFound = 'DRIVER_NOT_FOUND', // userId

        AmazonS3Crashed = 'AMAZON_S3_CLIENT_CRASHED_TRY_AGAIN',
        DriverDocumentNotFound = 'DRIVER_DOCUMENT_NOT_FOUND', // driverDocumentId
    }
}
