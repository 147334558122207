/**
 * Created by Adrien Dos Reis on 09/11/2020
 */
import { Injectable } from '@angular/core';
import { ApiService, Driver } from 'mys-base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BankInformation } from '../../models/bank-information';
import { HttpErrorResponse } from '@angular/common/http';
import { BankInformationError } from '../errors/bank-information.error';

@Injectable({ providedIn: 'root' })
export class BankInformationService extends ApiService
{
    private readonly BANK_INFO_URL = this.BASE_URL + '/alfred/bank-info';

    /**
     * Posts a new bank information to the server for the given driver
     */
    postNewBankInfo(driver: Driver, iban: string, bic: string): Observable<BankInformation | null>
    {
        const body = { driverId: driver.userId, iban: iban, bic: bic };
        return this.httpClient.post<BankInformation | null>(this.BANK_INFO_URL, body).pipe(
            map(bankInfo => BankInformation.assign(bankInfo))
        );
    }

    public createApiError(err: HttpErrorResponse): BankInformationError
    {
        return new BankInformationError(super.createApiError(err));
    }
}