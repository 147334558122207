import {NgModule} from '@angular/core';
import {HttpBackend} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import {AppComponent} from './app.component';

import {MetaReducer, StoreModule} from '@ngrx/store';

import {EffectsModule} from '@ngrx/effects';
import {RoutesModule} from './routes/routes.module';
import {LayoutModule} from './layout/layout.module';
import {AlfredRouterSerializer, effects, routerReducers} from './store';
// Chrome extension to visualize the State in real-time
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
// Ensures that the states is NEVER mutated
import {storeFreeze} from 'ngrx-store-freeze';
import {AlfredModule} from '../alfred/alfred.module';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {services} from '../alfred/services';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {environment} from '../environments/environment';
import {RouteReuseStrategy} from '@angular/router';
import {MysamRouteReuseStrategy} from './core/configuration/mysam-route-reuse-strategy';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MysamWordReplacerService} from './core/translator/mysam-word-replacer.service';
import {MysamPaginatorTranslator, MysamTranslateModule, WordReplacer} from 'msl-translate';
import {ToastrModule} from 'ngx-toastr';
import {CoreModule} from './core/core.module';
import {MysamTranslateHttpLoader} from '../../../msl-translate/src/lib/mysam-translate-http-loader';
import {MatIconRegistry} from '@angular/material/icon';
import {IS_FROM_ALFRED} from "mys-base";

// Toastr configured here, to be able to use it everywhere in the app
const toastr = ToastrModule.forRoot({
    timeOut: 6000,
    positionClass: 'toast-top-center',
    preventDuplicates: true
});

/**
 * Using the meta reducer "states freeze" here, to ensure that
 * we don't do any mistakes with the states
 */
export const metaReducers: MetaReducer<any>[] = !environment.production ? [storeFreeze] : [];

/**
 * https://github.com/ocombe/ng2-translate/issues/218
 * If the method is not exported, the production build fails with the following message :
 * Error during template compile of 'AppModule'
 * Reference to a non-exported function.
 *
 * UPDATE 19/04/2022 - Usage of HttpBackend instead of HttpClient, in order to bypass our custom Interceptors.
 * If we don't, the "Accept-Language" of the HeaderLanguageInterceptor breaks the translation files loading (wtf...)
 */
export function createTranslateLoader(http: HttpBackend): TranslateLoader
{
    return new MysamTranslateHttpLoader(http, [
        './assets/i18n/',
        './assets/i18n/app-settings/',
        './assets/i18n/companies/',
        './assets/i18n/create-trip/',
        './assets/i18n/driver-payment/',
        './assets/i18n/invoices/',
        './assets/i18n/liberty-driver/',
        './assets/i18n/my-subscription/',
        './assets/i18n/payment-methods/',
        './assets/i18n/register-client/',
        './assets/i18n/rollback-trip-status/',
        './assets/i18n/search-client/'
    ]);
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AlfredModule,
        TranslateModule.forRoot(MysamTranslateModule.translateConfiguration(createTranslateLoader)),
        MysamTranslateModule,
        CoreModule,
        LayoutModule,

        /**
         * UPDATE 09/02/2023 https://mysamcab.atlassian.net/browse/MYS-5984
         * Global options for all SWALs
         * See https://github.com/sweetalert2/ngx-sweetalert2/wiki/Setting-global-defaults-(SweetAlert2-mixins)
         */
        SweetAlert2Module.forRoot({
            provideSwal: () => import('sweetalert2').then(({ default: swal }) => swal.mixin({
                scrollbarPadding: false,
                customClass: { container: 'mysam' }
            }))
        }),
        StoreModule.forRoot(routerReducers, { metaReducers }),
        EffectsModule.forRoot(effects),
        StoreRouterConnectingModule.forRoot(),
        toastr,
        RoutesModule,
        !environment.production ? StoreDevtoolsModule.instrument() : []
    ],
    providers: [services, MysamPaginatorTranslator,
        { provide: RouterStateSerializer, useClass: AlfredRouterSerializer },
        { provide: RouteReuseStrategy, useClass: MysamRouteReuseStrategy },
        { provide: MatPaginatorIntl, useClass: MysamPaginatorTranslator },
        { provide: WordReplacer, useClass: MysamWordReplacerService },
        { provide: IS_FROM_ALFRED, useValue: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule
{
    /**
     * https://github.com/angular/components/issues/24845#issuecomment-1511399687
     *
     * Without this, the Material Symbols are not handled by <mat-icon> yet.
     * Adding this allows to update ALL existing <mat-icon> by adding a new class to the generated icon (which will
     * allow displaying the actual symbol)
     */
    constructor(private readonly iconRegistry: MatIconRegistry)
    {
        const defaultFontSetClasses = this.iconRegistry.getDefaultFontSetClass();
        const outlinedFontSetClasses = defaultFontSetClasses
            .filter((fontSetClass) => fontSetClass !== 'material-icons')
            .concat(['material-symbols-outlined']);
        this.iconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);
    }
}
