import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { AlfredUser, GenericState, Trip, TripOperator } from "mys-base";
import { Store } from "@ngrx/store";
import * as ngxs from "@ngxs/store";
import { Select } from "@ngxs/store";
import { BindOperatorToTrip } from "../../trip-operator-binding/actions/trip-operator-binding.action";
import { Observable, Subscription } from 'rxjs';
import { filter, map } from "rxjs/operators";
import { ResetMetaAttributesAndKeepTripAction } from "../../trips/actions";
import {
    AccessRightsService
} from "../../../../../msl-driver-registration/src/lib/authentication/access-rights/access-rights.service";
import { ToastrUtilService } from "../../utils/services/toastr-util.service";
import { TripOperatorUtils } from "../../utils/trip-operator-utils";
import { TripOperatorBindingState } from '../../trip-operator-binding/states/trip-operator-binding.state';
import { MarkTripOperatorAsEnded, ResetTripOperatorEndedAttributes } from "../action/trip-operator.action";
import { TripOperatorState } from "../state/trip-operator.state";

@Component({
    selector: 'mys-trip-operator[trip]',
    templateUrl: './trip-operator.component.html',
    styleUrls: ['./trip-operator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripOperatorComponent implements OnInit, OnChanges, OnDestroy
{

    // region Attributes

    // region Input

    @Input() trip: Trip;
    @Input() isReducedDisplay: boolean = false;

    // endregion

    // region Selectors

    @Select(TripOperatorBindingState.tripOperatorAdded) tripOperatorAdded$: Observable<TripOperator>;
    @Select(TripOperatorBindingState.tripIdsBeingBound) tripIdsBeingBound$: Observable<number[]>;

    @Select(GenericState.loadingSelector(TripOperatorState)) tripOperatorEndedLoad$: Observable<boolean>;
    @Select(TripOperatorState.tripOperatorEnded) tripOperatorEnded$: Observable<TripOperator>;

    // endregion

    private subscription: Subscription = new Subscription();

    /**
     * The "operators" attribute of the "trip" Input is managed by the Store. We cannot update it.
     * Since the "bindOperatorToTrip" method won't refresh the whole trip (for performance purposes), we need to
     * maintain a local "operators" list in this component
     */
    operators: TripOperator[];

    onHoverAndIsTheOperator: boolean = false;

    // endregion Attributes

    // region Constructor

    constructor(public accessRights: AccessRightsService, public tripOperatorUtils: TripOperatorUtils,
                private toastrUtil: ToastrUtilService, private store: Store,
                private ngxsStore: ngxs.Store, private cdRef: ChangeDetectorRef)
    {
    }

    // endregion

    // region Lifecycle

    ngOnInit(): void
    {
        this.initSubscriptions();
    }

    ngOnChanges(changes: SimpleChanges)
    {
        /**
         * When the "trip" input is updated, we reflect any change that might have occurred on the "operators"
         * back to our local "operators" variable
         */
        if (!!changes.trip)
        {
            this.operators = changes.trip.currentValue.operators;
        }
    }

    ngOnDestroy(): void
    {
        this.subscription.unsubscribe();
        this.store.dispatch(new ResetMetaAttributesAndKeepTripAction());
    }

    // endregion

    // region Subscriptions

    private initSubscriptions()
    {
        this.initSubscriptionBindOperator();

        this.subscription.add(
            this.tripOperatorEnded$.pipe(
                filter(tripOperatorEnded => !!tripOperatorEnded && tripOperatorEnded.tripId === this.trip.id),
                map((tripOperatorEnded) => {

                    this.toastrUtil.displayToastrSuccessMessage('ended-operator.SUCCESS');

                    let operatorToUpdate = this.operators.find(operator => operator.operator.userId === tripOperatorEnded.operator.userId);

                    if (operatorToUpdate) {
                        /**
                         * In order to be able to modify the value in the table we need to create a new editable instance
                         */
                        operatorToUpdate = Object.assign(new TripOperator(), tripOperatorEnded);

                        /**
                         * And finally update the tripOperators array
                         */
                        this.operators = this.operators.map(op => op.operator.userId === tripOperatorEnded.operator.userId ? operatorToUpdate : op);
                    }

                    this.ngxsStore.dispatch(new ResetTripOperatorEndedAttributes());

                    this.cdRef.detectChanges();
                })
            ).subscribe()
        );
    }

    /**
     * Subscription for the operator bound
     */
    private initSubscriptionBindOperator(): void
    {
        this.subscription.add(this.tripOperatorAdded$.pipe(
            /**
             * In order to be able to display the operator's badge when it is bind to an alert (and therefore to a trip) and
             * since this component can be displayed multiple times in the Trip List,
             * we must check that the trip operator returned by the request is indeed equal to the desired trip
             */
            filter(tripOperatorAdded => !!tripOperatorAdded && tripOperatorAdded.tripId === this.trip.id),
            map(tripOperatorAdded =>
            {
                this.operators = [...this.operators, tripOperatorAdded];

                this.toastrUtil.displayToastrSuccessMessage('bind-operator.SUCCESS');

                this.store.dispatch(new ResetMetaAttributesAndKeepTripAction());

                /**
                 * Without this call, if we keep the mouse cursor on the progress loader, it will keep being displayed.
                 */
                this.cdRef.detectChanges();
            })
        ).subscribe());
    }

    // endregion

    // region Assign operator

    /**
     *  Bind current alfred user to the trip hovered or current trip
     */
    bindOperatorToTrip()
    {
        this.ngxsStore.dispatch(new BindOperatorToTrip(this.trip.id, this.operators.length > 0));
    }

    // endregion

    // region Operator ended

    markTripOperatorAsEnded(operator: TripOperator): void {
        if (this.accessRights.currentUserSub.value.userId == operator.operator.userId) {
            this.ngxsStore.dispatch(new MarkTripOperatorAsEnded(operator.tripId));
        }
    }

    // endregion

    // region Check

    /**
     * We want to hide the floating button when the current user has already bound with the hovered/current trip
     */
    isCurrentAlfredUserAlreadyBound(currentUser: AlfredUser): boolean
    {
        return this.operators.some(tripOperator => tripOperator.operator.userId === currentUser.userId);
    }

    /**
     * Returns an Observable true if the local "trip" is being bound to the current AlfredUser.
     * Used to display a loader on the assignation button (and only on this TripOperatorComponent, if we are
     * displaying multiple ones at once (in the Trip List for example))
     */
    isCurrentTripBeingBound$(): Observable<boolean>
    {
        return this.tripIdsBeingBound$.pipe(
            map((tripIds: number[]) => tripIds.filter(tripId => tripId == this.trip.id).length > 0)
        );
    }

    // endregion

    // region Badge operator method

    /**
     * Gets initials of operator first and last name
     */
    getInitials(operator: TripOperator): string
    {
        let initials = operator.operator.firstName.charAt(0) + '.' + operator.operator.lastName.charAt(0);
        return initials.toUpperCase();
    }

    /**
     *  Returns the name of all operators, the date and time they being bound to the trip
     */
    getTooltipText()
    {
        let tooltipText = "";
        this.operators.forEach((operator, index) =>
        {
            if (index != 0) // do not include the first operator because it is already displayed
            {
                tooltipText += this.tripOperatorUtils.getOperatorNameAndBindingDate(operator) + " \n ";
            }
        });
        return tooltipText;
    }

    // endregion
    trackByAlertId(index: number, item: TripOperator): string
    {
        return item.operator.userId;
    }
}
