import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { MysamTranslateService } from 'msl-translate';

/**
 * Convert met, or an empty string if an empty driver details
 * object was passed to the "transform" method
 */
@Pipe({
    name: 'distance'
})
export class DistancePipe implements PipeTransform, OnDestroy
{
    private subscription: Subscription = new Subscription();
    private wrong_parameters;

    constructor(public translateService: MysamTranslateService)
    {
        this.subscription.add(this.translateService.get('errors.WRONG-PARAMETERS').subscribe(translation =>
        {
            this.wrong_parameters = translation.toString();
        }));
    }

    ngOnDestroy(): void
    {
        this.subscription.unsubscribe();
    }

    transform(distance: number, unitOfMeasureIn: string, unitOfMeasureOut: string): number
    {
        let calculatedDistance = 0;
        const distanceMatrix: { 'unit': string, 'value': number, 'weighting': number }[] = [
            { 'unit': 'mm', 'value': 0.001, 'weighting': 1 },
            { 'unit': 'cm', 'value': 0.01, 'weighting': 1 },
            { 'unit': 'dm', 'value': 0.1, 'weighting': 1 },
            { 'unit': 'm', 'value': 1, 'weighting': 1 },
            { 'unit': 'dam', 'value': 10, 'weighting': 1 },
            { 'unit': 'hm', 'value': 100, 'weighting': 1 },
            { 'unit': 'km', 'value': 1000, 'weighting': 1 }
        ];

        const valueIn: { 'unit': string, 'value': number, 'weighting': number } = distanceMatrix.find(dm => dm.unit === unitOfMeasureIn);
        const valueOut: { 'unit': string, 'value': number, 'weighting': number } = distanceMatrix.find(dm => dm.unit === unitOfMeasureOut);

        try
        {
            /* Return an errors if a parameter is wrong when the pipe is called */
            if (valueIn === undefined || valueOut === undefined)
            {
                throw new Error(this.wrong_parameters);
            }

            /* Calculate the good distance conversion */
            calculatedDistance = this.processDistance(valueIn.value, valueOut.value, distance);
            /* When the distance is calculated, apply the weighting */
            calculatedDistance = this.processDistance(valueIn.weighting, valueOut.weighting, calculatedDistance);

            return calculatedDistance;
        }
        catch (e)
        {
            console.error(e.toString(), [ 'valueIn', valueIn ], [ 'valueOut', valueOut ]);
        }
    }

    /* Calculate the distance with the correct conversion or weighting */
    processDistance(valueIn: number, valueOut: number, distance: number): number
    {
        /* We need to multiply valueIn by valueOut to have the correct conversion */
        if (valueIn - valueOut < 0)
        {
            return valueIn / valueOut * distance;
        }
        /* We need to divide valueIn by valueOut to have the correct conversion */
        else if (valueIn - valueOut > 0)
        {
            return valueIn * valueOut * distance;
        }
        /* valueIn = valueOut */
        else
        {
            return distance;
        }
    }
}
