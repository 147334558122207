import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IconComponent } from './icon.component';

@Component({
    selector: 'mys-disability-level',
    template: IconComponent.template,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisabilityLevelComponent extends IconComponent implements OnChanges
{
    @Input() disabilityLevel: boolean | null = null;

    ngOnChanges(changes: SimpleChanges): void
    {
        this.setDisabilityLevelIcon(this.disabilityLevel);
    }

    /**
     * Draws an icon adapted to the given disabilityLevel
     * @param disabilityLevel The disabilityLevel for which we want to draw the logo
     */
    private setDisabilityLevelIcon(disabilityLevel: boolean)
    {
        this.setTitleAsync(disabilityLevel);
        switch (disabilityLevel)
        {
            case true:
                this.imageName = 'significant-disability.png';
                break;
            case false:
                this.imageName = 'mild-disability.png';
                break;
            default: // NONE
                break;
        }
    }

    /**
     * Sets the "title" field asynchronously, once the given parameter has been translated
     * @param {boolean | null} disabilityLevel The disability level of the Trip
     */
    protected setTitleAsync(disabilityLevel: boolean | null): void
    {
        this.title$ = super.setTitleTranslationAsync(
            disabilityLevel === null ? 'enums.disability-level-filter.NONE' :
                disabilityLevel === false ?
                    'enums.disability-level-filter.MILD-DISABILITY' : 'enums.disability-level-filter.SIGNIFICANT-DISABILITY'
        );
    }
}
