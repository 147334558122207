<msl-primary-button (click)="click.emit()" [displayLoader]="displayLoader">

    <!--
            This line allows to embed any Material Icon defined here : https://fonts.google.com/icons
    -->
    <mat-icon *ngIf="!!icon">{{ icon }}</mat-icon>

    <!-- If a content is given, it will be displayed here -->
    <ng-content></ng-content>
</msl-primary-button>
