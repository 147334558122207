import { AlfredState, getAlfredState } from '../reducers';
import { createSelector } from '@ngrx/store';
import * as fromClientsReducer from '../reducers/clients/clients.reducer';
import * as fromClientReducer from '../reducers/clients/client.reducer';

// region Clients

export const getClientsState = createSelector(getAlfredState, (state: AlfredState) => state.clients);

export const getClientsEntities = createSelector(getClientsState, fromClientsReducer.selectClientEntities);
export const getClients = createSelector(getClientsState, fromClientsReducer.selectAllClients);

export const getClientsLoaded = createSelector(getClientsState, fromClientsReducer.getLoadedFromClientsReducer);
export const getClientsLoading = createSelector(getClientsState, fromClientsReducer.getLoadingFromClientsReducer);
export const getClientsMetadata = createSelector(getClientsState, fromClientsReducer.getMetadataFromClientsReducer);
export const getClientsCsv = createSelector(getClientsState, fromClientsReducer.getCsvFromClientsReducer);

// endregion

// region Client

export const getClientState = createSelector(getAlfredState, (state: AlfredState) => state.currentClient);

/* Editing selectors */
export const getClientEdited = createSelector(getClientState, fromClientReducer.getEditedFromClientReducer);
export const getClientEditing = createSelector(getClientState, fromClientReducer.getEditingFromClientReducer);

/* Loading selectors */
export const getClientLoaded = createSelector(getClientState, fromClientReducer.getLoadedFromClientReducer);
export const getClientLoading = createSelector(getClientState, fromClientReducer.getLoadingFromClientReducer);

export const getCurrentClient = createSelector(getClientState, fromClientReducer.getCurrentClientFromClientReducer);
export const getClientError = createSelector(getClientState, fromClientReducer.getErrorFromClientReducer);

// endregion
