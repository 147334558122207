<!-- When an error occurs while loading a list -->
<mat-error *ngIf="alertsError$ | async"> {{ 'notification.ERROR-WHILE-LOADING' | mysamTranslate }} </mat-error>

<!--
        Loader
        To avoid seeing the alerts list blink when it refreshes, we don't display the loader if the list is already
        loaded and contains at least one element
-->
<msl-centered-spinner *ngIf="alerts?.length == 0 && alertsLoading$ | async"></msl-centered-spinner>

<!-- Alert List -->
<ng-container *ngIf="alerts?.length > 0">
    <div *ngFor="let alert of alerts; trackBy: trackByAlertId; let last = last">
        <mys-alert-row [alert]="alert"></mys-alert-row>

        <!-- If we are not on the last element, we display a <mat-divider> -->
        <mat-divider *ngIf="!last"></mat-divider>
    </div>
</ng-container>

<!-- No notification message -->
<ng-container *ngIf="displayEmptyContainerIfEmpty && alerts?.length == 0 && !(alertsError$ | async)">
    {{ 'notification.NO-NOTIFICATION' | mysamTranslate }}
</ng-container>
