import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from "@angular/core";

/**
 * Created by Adrien Dos Reis on 13/03/2023
 * Displays a notification icon, and opens/closes an Alert panel when the alert is clicked
 */
@Component({
    selector: 'mys-icon-and-alert-panel',
    templateUrl: './icon-and-alert-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconAndAlertPanelComponent
{
    // region Inputs / Outputs

    /**
     * The badge value can be provided as an Input, which will :
     * - Update the "badgeNumberValue" variable
     * - Change the "Notification Icon" if needed
     */
    badgeNumberValue: number | null = null;

    @Input()
    set badgeNumber(newBadgeValue: number)
    {
        /**
         * If the newBadgeValue is 0, we replace it with null (in order to hide the badge instead of showing a badge
         * with a "0" value)
         */
        newBadgeValue = newBadgeValue > 0 ? newBadgeValue : null;

        this.badgeNumberValue = newBadgeValue;
        this.changeNotificationIcon(newBadgeValue);
    }

    /**
     * Broadcasts the "onShow/onHide" events from PrimeNG/OverlayPanel
     */
    @Output() onShow = new EventEmitter<any>();
    @Output() onHide = new EventEmitter<any>();

    // endregion

    // region Attributes
    notificationIconToDisplay = 'notifications';

    // endregion

    // region HostListeners

    /**
     * If this component is used in a container that is itself clickable (e.g. the Trip header in the TripDetailComponent),
     * clicking this component (to open the overlay panel) would also propagate the click event to the parent (and in
     * the case of TripDetailComponent, would collapse/expand the whole view when the header is clicked).
     * To avoid that, we simply stop the propagation of the click event when the current component is clicked`
     * This method gets the $event as method parameter (using the "@HostListener("click", ['$event'])" notation),
     * and stops the event propagation.
     */
    @HostListener("click", ['$event'])
    hostClicked($event: MouseEvent)
    {
        $event.stopPropagation();
    }

    // endregion

    changeNotificationIcon(alertsLength: number)
    {
        this.notificationIconToDisplay = alertsLength > 0 ? 'notifications_active' : 'notifications';
    }
}
