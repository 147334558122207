import { ApiError } from 'mys-base';

export class SearchDriversAutocompleteError extends ApiError
{

    constructor(apiError: ApiError)
    {
        super(apiError.type, apiError.description, apiError.httpStatus, apiError.extraParams);
    }

    public getTranslateCode(): string
    {
        return 'errors.my-sam-errors.DEFAULT-ERROR';
    }
}
