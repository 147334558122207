import { GenericState, GenericStateModel, MySamPair } from "mys-base";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { AlertService } from "../service/alert.service";
import { catchError, map } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";
import {
    BindOperatorToAlert,
    GetAssignedAlerts,
    GetUnassignedAlerts,
    GetUnassignedTripAlerts, LoadUnassignedAlertsOnDashboard,
    ResetAlertMetaAttributes,
    ResolveAlert
} from "../actions/alert.action";
import { Alert } from "projects/mys-base/src/lib/models/alert";
import { TripOperator } from "projects/mys-base/src/lib/models/trip-operator";
import { Page } from '../../../../../msl-driver-registration/src/lib/http/responses/pagination/page';
import {
    BindOperatorToTripAndAssignAlertsError,
    BindOperatorToTripAndAssignAlertsSuccess,
    ResetBindOperatorToTripAndAssignAlertsStates
} from '../../trip-operator-binding/actions/trip-operator-binding.action';

/**
 * Created by Sandra Bénard on 18/10/2022
 */

export interface AlertStateModel extends GenericStateModel
{
    assignedAlerts: Alert[],
    unassignedAlerts: Page<Alert>,
    unassignedAlertsDashboard: Page<Alert>,
    unassignedTripAlerts: Alert[],
    alertIdsSuccessfullyResolved: number[],
    alertsIdsResolving: number[],
    isAlertResolvedError: boolean,
    alertIdsBeingBound: number[],
    alertIdsSuccessfullyBound: number[],
    isAlertBindError: boolean,
    errorLoading: boolean
}

const initialMetaAttributes = {
    unassignedAlertsCount: null,
    alertUpdated: [],
    alertIdsSuccessfullyResolved: [],
    alertsIdsResolving: [],
    isAlertResolvedError: false,
    alertIdsBeingBound: [],
    alertIdsSuccessfullyBound: [],
    isAlertBindError: false,
    errorLoading: false
};

const initialState = {
    assignedAlerts: [],
    unassignedAlerts: new Page<Alert>(),
    unassignedAlertsDashboard: new Page<Alert>(),
    unassignedTripAlerts: [],
    ...initialMetaAttributes
};

@State({
    name: 'alert',
    defaults: initialState
})

@Injectable()
export class AlertState
{
    // region Constructor

    constructor(private notificationService: AlertService)
    {
    }

    // endregion

    // region Selector

    @Selector()
    static assignAlerts(state: AlertStateModel): Alert[]
    {
        return state.assignedAlerts;
    }

    @Selector()
    static unassignedAlerts(state: AlertStateModel)
    {
        return state.unassignedAlerts;
    }

    @Selector()
    static unassignedAlertsDashboard(state: AlertStateModel)
    {
        return state.unassignedAlertsDashboard;
    }

    @Selector()
    static nbUnassignedAlerts(state: AlertStateModel): number
    {
        /**
         * At first glance, we think that the "nbUnassignedAlerts" is simply the total number of elements of
         * "unassignedAlerts".
         * However, since we sometimes have "assigned alerts" in this list (when an assignation JUST occurred),
         * we need to remove those from the calculation
         */
        const totalUnassignedAlerts = state.unassignedAlerts.totalElements;
        const locallyAssignedAlerts = state.unassignedAlerts.content.filter(alert => !!alert.operator).length;

        return totalUnassignedAlerts - locallyAssignedAlerts;
    }

    @Selector()
    static unassignedCurrentTripAlerts(state: AlertStateModel)
    {
        return state.unassignedTripAlerts;
    }

    @Selector()
    static nbUnassignedCurrentTripAlerts(state: AlertStateModel)
    {
        /**
         * The "unassignedCurrentTripAlerts" selector can provide us with Alerts that are actually assigned to an
         * operator (when the assignation was made a few moments ago, in order for us to be able to display an animation).
         *
         * In order to provide an accurate "number" value, we exclude those "fake unassigned alerts" from our
         * calculation
         *
         * N.B. : The " || []" notation at the end allows to provide an empty array if "state.unassignedTripAlerts"
         * is null or undefined.
         * This way, we enforce the "non-nullability" when accessing the "length" property
         */
        return (state.unassignedTripAlerts?.filter(alert => !alert.operator) || []).length;
    }

    @Selector()
    static errorLoading(state: AlertStateModel): boolean
    {
        return state.errorLoading;
    }

    /**
     * This selector returns a function, taking a number (an alertId) as parameter, and returning a boolean true if
     * the given "alertId" is in the "alertIdsBeingBound" list
     * See https://www.ngxs.io/advanced/optimizing-selectors#implementation for implementation details (and why the
     * usage of a temporary Set for performance matters)
     */
    @Selector()
    static isAlertBeingBoundById(state: AlertStateModel): (number) => boolean
    {
        return AlertState.contains(state.alertIdsBeingBound);
    }

    @Selector()
    static isAlertSuccessfullyBoundById(state: AlertStateModel): (number) => boolean
    {
        return AlertState.contains(state.alertIdsSuccessfullyBound);
    }

    @Selector()
    static isAlertBindError(state: AlertStateModel): boolean
    {
        return state.isAlertBindError;
    }

    @Selector()
    static isAlertSuccessfullyResolvedById(state: AlertStateModel): (number) => boolean
    {
        return AlertState.contains(state.alertIdsSuccessfullyResolved);
    }

    @Selector()
    static isAlertResolvingById(state: AlertStateModel): (number) => boolean
    {
        return AlertState.contains(state.alertsIdsResolving);
    }

    @Selector()
    static isAlertResolveError(state: AlertStateModel): boolean
    {
        return state.isAlertResolvedError;
    }

    /**
     * Util method used in our parameterized selectors, allowing to find a specific "id" in a given "numberArray"
     */
    static contains(numberArray: number[]): (number) => boolean
    {
        const numberSet = new Set(numberArray);
        return (value: number) => numberSet.has(value);
    }

    // endregion

    // region Assigned Alerts

    @Action(GetAssignedAlerts)
    GetAssignedAlerts(ctx: StateContext<AlertStateModel>)
    {
        ctx.patchState({ ...GenericState.load() });

        return this.notificationService.getAssignedAlerts().pipe(
            map((assignedAlerts: Alert[]) => this.getAssignedAlertsSuccess(ctx, assignedAlerts)),
            catchError((error: any) =>
                of(this.getAssignedAlertsFail(ctx, error)))
        );
    }

    // noinspection JSMethodCanBeStatic
    getAssignedAlertsSuccess(ctx: StateContext<AlertStateModel>, assignedAlerts: Alert[])
    {
        return ctx.patchState({
            assignedAlerts: assignedAlerts,
            ...GenericState.success()
        });
    }

    // noinspection JSMethodCanBeStatic
    getAssignedAlertsFail(ctx: StateContext<AlertStateModel>, error: any)
    {
        return ctx.patchState({
            assignedAlerts: null,
            errorLoading: true,
            ...GenericState.error(error)
        });
    }

    // endregion

    // region Unassigned Alerts

    @Action(GetUnassignedAlerts)
    GetUnassignedAlerts(ctx: StateContext<AlertStateModel>, action: GetUnassignedAlerts)
    {
        ctx.patchState({ ...GenericState.load() });

        return this.notificationService.getUnassignedAlerts(action.pageable).pipe(
            map((unassignedAlerts: Page<Alert>) => this.getUnassignedAlertsSuccess(ctx, unassignedAlerts)),
            catchError((error: any) =>
                of(this.getUnassignedAlertsFail(ctx, error)))
        );
    }

    // noinspection JSMethodCanBeStatic
    getUnassignedAlertsSuccess(ctx: StateContext<AlertStateModel>, unassignedAlerts: Page<Alert>)
    {
        return ctx.patchState({
            unassignedAlerts: unassignedAlerts,
            errorLoading: false,
            ...GenericState.success()
        });
    }

    // noinspection JSMethodCanBeStatic
    getUnassignedAlertsFail(ctx: StateContext<AlertStateModel>, error: any)
    {
        return ctx.patchState({
            unassignedAlerts: null,
            errorLoading: true,
            ...GenericState.error(error)
        });
    }

    // endregion

    // region Unassigned Alerts Dashboard

    @Action(LoadUnassignedAlertsOnDashboard)
    loadUnassignedAlertsOnDashboard(ctx: StateContext<AlertStateModel>, action:LoadUnassignedAlertsOnDashboard)
    {
        ctx.patchState({ ...GenericState.load() });

        return this.notificationService.getUnassignedAlerts(action.pageable).pipe(
            map((unassignedAlerts: Page<Alert>) => this.loadUnassignedAlertsOnDashboardSuccess(ctx, unassignedAlerts)),
            catchError((error: any) =>
                of(this.loadUnassignedAlertsOnDashboardFail(ctx, error)))
        );
    }

    // noinspection JSMethodCanBeStatic
    loadUnassignedAlertsOnDashboardSuccess(ctx: StateContext<AlertStateModel>, unassignedAlerts: Page<Alert>)
    {
        return ctx.patchState({
            unassignedAlertsDashboard: unassignedAlerts,
            errorLoading: false,
            ...GenericState.success()
        });
    }

    // noinspection JSMethodCanBeStatic
    loadUnassignedAlertsOnDashboardFail(ctx: StateContext<AlertStateModel>, error: any)
    {
        return ctx.patchState({
            unassignedAlertsDashboard: null,
            errorLoading: true,
            ...GenericState.error(error)
        });
    }

    // endregion

    // region Unassigned Trip alerts

    @Action(GetUnassignedTripAlerts)
    GetUnassignedTripAlerts(ctx: StateContext<AlertStateModel>, action: GetUnassignedTripAlerts)
    {
        ctx.patchState({ ...GenericState.load() });

        return this.notificationService.getUnassignedAlertsByTrip(action.tripId).pipe(
            map((unassignedTripAlerts: Alert[]) => this.getUnassignedTripAlertsCountSuccess(ctx, unassignedTripAlerts)),
            catchError((error: any) =>
                of(this.getUnassignedTripAlertsCountFail(ctx, error)))
        );
    }

    // noinspection JSMethodCanBeStatic
    getUnassignedTripAlertsCountSuccess(ctx: StateContext<AlertStateModel>, unassignedTripAlerts: Alert[])
    {
        return ctx.patchState({
            unassignedTripAlerts: unassignedTripAlerts,
            errorLoading: false,
            ...GenericState.success()
        });
    }

    // noinspection JSMethodCanBeStatic
    getUnassignedTripAlertsCountFail(ctx: StateContext<AlertStateModel>, error: any)
    {
        return ctx.patchState({
            unassignedTripAlerts: null,
            errorLoading: true,
            ...GenericState.error(error)
        });
    }

    // endregion

    // region Bind Operator to Alert

    /**
     * Bind Operator Action
     */

    @Action(BindOperatorToAlert)
    BindOperatorToAlert(ctx: StateContext<AlertStateModel>, action: BindOperatorToAlert)
    {
        ctx.patchState({ alertIdsBeingBound: [action.alertId, ...ctx.getState().alertIdsBeingBound] });

        return this.notificationService.bindOperatorToAlert(action.alertId, action.shouldBindOperatorToAllExistingAlerts).pipe(
            /**
             * Dispatching a specific action here, in order to update both our current state and the
             * TripOperatorBindingState as well
             */
            map((operatorAndAlertsAssigned: MySamPair<TripOperator | null, Alert[]>) =>
                ctx.dispatch(new BindOperatorToTripAndAssignAlertsSuccess(operatorAndAlertsAssigned))),
            catchError((error: any) => ctx.dispatch(new BindOperatorToTripAndAssignAlertsError(error)))
        );
    }

    @Action(BindOperatorToTripAndAssignAlertsSuccess)
    bindOperatorToAlertsSuccess(ctx: StateContext<AlertStateModel>, action: BindOperatorToTripAndAssignAlertsSuccess)
    {
        const alertsAssigned: Alert[] = action.payload.second;
        const alertIdsAssigned: number[] = alertsAssigned.map(alert => alert.id);

        /**
         * The "alertIdsStillBeingBound" are the "alertIdsBeingBound", minus the ones contained in the "action"
         * (that were bound successfully : they are not "being bound" anymore)
         */
        const alertIdsStillBeingBound = ctx.getState().alertIdsBeingBound.filter(id => !alertIdsAssigned.includes(id));

        return ctx.patchState({
            alertIdsSuccessfullyBound: [...alertIdsAssigned, ...ctx.getState().alertIdsSuccessfullyBound],
            alertIdsBeingBound: alertIdsStillBeingBound,
            isAlertBindError: false,

            /**
             * The new alerts is placed first, to appear at the top of the list (since the list is sorted
             * by "created" DESC)
             */
            assignedAlerts: [...alertsAssigned, ...ctx.getState().assignedAlerts],

            /**
             * We update the "unassignedAlerts" and the "unassignedTripAlerts" (the "assignedAlerts" are updated
             * above, by adding the "alertsAssigned" to it)
             */
            ...this.updateExistingAlertsInState(ctx, alertsAssigned)
        });
    }

    @Action(BindOperatorToTripAndAssignAlertsError)
    bindOperatorToAlertFail(ctx: StateContext<AlertStateModel>, _action: BindOperatorToTripAndAssignAlertsError)
    {
        return ctx.patchState({
            alertIdsSuccessfullyBound: [],
            alertIdsBeingBound: [],
            isAlertBindError: true
        });
    }

    // endregion

    // region Resolve Alert

    @Action(ResolveAlert)
    resolveAlert(ctx: StateContext<AlertStateModel>, action: ResolveAlert)
    {
        ctx.patchState({ alertsIdsResolving: [action.alertId, ...ctx.getState().alertsIdsResolving] });

        return this.notificationService.resolveAlert(action.alertId).pipe(
            map((alert) => this.resolveAlertSuccess(ctx, alert)),
            catchError((error: any) => of(this.resolveAlertFail(ctx, error)))
        );
    }

    resolveAlertSuccess(ctx: StateContext<AlertStateModel>, resolveResponse: MySamPair<TripOperator | null, Alert[]>)
    {
        /**
         * See https://mysamcab.atlassian.net/browse/MYS-6079
         * The "resolveResponse" contains the following :
         * - An optional TripOperator, if a Trip was assigned to the current user when resolving the Alert
         * - A list of Alerts, all assigned to the current user, and with ONE of them also resolved (the one we
         * wanted to resolve in the first place)
         *
         * In the simplest scenario, the "first" element will be null and the "second" will be a list of only one
         * resolved element. But, in some other scenarios (resolving an alert not previously assigned, and bound to
         * a trip, that might have other alerts bound to it...), it can be a little more complicated :)
         *
         * How to handle it :
         * We extract the resolved alert from the "resolveResponse", and we delegate the rest of the response to
         * "BindOperatorToTripAndAssignAlertsSuccess"
         */
        const resolvedAlert = resolveResponse.second.find(alert => !!alert.resolutionDate);

        /**
         * The "alertsIdsStillResolving" are the "alertsIdsResolving", minus the given "alert" (which was bound
         * successfully : it is not "resolving" anymore)
         */
        const alertsIdsStillResolving = ctx.getState().alertIdsBeingBound.filter(id => resolvedAlert.id !== id);

        /**
         * Now we delegate the rest of the response to BindOperatorToTripAndAssignAlertsSuccess
         */
        ctx.dispatch(new BindOperatorToTripAndAssignAlertsSuccess(resolveResponse));

        return ctx.patchState({
            alertIdsSuccessfullyResolved: [resolvedAlert.id, ...ctx.getState().alertIdsSuccessfullyResolved],
            alertsIdsResolving: alertsIdsStillResolving,

            isAlertResolvedError: false,

            /**
             * Here, we update the "unassignedAlerts", the "unassignedTripAlerts", and also the "assignedAlerts"
             */
            ...this.updateExistingAlertsInState(ctx, [resolvedAlert], true)
        });
    }

    resolveAlertFail(ctx: StateContext<AlertStateModel>, error: any)
    {
        /**
         * See https://mysamcab.atlassian.net/browse/MYS-6079
         * An error during resolve is usually triggered by the "bind operator and assign alerts" underlying process
         * automatically triggered by the backend. In order to handle it properly, we dispatch the
         * BindOperatorToTripAndAssignAlertsError as well as the standard "error" handling of the "resolved" process
         */
        ctx.dispatch(new BindOperatorToTripAndAssignAlertsError(error));
        return ctx.patchState({
            alertIdsSuccessfullyResolved: [],
            alertsIdsResolving: [],
            isAlertResolvedError: true
        });
    }

    // endregion

    // region Reset

    @Action(ResetAlertMetaAttributes)
    resetAlertMetaAttributes(ctx: StateContext<AlertStateModel>)
    {
        return ctx.patchState(initialMetaAttributes);
    }

    @Action(ResetBindOperatorToTripAndAssignAlertsStates)
    resetAlertState(ctx: StateContext<AlertStateModel>)
    {
        return ctx.patchState(initialState);
    }

    // endregion

    // region Private methods

    /**
     * In this State, we have three lists of Alerts :
     * - "unassignedAlerts" (actually a Page<Alert>), containing all the unassigned alerts of our system
     * - "unassignedTripAlerts", containing all unassigned alerts bound to a specific trip
     * - "assignedAlerts", containing all alerts of our system assigned to the current user
     *
     * When assigning/resolving alerts, we DON'T want to remove them from the "unassigned" lists (or from the "assigned"
     * list when resolving), because if we do, they will also disappear instantly from the UI, and we won't be able to
     * animate them.
     *
     * Instead, this method updates their values in their respective lists (e.g. replacing a locally unassigned alert
     * with its updated version), and the UI components will be able to adapt the way they are displayed.
     *
     * Finally, when a backend refresh will be triggered, the State will be refreshed and the Alerts will be placed in
     * the appropriate lists
     */
    private updateExistingAlertsInState(ctx: StateContext<AlertStateModel>, alerts: Alert[],
                                        alsoUpdateAssignedAlerts: boolean = false): Partial<AlertStateModel>
    {
        /**
         * The new "unassignedAlerts" page will still contain those "assigned alerts" (for now), but they will now be
         * actually assigned to an operator. This way, our UI components will be able to update their UI and display
         * those alerts accordingly (before actually remove them when the backend will refresh the list)
         */
        const newUnassignedPage = new Page<Alert>(this.updateAlerts(ctx.getState().unassignedAlerts.content, alerts));

        /**
         * Same behavior for "unassignedTripAlerts" : It will be updated with "assigned alerts" in order to
         * adapt the UI
         */
        const newUnassignedTripAlerts = this.updateAlerts(ctx.getState().unassignedTripAlerts, alerts);

        /**
         * Finally, if the "alsoUpdateAssignedAlerts" boolean is true, we... also update the "assigned alerts"
         */
        let newAssignedAlerts = ctx.getState().assignedAlerts;
        if (alsoUpdateAssignedAlerts)
        {
            newAssignedAlerts = this.updateAlerts(newAssignedAlerts, alerts);
        }

        return {
            assignedAlerts: newAssignedAlerts,
            unassignedAlerts: newUnassignedPage,
            unassignedTripAlerts: newUnassignedTripAlerts
        };
    }

    /**
     * Util method to change the content of an Alert array
     * Iterates over "initialArray", and replaces each alert with the alert from "updatedArray" if it contains an
     * alert with the same ID. Otherwise, keeps the old alert from "initialArray"
     */
    private updateAlerts(initialArray: Alert[], updatedArray: Alert[]): Alert[]
    {
        return initialArray.map(initialAlert =>
            updatedArray.find(one => this.equalityPred(one, initialAlert)) || initialAlert);
    }

    /**
     * Equality predicate between two Alerts : They are identical if their IDs are identical
     */
    private equalityPred(one: Alert, two: Alert): boolean
    {
        return one.id === two.id;
    }

    // endregion
}
