/**
 * Created by Adrien Dos Reis on 20/02/2019
 */
import { Company } from 'mys-base';

export const GET_COMPANIES = '[SelectCompany] Get Companies';
export const GET_GROUPS_OF_DRIVERS = '[SelectCompany] Get Groups of Drivers';
export const ADD_COMPANY_IN_STORE = '[SelectCompany] Add Company In Store';

/**
 * Gets all Companies of our database
 */
export class GetCompaniesAction
{
    static readonly type = GET_COMPANIES;

    constructor(public name: string | null = null)
    {
    }
}

/**
 * Gets the Groups of Drivers from our database
 */
export class GetGroupsOfDriversAction
{
    static readonly type = GET_GROUPS_OF_DRIVERS;

    constructor()
    {
    }
}

/**
 * Adds a Company in our states
 */
export class AddCompanyInStoreAction
{
    static readonly type = ADD_COMPANY_IN_STORE;

    constructor(public payload: Company)
    {
    }
}
