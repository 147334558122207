/**
 * Created by Adrien Dos Reis on 10/11/2020
 */
export class BankInformation
{
    constructor(public iban: string, public bic: string, public created: Date, public active: Boolean)
    {
    }

    /**
     * Assigns an "uncasted" BankInformation (provided by an API call for example) to a real BankInformation object
     */
    static assign(bi: BankInformation | null): BankInformation | null
    {
        return (!!bi) ? new BankInformation(bi.iban, bi.bic, bi.created, bi.active) : null;
    }
}
