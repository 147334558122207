import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'mys-base';
import { AddToFavoritesOrBlacklistError } from '../errors/add-to-favorites-or-blacklist.error';
import { Observable } from 'rxjs';

@Injectable()
export class AddDriverToClientFavoritesOrBlacklistService extends ApiService
{
    private readonly BASE_CLIENT_URL = this.BASE_URL + '/alfred/clients/';

    /**
     * Add a driver to the favorite drivers list of a client
     *
     * @param {string} clientId ID of the client
     * @param {string} driverId ID of the driver to add to favorites
     */
    public addDriverToClientFavorites(clientId: string, driverId: string): Observable<void>
    {
        return this.httpClient.post<void>(this.BASE_CLIENT_URL + clientId + '/favorites/add/' + driverId, undefined);
    }

    /**
     * Add a driver to the blacklist of a client
     *
     * @param clientId ID of the client
     * @param driverId ID of the driver to add to blacklist
     */
    addDriverToClientBlacklist(clientId: string, driverId: string)
    {
        return this.httpClient.post<void>(this.BASE_CLIENT_URL + clientId + '/blacklist/' + driverId, null);
    }

    public createApiError(err: HttpErrorResponse): AddToFavoritesOrBlacklistError
    {
        return new AddToFavoritesOrBlacklistError(super.createApiError(err));
    }
}
