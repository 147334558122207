import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'mys-email-field',
    templateUrl: './email-field.component.html',
    styleUrls: [ './email-field.component.scss' ]
})
export class EmailFieldComponent implements OnInit
{
    // region Inputs / Outputs

    @Input() placeholderKey = 'login-form.EMAIL';

    /**
     * Will be set as the default value of the "emailInput" FormControl
     */
    @Input() defaultEmail = '';
    @Output() emailCtrl = new EventEmitter<FormControl<string>>();

    // endregion

    emailInput: FormControl<string>;

    ngOnInit(): void
    {
        /**
         * Now that "defaultEmail" is set, we can define the FormControl relying on it
         */
        this.emailInput = new FormControl<string>(this.defaultEmail, Validators.compose([ Validators.required, Validators.email ]));

        /**
         * We emit the EmailCtrl as soon as possible (as parent components might rely on it)
         */
        this.emitEmailCtrl();

        /**
         * And on every change, we re-emit it
         */
        this.emailInput.registerOnChange(this.emitEmailCtrl);
    }

    /**
     * Emits "emailInput" for the parent components to use
     */
    private emitEmailCtrl()
    {
        this.emailCtrl.emit(this.emailInput);
    }
}
