import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { SettingsService } from '../../shared/settings/settings.service';
import * as ngxs from '@ngxs/store';
import { LogoutAction } from 'msl-login';
import { isLibertyDriver } from '../../../environments/liberty-driver/environment.alfred';
import { PagetitleService } from '../../core/pagetitle/pagetitle.service';
import { Navigate } from '@ngxs/router-plugin';
import { Router } from '@angular/router';
import { MysamTranslateService } from 'msl-translate';
import {
    AccessRightsService
} from "../../../../../msl-driver-registration/src/lib/authentication/access-rights/access-rights.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss', './header.menu-links.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent
{
    isLibertyDriver = isLibertyDriver;

    constructor(public accessRights: AccessRightsService, public settings: SettingsService, public pt: PagetitleService,
                private ngxsStore: ngxs.Store, private translate: MysamTranslateService, private router: Router)
    {
    }

    reportBug()
    {
        // TODO Continue to dev this
        // console.info(DebugLogger.getInstance().readAndClearStorage().join('\n'));
    }

    goToSubscriptionComponent()
    {
        this.ngxsStore.dispatch(new Navigate(['/my-subscription']));
    }

    changeLanguage(langCode: string)
    {
        /**
         * We change the language
         */
        this.translate.useLanguage(langCode);

        /**
         * And we reload the page (using the Angular Router, in order to reload only the content)
         */
        this.ngxsStore.dispatch(new Navigate([this.router.url]));
    }

    onHelpIconClicked()
    {
        window.open('mailto:' + `contact@mysam.fr?subject=Liberty Driver - Question`);
    }

    logout()
    {
        this.ngxsStore.dispatch(new LogoutAction());
    }

    // region Settings

    toggleSidebarOffcanvasVisible()
    {
        this.settings.app.sidebar.offcanvasVisible = !this.settings.app.sidebar.offcanvasVisible;
    }

    toggleSidebar(state = null)
    {
        //  states === true -> open
        //  states === false -> close
        //  states === null -> toggle
        this.settings.app.sidebar.visible = state !== null ? state : !this.settings.app.sidebar.visible;
    }

    // endregion
}
