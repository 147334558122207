import { DocumentState } from '../../models/constants/document-state';
import { DriverDocument } from '../../models/driver-document';

/**
 * Created by Aurélien Rieu on 05/03/2019
 */
export const GET_DOCUMENT_BY_DRIVER_ID_AND_REF_ID = '[DriverDocuments] Get Document By DriverId and ReferenceId';
export const GET_DOCUMENT_BY_ID = '[DriverDocument] Get Document By Id';
export const UPDATE_DOCUMENT_IN_LIST = '[DriverDocuments] Update the Driver document in the document list';
export const UPDATE_DOCUMENT_STATE = '[DriverDocument] Update Driver document states';
export const RESET_DRIVER_DOCUMENT = '[DriverDocument] Reset Driver document';
export const RESET_DRIVER_DOCUMENTS = '[DriverDocuments] Reset Driver documents';

/**
 * Get the Driver documents
 */
export class GetDocumentByDriverIdAndReferenceId
{
    static readonly type = GET_DOCUMENT_BY_DRIVER_ID_AND_REF_ID;

    constructor(public driverId: string, public referenceId)
    {
    }
}

export class GetDocumentById
{
    static readonly type = GET_DOCUMENT_BY_ID;

    constructor(public documentId: number)
    {

    }
}

/**
 * Update the Driver document in the document list
 */
export class UpdateDocumentInList
{
    static readonly type = UPDATE_DOCUMENT_IN_LIST;

    constructor(public driverDocument: DriverDocument)
    {
    }
}

/**
 * Update the Driver document states
 */
export class UpdateDriverDocumentState
{
    static readonly type = UPDATE_DOCUMENT_STATE;

    constructor(public driverDocumentId: number, public state: DocumentState)
    {
    }
}

/**
 * Reset the Driver document
 */
export class ResetDriverDocument
{
    static readonly type = RESET_DRIVER_DOCUMENT;

    constructor()
    {
    }
}

/**
 * Reset the Driver documents
 */
export class ResetDriverDocuments
{
    static readonly type = RESET_DRIVER_DOCUMENTS;

    constructor()
    {
    }
}
