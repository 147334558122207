import { Injectable } from "@angular/core";
import { ApiService, Trip } from "mys-base";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class TripOperatorService extends ApiService
{
    private TRIP_OPERATOR_BASE_URL = this.BASE_URL + '/alfred/trip-operator/';

    /**
     * Mark trip operator as ended
     * @param tripId trip concerned
     */
    public markTripOperatorAsEnded(tripId: number): Observable<Trip>
    {
        return this.httpClient.post<Trip>(this.TRIP_OPERATOR_BASE_URL + 'operator-ended/' + tripId, null);
    }
}
