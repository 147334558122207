/**
 * Created by Adrien Dos Reis on 07/01/2019
 */
import { Client, GenericState, GenericStateModel } from 'mys-base';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetClientsAction, ResetClientsStateAction } from '../actions/clients.action';
import { ClientAutocompleteService } from '../services/client-autocomplete.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

export interface ClientsStateModel extends GenericStateModel
{
    clients: Client[];
}

const initialState = {
    clients: [],
    ...GenericState.init()
};

import { Injectable } from '@angular/core';

@State({
    name: 'clients',
    defaults: initialState
})
@Injectable()
export class ClientsState
{
    constructor(private clientAutocompleteService: ClientAutocompleteService)
    {
    }

    // region Selectors

    @Selector()
    static clients(state: ClientsStateModel): Client[]
    {
        return state.clients;
    }

    @Selector()
    static loading(state: ClientsStateModel)
    {
        return state.loading;
    }

    @Selector()
    static registerClientError(state: ClientsStateModel): any
    {
        return state.error;
    }

    // endregion

    @Action(GetClientsAction)
    getClients(ctx: StateContext<ClientsStateModel>, action: GetClientsAction)
    {
        ctx.patchState({ ...GenericState.load() });

        const companyId = !!action.company ? action.company.id : null;
        return this.clientAutocompleteService.getClientsByTerm(action.term, companyId).pipe(
            map((clients: Client[]) => this.getClientsSuccess(ctx, clients)),
            catchError((error: any) => of(this.getClientsFail(ctx, error)))
        );
    }


    // noinspection JSMethodCanBeStatic
    getClientsFail(ctx: StateContext<ClientsStateModel>, error: any): ClientsStateModel
    {
        return ctx.patchState({ ...GenericState.error(error) });
    }

    // noinspection JSMethodCanBeStatic
    getClientsSuccess(ctx: StateContext<ClientsStateModel>, clients: Client[]): ClientsStateModel
    {
        return ctx.patchState({
            clients: clients,
            ...GenericState.success()
        });
    }

    @Action(ResetClientsStateAction)
    resetClientsState(ctx: StateContext<ClientsStateModel>, _action: ResetClientsStateAction): ClientsStateModel
    {
        return ctx.setState(initialState);
    }
}
