import { MysamTranslateService } from 'msl-translate';
import { ApiError } from 'mys-base';

/**
 * Created by Sandra Bénard on 13/02/2023
 */
export class TripOperatorError extends ApiError
{
    constructor(apiError: ApiError)
    {
        super(apiError.type, apiError.description, apiError.httpStatus, apiError.extraParams);
    }

    public getErrorMessage(translate: MysamTranslateService): string
    {
        switch (this.type)
        {
            // trip alerts unassigned
            case OperatorError.Type.UnhandledAlertOnTripException:
                return this.description; // This error is already translated from the backend

            /**
             * For now, we don't handle other errors
             */
            default: // Default error
                return translate.instant('bind-operator.ERROR-SUBTITLE');
        }
    }
}

export namespace OperatorError
{
    export enum Type
    {
        UnhandledAlertOnTripException = 'UNHANDLED_ALERT_ON_TRIP', // trip alerts unassigned
    }
}
