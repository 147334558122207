import { Company } from 'mys-base';

export const GET_CLIENTS = '[Clients] Get Clients';
export const RESET_GET_CLIENTS = '[Clients] Reset Get Clients';

export class GetClientsAction
{
    static readonly type = GET_CLIENTS;

    constructor(public term: string, public company: Company | null) // Search term
    {
    }
}

export class ResetClientsStateAction
{
    static readonly type = RESET_GET_CLIENTS;

    constructor()
    {
    }
}
