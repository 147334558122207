import { Pageable } from "../../../../../msl-driver-registration/src/lib/http/responses/pagination/pageable";

/**
 * Created by Sandra Bénard on 18/10/2022
 */

// region Assigned Alerts

export const GET_ASSIGNED_ALERTS = '[AssignedAlert] Get assigned alerts list';

export class GetAssignedAlerts
{
    static readonly type = GET_ASSIGNED_ALERTS;
}

// endregion

// region Unassigned Alert

export const GET_UNASSIGNED_ALERTS = '[UnassignedAlert] Get unassigned alerts list';
export const GET_UNASSIGNED_ALERT_COUNT = '[UnassignedAlert] Get unassigned alert count';
export const GET_UNASSIGNED_TRIP_ALERT = '[UnassignedAlert] Get unassigned trip alert';
export const LOAD_UNASSIGNED_ALERTS_ON_DASHBOARD = '[UnassignedAlert] Load unassigned alert on dashboard';

export class GetUnassignedAlerts
{
    static readonly type = GET_UNASSIGNED_ALERTS;

    constructor(public pageable: Pageable)
    {
    }
}

/**
 * Now we have two components where unassigned alerts are displayed (on the dashboard and
 * in notifications) both that can be displayed at the same time must not be based on the same attribute
 * state otherwise during an action (example: page change in the paginator) both components will be updated.
 * This is why we created a state attribute specifically for the dashboard.
 */
export class LoadUnassignedAlertsOnDashboard
{
    static readonly type = LOAD_UNASSIGNED_ALERTS_ON_DASHBOARD;
    constructor(public pageable: Pageable) {
    }
}

export class GetUnassignedTripAlerts
{
    static readonly type = GET_UNASSIGNED_TRIP_ALERT;

    constructor(public tripId: number)
    {
    }
}

// endregion

// region Actions on Alerts

export const BIND_OPERATOR_TO_ALERT = '[Alert] Bind operator to alert';
export const RESOLVE_ALERT = '[Alert] Resolve alert';

export class BindOperatorToAlert
{
    static readonly type = BIND_OPERATOR_TO_ALERT;

    constructor(public alertId: number, public shouldBindOperatorToAllExistingAlerts: boolean = false)
    {
    }
}

export class ResolveAlert
{
    static readonly type = RESOLVE_ALERT;

    constructor(public alertId: number)
    {
    }
}

// endregion

// region Reset
export const RESET_ALERT_META_ATTRIBUTES = '[Alert] Reset meta attributes';

/**
 * Resets the Meta Attributes of the AlertState (= resets all loading/binding/resolving/... attributes, but keeps the
 * unassigned alerts/assigned alerts/alerts on trip lists loaded)
 */
export class ResetAlertMetaAttributes
{

    static readonly type = RESET_ALERT_META_ATTRIBUTES;

    constructor()
    {
    }
}

// endregion
