/**
 * Created by Adrien Dos Reis on 27/12/2018
 */
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from "@angular/core";

/**
 * Re-defines how the routes should be used and re-used.
 * All methods does the same thing than the default RouteReuseStrategy, except stated otherwise
 */
@Injectable()
export class MysamRouteReuseStrategy extends RouteReuseStrategy
{
    shouldDetach(route: ActivatedRouteSnapshot): boolean
    {
        return false;
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void
    {
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean
    {
        return false;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle
    {
        return null;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean
    {
        /**
         * Here, we explicitly set the "reuse" to "false", in order to re-load a component when we reuse
         * its route
         */
        return false;
    }
}
