import { ApiService } from 'mys-base';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DriverUpdateRequest } from '../../../http/requests/drivers/driver-update.request';
import { Driver } from 'mys-base';
import { DriverError } from '../errors/driver.error';

/**
 * Created by Aurélien Rieu on 04/06/2019
 */
@Injectable({ providedIn: 'root' })
export class DriverService extends ApiService
{
    private DRIVER_BASE_URL = environment.BASE_URL + '/alfred/drivers/';
    private DRIVER_UPDATE_URL = environment.BASE_URL + '/alfred/driver-edition/';

    /**
     * Gets the Driver bound to the given driverId
     *
     * @param {string} driverId The Id of the Driver we are looking for
     * @returns {Observable<Driver>} The Driver matching with the Id
     */
    public getDriverById(driverId: string): Observable<Driver>
    {
        return this.httpClient.get<Driver>(`${this.DRIVER_BASE_URL}${driverId}`).pipe(
            // Enforcing the return type here
            map((driver) => Driver.assign(driver))
        );
    }

    /**
     * Update the Driver from a request object
     *
     * @param {string} userId The Id of the Driver we are looking for
     * @param {DriverUpdateRequest} driverUpdateReq The object containing the updates to apply to the Driver
     * @returns {Observable<Driver>} The updated Driver
     */
    public updateDriver(userId: string, driverUpdateReq: DriverUpdateRequest): Observable<Driver>
    {
        return this.httpClient.put<Driver>(`${this.DRIVER_UPDATE_URL}${userId}`, driverUpdateReq).pipe(
            // Enforcing the return type here
            map((driver) => Driver.assign(driver))
        );
    }

    /**
     * Error builder for the services
     *
     * @param {HttpErrorResponse} err The errors to cast in DriverError
     * @returns {DriverError} An errors typed as DriverError
     */
    public buildDriverError(err: HttpErrorResponse): DriverError
    {
        const error = super.createApiError(err);
        return new DriverError(error);
    }
}
