import { Trip } from '../../../../../mys-base/src/lib/models/trip';
import { Action } from '@ngrx/store';

// Stop Trip
export const STOP_TRIP = '[Alfred] Stop Trip';
export const STOP_TRIP_FAIL = '[Alfred] Stop Trip Fail';
export const STOP_TRIP_SUCCESS = '[Alfred] Stop Trip Success';

export class StopTrip implements Action
{
    readonly type = STOP_TRIP;

    constructor(public payload: number)
    {
    }
}

export class StopTripFail implements Action
{
    readonly type = STOP_TRIP_FAIL;

    constructor(public payload: any)
    {
    }
}

export class StopTripSuccess implements Action
{
    readonly type = STOP_TRIP_SUCCESS;

    constructor(public payload: Trip)
    {
    }
}

// Action types
export type StopTripAction = StopTrip | StopTripFail | StopTripSuccess;
