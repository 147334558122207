import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import * as routerActions from '../actions';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class RouterEffect
{
    constructor(private actions$: Actions, private router: Router, private location: Location)
    {
    }

    navigate$ = createEffect(() => this.actions$
        .pipe(
            ofType<routerActions.Go>(routerActions.GO),
            map((action: routerActions.Go) => action.payload),
            tap(({ path, query: queryParams, extras }) =>
            {
                // console.log('navigating to ', path);
                this.router.navigate(path, { queryParams, ...extras });
            })
        ), { dispatch: false });

    navigateBack$ = createEffect(() => this.actions$.pipe(
            ofType<routerActions.Back>(routerActions.BACK),
            tap(() => this.location.back())
        ), { dispatch: false });

    navigateForward$ = createEffect(() => this.actions$.pipe(
        ofType<routerActions.Forward>(routerActions.FORWARD),
        tap(() => this.location.forward())
    ), { dispatch: false });
}
