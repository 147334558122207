import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts seconds to hours, or return 0 the seconds are null
 */
@Pipe({
    name: 'secondsToMinutesFloored'
})
export class SecondsToMinutesFlooredPipe implements PipeTransform
{
    transform(seconds: number): number
    {
        if (!!seconds)
        {
            return Math.floor(seconds / 60) % 60;
        }

        return 0;
    }
}
