import { Component, Input } from '@angular/core';
import { ZoneSurcharge } from 'mys-base';

@Component({
    selector: 'mys-zone-surcharge[zoneSurcharge]',
    templateUrl: './zone-surcharge.component.html',
    styleUrls: [ './zone-surcharge.component.scss' ]
})
export class ZoneSurchargeComponent
{
    /**
     * The actual ZoneSurcharge to display
     */
    @Input() zoneSurcharge: ZoneSurcharge | null;

    /**
     * UI Inputs :
     * - If "ionColorClass" is provided, it will be used as the coloring class of the inner ion icon
     * - Otherwise, a default color will be provided, based on the boolean value of "displayAsCompany"
     */
    @Input() ionColorClass: string | null;
    @Input() displayAsCompany = false;
}
