/**
 * Created by Adrien Dos Reis on 17/12/2018
 */
import { Injectable } from '@angular/core';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AccessRightsService } from './access-rights.service';
import { Driver, DriverStatus } from 'mys-base';
import { of } from 'rxjs/internal/observable/of';

@Injectable({ providedIn: 'root' })
export class DriverAccessRightsService
{
    // region Attributes

    /**
     * The current connected Driver (only if it is a Driver, of course)
     */
    currentDriver$ = of<Driver>();

    /**
     * A boolean indicating whether the current connected Driver is "PENDING_APPROVAL" or not
     */
    isPendingApproval$ = of<boolean>();

    // endregion

    constructor(private accessRights: AccessRightsService)
    {
        of().pipe(takeUntil(
            this.currentDriver$ = this.accessRights.currentUser$.pipe(
                filter(currentUser => Driver.isDriver(currentUser)),
                map(currentUser => Driver.assign(currentUser))
            )
        )).subscribe();

        of().pipe(takeUntil(
            this.isPendingApproval$ = this.currentDriver$.pipe(
                filter(currentDriver => !!currentDriver),
                map((currentDriver: Driver) => currentDriver.status === DriverStatus.PENDING_APPROVAL)
            )
        )).subscribe();
    }
}
