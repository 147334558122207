import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "../components/components.module";
import { PipesModule } from '../pipes/pipes.module';
import { MatBadgeModule } from "@angular/material/badge";
import { NgxsModule } from "@ngxs/store";
import { AlertState } from "./state/alert.state";
import { MatCardModule } from "@angular/material/card";
import { AlertsWithPaginationComponent } from './components/alerts-with-pagination/alerts-with-pagination.component';
import { MslCenteredSpinnerModule } from "msl-components";
import { MatTabsModule } from "@angular/material/tabs";
import { FloatingActionButtonModule } from "../utils/modules/floating-action-button/floating-action-button.module";
import { AlertRowComponent } from './components/alert-row/alert-row.component';
import { TripAlertsComponent } from './components/trip-alerts/trip-alerts.component';
import { AlertsListComponent } from './components/alerts-list/alerts-list.component';
import { IconNotificationComponent } from './components/icon-notification/icon-notification.component';
import { TripOperatorModule } from '../trip-operator/trip-operator.module';
import {
    SwalTripOperatorAssignAlertsFailedComponent
} from '../trip-operator/components/swal-trip-alert-error/swal-trip-operator-assign-alerts-failed.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {
    IconAndAlertPanelComponent
} from './components/containers/icon-and-alert-panel/icon-and-alert-panel.component';
import {
    HeaderAlertsContainerComponent
} from './components/containers/header-alerts-container/header-alerts-container.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { UnassignedAlertContainerComponent } from './components/unassigned-alert-container/unassigned-alert-container.component';

const exportedComponents = [HeaderAlertsContainerComponent, AlertsWithPaginationComponent, AlertRowComponent,
    IconAndAlertPanelComponent, TripAlertsComponent, UnassignedAlertContainerComponent, AlertsListComponent];
const components = [AlertsListComponent, IconNotificationComponent, SwalTripOperatorAssignAlertsFailedComponent];

/**
 * The AlertModule have interactions with the TripOperatorModule : An assigned alert might also bind the
 * operator to the alert's trip, and vice versa.
 * In order to ensure both Alert and TripOperatorBinding states are up and running, we import TripOperatorModule
 * from AlertModule
 * Furthermore, in order for Angular to know about this import, we need to export the TripOperatorModule from here. This
 * way, Angular knows that AlertModule also imports TripOperatorModule, and other modules can use it as well
 */
const exportedModules = [TripOperatorModule];

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        ...exportedModules,
        FloatingActionButtonModule,
        MatBadgeModule,
        MslCenteredSpinnerModule,
        NgxsModule.forFeature([AlertState]),
        OverlayPanelModule,
        PipesModule,
        MatCardModule,
        MatTabsModule,
        SweetAlert2Module
    ],
    declarations: [...exportedComponents, ...components],
    exports: [...exportedComponents, ...exportedModules]
})
export class AlertModule
{
}
