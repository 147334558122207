<!--
        Small margin, useful for two things :
        - Vertically : Allows to display the shadow below our badges properly
        - Horizontally : Allows not to glue the badge to the "Menu actions" at the right (in the Trip detail page)
-->
<div *ngIf="accessRights.currentUser$ | async as currentUser" class="d-flex flex-wrap m-1 justify-content-around">

    <!--
            Button allowing to bind the current user to the trip
    -->
    <div class="d-none display-if-parent-hovered" *ngIf="!isCurrentAlfredUserAlreadyBound(currentUser)">
        <mys-floating-action-button
            [icon]="'add'"
            [displayLoader]="isCurrentTripBeingBound$() | async"
            [matTooltip]="'bind-operator.BIND-OPERATOR' | mysamTranslate"
            (click)="bindOperatorToTrip()">
        </mys-floating-action-button>
    </div>
    <!-- Badges of all operators bounded with the trip -->
    <!--
        If the user is on the trip detail page or if we only need to display two or less operators for the trip,
        we display all operators
    -->
    <ng-container *ngIf="operators.length <= 2 || !isReducedDisplay">
        <ng-container *ngFor="let operator of operators">
            <ng-template [ngTemplateOutlet]="initialOrIconBtn"
                         [ngTemplateOutletContext]="{ operator: operator }"></ng-template>
        </ng-container>

    </ng-container>

    <!--
        Here, when the user is on the list of trips and several operators are linked to a trip,
        we display the first operator and the rest on "..." (instead of all the badges) with the tooltip text the list of operators.
        For tablet or phone we display the tooltip on click
    -->
    <div *ngIf="operators.length > 2 && isReducedDisplay" class="d-flex justify-content-around">
        <ng-template [ngTemplateOutlet]="initialOrIconBtn"
                     [ngTemplateOutletContext]="{ operator: operators[0] }"></ng-template>
        <mys-floating-action-button #tooltip="matTooltip"
                                    [icon]="'more_horiz'"
                                    (click)="tooltip.show()"
                                    [matTooltip]="getTooltipText()"
                                    [matTooltipPosition]="'above'">
        </mys-floating-action-button>
    </div>
</div>
<!-- //////////////////////////////////////////////////// -->
<!-- Reusable code blocks defined here -->
<!-- //////////////////////////////////////////////////// -->
<ng-template #initialOrIconBtn let-operator=operator let-alertResolving=alertResolving>
    <mys-floating-action-button [matTooltip]="tripOperatorUtils.getOperatorNameAndBindingDate(operator)"
                                [ngClass]="{'gray-background': !!operator.endDate }"
                                (click)="markTripOperatorAsEnded(operator)"
                                (mouseleave)="onHoverAndIsTheOperator = false"
                                (mouseenter)="onHoverAndIsTheOperator = this.accessRights.currentUserSub.value.userId == operator.operator.userId">
        <!-- Basic information -> initials of operator -->
        <ng-container *ngIf="!onHoverAndIsTheOperator || !!operator.endDate; else isTheOperator">
            {{ getInitials(operator) }}
        </ng-container>
        <!-- On hover if the badge is the current operator we display an icon instead -->
        <ng-template #isTheOperator>
            <msl-centered-spinner *ngIf="tripOperatorEndedLoad$ | async"></msl-centered-spinner>
            <mat-icon color="primary" *ngIf="!(tripOperatorEndedLoad$ | async)">done_outline</mat-icon>
        </ng-template>
    </mys-floating-action-button>
</ng-template>
