import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AddressType, MslAddressAutocompleteComponent, SearchAddresses } from "msl-addresses";
import { Store } from "@ngrx/store";
import { AlfredState } from "../../store";
import * as ngxs from "@ngxs/store";

/**
 * Created by Sandra Bénard on 19/01/2022
 */

@Component({
    selector: 'mys-detail-item-address-autocomplete',
    templateUrl: './detail-item-address-autocomplete.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailItemAddressAutocompleteComponent
{
    // region Input specific to address field

    @ViewChild('autocompleteAddress') autocompleteAddress: MslAddressAutocompleteComponent;

    @Input() addressType: AddressType;
    @Input() displayInReadMode: string = null;

    @Output() updateAddressField = new EventEmitter<MslAddressAutocompleteComponent>();

    // endregion

    // region mys-detail-item

    @Input() iconColorClass = 'pink';
    @Input() titleKey: string;
    @Input() isEditable = false;

    // endregion

    // region msl-address-autocomplete

    @Input() values: any[] = [];
    @Input() errorFieldBlank = 'errors.DEFAULT-BODY';

    // endregion msl-address-autocomplete

    // region Editing handling

    /**
     * If defined, will be executed on "editing".
     * Be careful ! This attribute cannot be used with others like "textWhileEditing" and "contentWhileEditingChange"
     * (because defining a "executeWhenEditing" would take precedence over the display of "textWhileEditing")
     */
    @Input() contentWhileEditing: string = null;
    @Output() contentWhileEditingChange: EventEmitter<string> = new EventEmitter<string>();

    // region constructor

    constructor(private store: Store<AlfredState>, private ngxsStore: ngxs.Store) {}

    // endregion

    /**
     * Before the update, we want to check that the field does not contain errors
     */
    validatorFn(): boolean
    {
        return this.autocompleteAddress.autocomplete.checkForErrorsAndUpdateUI();
    }

    /**
     * Searches for Addresses matching the given term, and populates the states bound to the "from/to" address
     * based on the "addressType" boolean value
     * @param {string} term
     */
    autocompleteAddresses(term: string)
    {
        this.ngxsStore.dispatch(new SearchAddresses(term, this.addressType));
    }
}
