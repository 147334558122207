import { Pipe, PipeTransform } from '@angular/core';

/**
 * Displays a enum object as a list of "[key, value]"
 */
@Pipe({
    name: 'enum'
})
export class EnumPipe implements PipeTransform
{
    transform(value, ...args: string[]): any
    {
        const keys = [];
        for (const enumMember of Object.keys(value))
        {
            keys.push({ key: enumMember, value: value[ enumMember ] });
        }
        return keys;
    }
}
