import { ApiError } from 'mys-base';
import { MysamTranslateService } from 'msl-translate';

export class AlfredUserError extends ApiError
{
    public getErrorMessage(translate: MysamTranslateService): string
    {
        switch (this.type)
        {
            case AlfredUserError.Type.EmailAlreadyExists:
            {
                console.log('errors params:', this.extraParams);
                return translate.instant('alfred-users.errors.EMAIL_ALREADY_EXISTS_W_PARAMS',
                    { email: this.extraParams[ 'email' ] });
            }
            default:
            {
                return translate.instant('errors.DEFAULT-BODY');
            }
        }
    }
}

export namespace AlfredUserError
{
    export enum Type
    {
        EmailAlreadyExists = 'ALFRED_EMAIL_ALREADY_EXISTS'
    }
}
