import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'mys-floating-action-button',
    templateUrl: './floating-action-button.component.html',
    styleUrls: [ './floating-action-button.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloatingActionButtonComponent
{
    // region Inputs / Outputs

    @Input() icon: string | null = null; // Can be any icon name defined here : https://material.io/resources/icons/
    @Output() click = new EventEmitter<void>();
    @Input() displayLoader: boolean = false;

    // endregion
}
