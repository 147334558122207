import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AlfredState } from '../store';
import { Store } from '@ngrx/store';
import { filter, flatMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    CurrentUserIsPayingGuard
} from '../../../../msl-driver-registration/src/lib/authentication/guards/current-paying-user-guard.service';
import {
    AccessRightsService
} from '../../../../msl-driver-registration/src/lib/authentication/access-rights/access-rights.service';
import { AlfredUser, SpringAuthority } from 'mys-base';
import { CurrentUserUtils } from 'msl-login';

/**
 * Created by Adrien Dos Reis on 27/04/2020
 * This guard only allows access for AlfredUsers not bound to a Company, or Drivers that have the SUPERVISOR role
 */
@Injectable({ providedIn: 'root' })
export class AdminOrSupervisorDriverGuard 
{
    constructor(private store: Store<AlfredState>, private currentUserLoadedAndPayingGuard: CurrentUserIsPayingGuard,
                private accessRights: AccessRightsService)
    {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    {
        // First, we ensure that the parent "canActivate" is valid.
        return this.currentUserLoadedAndPayingGuard.canActivate(route, state).pipe(
            filter((parentResult: boolean) => !!parentResult),

            /**
             * Now, if the parent "canActivate" is valid, we can check the second step
             */
            flatMap(() => this.isAdminOrSupervisor())
        );
    }

    /**
     * Returns an Observable containing a boolean indicating whether the current User is either a MySam Admin, or a Supervisor Driver
     */
    isAdminOrSupervisor(): Observable<boolean>
    {
        return this.accessRights.currentUser$.pipe(
            map((currentUser: AlfredUser) => currentUser.authorities),
            map((authorities: SpringAuthority[]) =>
                CurrentUserUtils.isRoleSupervisorDriver(authorities) || CurrentUserUtils.isRoleAdmin(authorities)));
    }
}
