import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MenuService } from '../core/menu/menu.service';

import { menu } from './menu';
import { routes } from './routes';

@NgModule({
    imports: [
        // " onSameUrlNavigation: 'reload' " allows us to use our custom RouteReuseStrategy
        RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload' })
    ],
    declarations: [],
    exports: [ RouterModule ]
})
export class RoutesModule
{
    constructor(private menuService: MenuService)
    {
        this.menuService.addMenu(menu);
    }
}
