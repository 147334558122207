import { TripDetailGuard } from './trip-detail.guard';
import { DriverDetailGuard } from './driver-detail.guard';
import { ClientDetailGuard } from './client-detail.guard';
import { BoundToMySamGuard } from './bound-to-mysam.guard';

/**
 * Guards only used in the Alfred module
 */
export const alfredGuards = [ TripDetailGuard, DriverDetailGuard, ClientDetailGuard, BoundToMySamGuard];

export * from './trip-detail.guard';
export * from './driver-detail.guard';
export * from './client-detail.guard';
export * from './bound-to-mysam.guard';
