import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Select, Store } from '@ngxs/store';
import { RequestNewPasswordAction } from '../../actions/forgot-password.action';
import { UntypedFormControl } from '@angular/forms';
import { GenericState, SwalUtilsService } from 'mys-base';
import { ForgotPasswordState } from '../../state/forgot-password.state';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
    selector: 'mys-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: [ './forgot-password.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent implements OnDestroy
{
    // region Attributes

    private subscription = new Subscription();
    emailCtrl: UntypedFormControl; // Will be initialized as soon as EmailFieldComponent is initialized

    // endregion

    // region Inputs / Outputs

    /**
     * Will be set as the default value of the "email" field in "swalForgotPassword"
     */
    @Input() defaultEmail = '';

    // endregion

    // region ViewChildren

    @ViewChild('swalForgotPassword') swalForgotPassword: SwalComponent;
    @ViewChild('swalEmailSuccessfullySent') swalEmailSuccessfullySent: SwalComponent;

    // endregion

    // region Selectors

    @Select(GenericState.loadedSelector(ForgotPasswordState)) forgotRequestLoaded$: Observable<boolean>;
    @Select(GenericState.errorSelector(ForgotPasswordState)) forgotRequestError$: Observable<any>;

    // endregion

    // region Constructor

    constructor(private ngxsStore: Store)
    {
    }

    // endregion

    /**
     * Gets the value from the "emailInput", and sends it to the server to request a new Password Token
     * Returns false to block the swal from closing
     */
    requestNewPasswordToken(): Promise<boolean>
    {
        if (this.emailCtrl.valid)
        {
          /**
           * We add the possibility to reset a forgot password for liberty order, since it's the same process but not
           * the same endpoint we just add a boolean variable for check if is liberty driver or not
           * jira ticket : https://mysamcab.atlassian.net/browse/MYS-5339
           */
             this.ngxsStore.dispatch(new RequestNewPasswordAction(this.emailCtrl.value));

            /**
             * Now, we observe both "errors" and "loaded" values, and whenever one of those values changes,
             * we know that we have finished the process (the loader can stop spinning).
             *
             * And in this case, we return "loaded", which will either be true (and the swal will close itself) or false
             * (and the swal will stay displayed)
             */
            return SwalUtilsService.getPreconfirmPromise(this.forgotRequestLoaded$, this.forgotRequestError$,
                () => this.swalEmailSuccessfullySent.fire());
        }
        else
        {
            /**
             * Since the "emailCtrl" could have be "pre-filled" by the Input, we mark it as "touched", in order to display
             * any errors if the field is not valid
             */
            this.emailCtrl.markAsTouched();
            this.emailCtrl.updateValueAndValidity();
            return Promise.resolve(false);
        }
    }

    /**
     * Displays the Swal compounded in this Component
     */
    display()
    {
        this.swalForgotPassword.fire();
    }

    /**
     * Binds the State Selector values to their respective behavior
     */
    private bindSelectors()
    {
        /**
         * Success scenario
         * The first Swal will close itself thanks to the Observable returned by "requestNewPasswordToken".
         * Here, we just have to open the second Swal
         */
        // TODO The second swal seem to open/close right away
        this.subscription.add(this.forgotRequestLoaded$.pipe(
            filter(loaded => !!loaded),
            tap(_loaded => this.swalEmailSuccessfullySent.fire())
        ).subscribe());
    }

    // region Lifecycle

    ngOnDestroy()
    {
        this.subscription.unsubscribe();
    }

    // endregion
}
