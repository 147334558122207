<!--
    UPDATE 30/09/2019
    https://mysamcab.atlassian.net/browse/MYS-3740
    If the connected user is a Driver, and if this Driver is PENDING_APPROVAL, we want to display a big flashing
    red header to let him know he cannot receive any trip requests, and that he should download the mobile app
    to upload his documents
-->
<div id="banner" class="row" *ngIf="driverAccessRights.isPendingApproval$ | async">

    <!--
        Smaller on medium screens, to keep a maximum space for the "middle" block
        Big on large screens, because we have more space to display it
        Bigger on small screens, because the "middle" block is hidden on small screens
    -->
    <div class="col-lg-4 col-md-3 col-7 d-flex justify-content-center align-items-center">
        <strong class="px-4" [innerHTML]="'ld.banner.PENDING-APPROVAL-HTML' | mysamTranslate"></strong>
    </div>

    <!--
          Block hidden on "small" screens
    -->
    <div class="col-lg-5 col-md-6 col-sm-5 d-none d-md-flex align-items-center">
        <ul>
            <li [innerHTML]="'ld.banner.UPLOAD-REQUIRED-DOCUMENTS-HTML' | mysamTranslate"></li>
            <li [innerHTML]="'ld.banner.MYSAM-WILL-VALIDATE-SHORTLY-HTML' | mysamTranslate"></li>
        </ul>
    </div>

    <!-- The "Download app" link will be aligned horizontally at the end, and vertically centered -->
    <div class="col-md-3 col-5">

        <!-- This text will be hidden on very small screens -->
        <strong class="d-sm-flex d-none justify-content-end align-items-center me-5 me-sm-3"
                [innerHTML]="'ld.banner.DOWNLOAD-MOBILE-APP-HTML' | mysamTranslate">
        </strong>

        <!--
              On very small screens, this div will be flex, and therefore :
              - Will be as big as the parent (h-100)
              - Will center vertically its content (align-items-center)
              - Will be aligned horizontally at the end (justify-content-end)

              On small screens & big screens, it will be displayed as a block (in order not to overflow with the
              <strong> block above, which is displayed as a flex). In this case, it will just stay below the <strong> block

              The padding classes are defined arbitrarily, in order to have some padding on "at least small" screens,
              and reduce this padding (even remove it vertically) on "very small" screens
        -->
        <div class="d-flex d-sm-block justify-content-end align-items-center h-100 py-sm-2 py-0 px-sm-2 px-1">
            <mys-download-driver-mobile-app></mys-download-driver-mobile-app>
        </div>
    </div>
</div>
