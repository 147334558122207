export class Pageable
{
    constructor(private _page: number, private _size: number)
    {
    }

    get page(): number
    {
        return this._page;
    }

    get size(): number
    {
        return this._size;
    }

    /*get sort(): string
    {
        return this._sort;
    }

    get direction()
    {
        return this._direction;
    }*/
}
