import { ApiService, MySamPair } from "mys-base";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { TripOperator } from "../../../../../mys-base/src/lib/models/trip-operator";
import { Alert } from "../../../../../mys-base/src/lib/models/alert";
import { map } from 'rxjs/operators';
import { HttpErrorResponse } from "@angular/common/http";
import { TripOperatorError } from "../../trips/errors/trip.operator.error";
import {
    AbstractTripOperatorBindingAlertAssignationService
} from './abstract-trip-operator-binding-alert-assignation.service';

/**
 * Created by Sandra Bénard on 02/08/2022
 */

@Injectable({ providedIn: 'root' })
export class TripOperatorBindingService extends AbstractTripOperatorBindingAlertAssignationService
{
    private TRIP_OPERATOR_BASE_URL = this.BASE_URL + '/alfred/trip-operator/';

    /**
     * Bind an alfred user to the trip
     * @param tripId trip concerned
     * @param isAnotherOperatorAlreadyBound true if there is already another AlfredUser on the run, false otherwise.
     *              This lets the server know if two people have attempted to join the race simultaneously.
     * @param bindOperatorToAlertsToo False by default
     */
    public bindOperatorToTrip(tripId: number, isAnotherOperatorAlreadyBound: boolean, bindOperatorToAlertsToo: boolean): Observable<MySamPair<TripOperator | null, Alert[]>>
    {
        return this.post(this.TRIP_OPERATOR_BASE_URL + "bind-to-trip/" + tripId + "/" +
            isAnotherOperatorAlreadyBound + "/" + bindOperatorToAlertsToo);
    }
}
