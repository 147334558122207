import { Pipe, PipeTransform } from "@angular/core";
import { LibertyMobileCmt } from "../models/liberty-mobile-cmt";

@Pipe({
    name: 'companyLM'
})
export class CompanyWithLibertyMobilePipe implements PipeTransform
{
    transform(companyLM: LibertyMobileCmt): string
    {
        if (!!companyLM)
        {
            return companyLM.companyName;
        }

        return '';
    }
}
