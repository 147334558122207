import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MenuService } from './menu/menu.service';
import { PagetitleService } from './pagetitle/pagetitle.service';

import { throwIfAlreadyLoaded, UserSource } from 'mys-base';
import { MysamCoreModule } from '../../../../mys-base/src/lib/modules/mysam-core.module';
import { Constants } from '../../alfred/models/constants/constants';
import { LoginModule } from '../../../../msl-driver-registration/src/lib/login/login.module';
import { HeaderLanguageInterceptor } from 'msl-translate';
import { isLibertyDriver } from "../../environments/liberty-driver/environment.alfred";
import { environment } from '../../environments/environment';

@NgModule({
    imports: [
        LoginModule.forRoot(isLibertyDriver ? UserSource.LIBERTY_DRIVER : UserSource.MYSAM),
        MysamCoreModule.forRoot(Constants.AUTHENTICATION_BASIC, "alfred", environment.BASE_URL, [HeaderLanguageInterceptor])
    ],
    providers: [
        MenuService,
        PagetitleService
    ]
})
export class CoreModule
{
    constructor(@Optional() @SkipSelf() parentModule: CoreModule)
    {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
