import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DriverDocument } from '../../../models/driver-document';
import { Driver } from 'mys-base';

@Component({
    selector: 'mys-modal-document-image-dialog',
    templateUrl: './modal-document-image-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalDocumentImageDialogComponent
{
    constructor(@Inject(MAT_DIALOG_DATA) public data: { driver: Driver, driverDocument: DriverDocument })
    {
    }
}
