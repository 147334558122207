import { Action } from '@ngrx/store';
import { Client } from 'mys-base';

export const CLIENT_REFERRER_UPDATE = '[Alfred] Client Referrer update';
export const CLIENT_REFERRER_UPDATE_FAIL = '[Alfred] Client Referrer update Fail';
export const CLIENT_REFERRER_UPDATE_SUCCESS = '[Alfred] Client Referrer update Success';

export class ClientReferrerUpdate implements Action
{
    readonly type = CLIENT_REFERRER_UPDATE;

    constructor(public clientId: string, public referrerId: string)
    {
    }
}

export class ClientReferrerUpdateFail implements Action
{
    readonly type = CLIENT_REFERRER_UPDATE_FAIL;

    constructor(public payload: any)
    {
    }
}

export class ClientReferrerUpdateSuccess implements Action
{
    readonly type = CLIENT_REFERRER_UPDATE_SUCCESS;

    constructor(public payload: Client)
    {
    }
}

// Action types
export type ClientReferrerUpdateAction = ClientReferrerUpdate | ClientReferrerUpdateFail | ClientReferrerUpdateSuccess;
