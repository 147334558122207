import { Pipe, PipeTransform } from '@angular/core';
import { Company } from 'mys-base';

/**
 * Displays a User object as "firstName lastName", or an empty string if an empty user
 * object was passed to the "transform" method
 */
@Pipe({
    name: 'company'
})
export class CompanyPipe implements PipeTransform
{
    transform(company: Company): string
    {
        if (!!company)
        {
            return company.name;
        }

        return '';
    }
}
