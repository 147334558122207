<!-- We get the list of companies from our observable -->
<div class="col-sm p0" *ngIf="companies$ | async as companies">
    <!-- And we display the Select dropdown ONLY if we have at least one company to display -->
    <mat-form-field *ngIf="companies.length > 0" class="w-100">
        <mat-label>{{ 'select-company.TITLE' | mysamTranslate }}</mat-label>
        <mat-select (selectionChange)="companyChange.emit($event.value)"
                    [(value)]="company" [compareWith]="compareTwoCompanies">

            <!--
                 If requested, we display an "empty" option, to represent the absence of selection
            -->
            <ng-container *ngIf="shouldDisplayEmptyRow">
                <mat-option [value]="null">{{ 'select-company.NO-COMPANY' | mysamTranslate }}</mat-option>
                <mat-divider></mat-divider>
            </ng-container>

            <!-- And now, the "real" list of companies -->
            <mat-option *ngFor="let c of companies" [value]="c">
                {{ c.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
