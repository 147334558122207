import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform
{
    /**
     * If the "phoneNumber" starts with a + (e.g +33605040302), we want to format it this way : +336.05.04.03.02
     * Otherwise, we just split every two chars : 06.05.04.03.02
     */
    transform(phoneNumber: string, separator: string = ' '): any
    {
        /**
         * First, we remove any non-numeric characters that we might have
         * (except for the first one that might be a '+' that we want to keep)
         */
        phoneNumber = phoneNumber.substring(0, 1) + phoneNumber.substring(1).replace(/\D/g, '');

        let prefix: string;
        let suffix: string;
        if (phoneNumber.startsWith('+'))
        {
            // prefix = +336
            prefix = phoneNumber.substring(0, 4);
            suffix = phoneNumber.substring(4);
        }
        else
        {
            // prefix = 06
            prefix = phoneNumber.substring(0, 2);
            suffix = phoneNumber.substring(2);
        }

        /**
         * Now we replace every two groups of characters by themselves, prepended by a dot character
         */
        return (prefix + suffix.replace(/[^\dA-Z]/g, '')
            .replace(/(.{2})/g, separator + '$1')).trim();
    }
}
